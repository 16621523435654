import { Plans, PlaybookTabs, ScheduleTabs } from '@labradorsports/constants';
import { processParamConfig } from '@labradorsports/utils';

// stateKey should match the state path in RootState
export const paramSetup = processParamConfig({
    global: {
        t: {
            stateKey: 'teams.activeTeam',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/login': {
        d: { stateKey: 'main.deeplink', initialState: '' },
    },
    '/signup': {
        d: { stateKey: 'main.deeplink', initialState: '' },
    },
    '/payment/*': {
        l: {
            stateKey: 'billing.invoiceLinkId',
            initialState: '',
            options: { shouldPush: true },
        },
        e: {
            stateKey: 'billing.invoiceError',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/playbook/*': [
        {
            p: {
                stateKey: 'playbook.playId',
                initialState: '',
                options: { shouldPush: true },
            },
            pb: {
                stateKey: 'playbook.playbookId',
                initialState: '',
                options: { shouldPush: true },
            },
            sp: {
                stateKey: 'playbook.shownPlaybook',
                initialState: PlaybookTabs.MY,
                options: {
                    shouldPush: true,
                    parse: (value: string): string => {
                        // Only 3 valid values
                        if (value === PlaybookTabs.TEAM || value === PlaybookTabs.PUBLIC) return value;

                        return PlaybookTabs.MY;
                    },
                },
            },
            f: {
                stateKey: 'playbook.folderId',
                initialState: '',
                options: {
                    shouldPush: true,
                },
            },
            cpg: {
                stateKey: 'community.pageNumber',
                initialState: 1,
                options: {
                    shouldPush: true,
                    parse: Number,
                },
            },
        },
        {
            'usage/': {
                vd: {
                    stateKey: 'playbook.viewerDetailsPlayId',
                    initialState: '',
                    options: { shouldPush: true },
                },
            },
        },
    ],
    '/team/player/*': {
        p: {
            stateKey: 'teams.rosterPlayerId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/join-team': {
        k: { stateKey: 'main.joinTeamCode', initialState: '' },
        j: { stateKey: 'main.joinTeamId', initialState: '' },
    },
    '/email-action': {
        mode: { stateKey: 'auth.emailAction.mode', initialState: '' },
        oobCode: { stateKey: 'auth.emailAction.oobCode', initialState: '' },
        apiKey: { stateKey: 'auth.emailAction.apiKey', initialState: '' },
        continueUrl: {
            stateKey: 'auth.emailAction.continueUrl',
            initialState: '',
        },
    },
    '/profile/teamadmin': [
        {
            sub: {
                stateKey: 'profile.showSubSummary',
                initialState: '',
                options: { parse: (val: string): boolean => val === 'true' },
            },
        },
        {
            'create/': {
                plan: {
                    stateKey: 'billing.creatingBilling.plan',
                    initialState: '',
                    options: {
                        parse: (val: string): string => {
                            if (val in Plans) {
                                return val;
                            }

                            return '';
                        },
                    },
                },
            },
            'edit/*': {
                e: {
                    stateKey: 'billing.editBillingEntityId',
                    initialState: '',
                    options: { shouldPush: true },
                },
            },
            'billing/': {
                e: {
                    stateKey: 'billing.editBillingEntityId',
                    initialState: '',
                    options: { shouldPush: true },
                },
            },
        },
    ],
    '/profile/billing/team': {
        e: {
            stateKey: 'billing.editBillingEntityId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/schedule': [
        {
            st: {
                stateKey: 'schedule.shownTab',
                initialState: ScheduleTabs.FEED,
                options: {
                    shouldPush: true,
                    parse: (value: string): string => {
                        // Only 2 valid values
                        if (value === ScheduleTabs.PRACTICE_TEMPLATES) return value;

                        return ScheduleTabs.FEED;
                    },
                },
            },
        },
        {
            'series/*': {},
            event: [
                {
                    ev: {
                        stateKey: 'schedule.viewEventId',
                        initialState: '',
                        options: { shouldPush: true },
                    },
                },
                {
                    '*': {},
                    'edit/*': {},
                    'scouting-report/*': {},
                },
            ],
            'practice/*': {
                p: {
                    stateKey: 'schedule.viewPracticeTemplateId',
                    initialState: '',
                    options: { shouldPush: true },
                },
            },
            scouting: [
                {
                    ev: {
                        stateKey: 'schedule.viewEventId',
                        initialState: '',
                        options: { shouldPush: true },
                    },
                },
                {
                    view: {},
                    'edit/*': {},
                },
            ],
        },
    ],
    '/schedule/update-rsvp': {
        r: {
            stateKey: 'schedule.viewEventId',
            initialState: '',
            options: { shouldPush: true },
        },
        s: {
            stateKey: 'schedule.rsvpUpdateStatus',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/feed/post': {
        p: {
            stateKey: 'messaging.viewPostId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/feed/documents/acks': {
        d: {
            stateKey: 'messaging.viewDocumentAcksId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/play/*': {
        l: {
            stateKey: 'playbook.publicLinkId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/community/latest': {
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                shouldPush: true,
                parse: Number,
            },
        },
    },
    '/community/popular': {
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                shouldPush: true,
                parse: Number,
            },
        },
    },
    '/community/trending': {
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                shouldPush: true,
                parse: Number,
            },
        },
    },
    '/community/play': {
        p: {
            stateKey: 'community.playId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/community/profile': {
        pr: {
            stateKey: 'community.profileId',
            initialState: '',
            options: { shouldPush: true },
        },
    },
    '/community/search': {
        q: {
            stateKey: 'community.searchText',
            initialState: '',
            options: { shouldPush: true },
        },
        pg: {
            stateKey: 'community.pageNumber',
            initialState: 1,
            options: {
                shouldPush: true,
                parse: Number,
            },
        },
    },
});
