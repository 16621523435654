import createAssetGetter from './createAssetGetter.js';

export const FooterLogo = createAssetGetter('img/FooterLogo.svg');

export const HomeIcon = createAssetGetter('img/HomeIcon.svg');
export const PlaybookIcon = createAssetGetter('img/PlaybookIcon.svg');
export const ScheduleIcon = createAssetGetter('img/ScheduleIcon.svg');
export const RosterIcon = createAssetGetter('img/RosterIcon.svg');

export const AddMemberIcon = createAssetGetter('img/AddPerson.svg');

export const CreatePostIcon = createAssetGetter('img/CreatePost.svg');
export const CreatePlayIcon = createAssetGetter('img/CreatePlay.svg');
export const CreateEventIcon = createAssetGetter('img/CreateEvent2.svg');
export const CreateEventSeriesIcon = createAssetGetter('img/CreateEventSeries.svg');
export const ScheduleBuilderIcon = createAssetGetter('img/ScheduleBuilder.svg');

export const AvatarIcon = createAssetGetter('img/AvatarIcon.svg');

export const LikeIcon = createAssetGetter('img/AddLike.svg');
export const LikeIconFilled = createAssetGetter('img/AddLikeFilled.svg');
export const CommentIcon = createAssetGetter('img/PostComment.svg');

export const Rebound = createAssetGetter('img/GroundBall.svg');
export const Assign = createAssetGetter('img/DefenseAssign.svg');
export const MovePlayer = createAssetGetter('img/MovePlayer.svg');
export const PassBall = createAssetGetter('img/PassBall.svg');
export const SetPick = createAssetGetter('img/SetPick.svg');
export const Shoot = createAssetGetter('img/Shoot.svg');
export const GroundBall = createAssetGetter('img/GroundBall.svg');
export const WaveMove = createAssetGetter('img/WaveMove.svg');
export const ArrowMoveIcon = createAssetGetter('img/MoveTypeArrow.svg');
export const PickMoveIcon = createAssetGetter('img/MoveTypePick.svg');
export const SquiggleMoveIcon = createAssetGetter('img/MoveTypeSquiggle.svg');
export const DrawRectangle = createAssetGetter('img/DrawRectangle.svg');
export const DrawEllipse = createAssetGetter('img/DrawEllipse.svg');

export const AddIcon = createAssetGetter('img/AddIcon.svg');
export const CopyIcon = createAssetGetter('img/CopyIcon.svg');

export const CommentAdded = createAssetGetter('img/CommentAdded.svg');

export const AddComment = createAssetGetter('img/AddComment.svg');
export const RemoveX = createAssetGetter('img/RemoveX.svg');

export const AddFrameIcon = createAssetGetter('img/AddFrame.svg');
export const PauseFramesIcon = createAssetGetter('img/PauseFrames.svg');
export const PlayFramesIcon = createAssetGetter('img/PlayFrames.svg');
export const PlaybackSpeedIcon = createAssetGetter('img/PlaybackSpeed.svg');
export const AddVideoIcon = createAssetGetter('img/AddVideo.svg');
export const TitleSlideIcon = createAssetGetter('img/TitleSlide.svg');

export const FeedbackIcon = createAssetGetter('img/Feedback.svg');
export const EmailIcon = createAssetGetter('img/Email.svg');
export const VideoIcon = createAssetGetter('img/YouTubeBlue.svg');

export const PlayIcon = createAssetGetter('img/PlayFrames.svg');

export const ReplayIcon = createAssetGetter('img/Replay.svg');
export const PlayVideoIcon = createAssetGetter('img/PlayVideo.svg');

export const SaveIcon = createAssetGetter('img/Save.svg');

export const TagIcon = createAssetGetter('img/AddTag.svg');

export const RotateClockwise = createAssetGetter('img/RotateClockwise.svg');
export const RotateCounterclockwise = createAssetGetter('img/RotateCounterclockwise.svg');

export const DeleteIcon = createAssetGetter('img/SolidTrashcan.svg');

export const CurvedCornerIcon = createAssetGetter('img/CurvedCorner.svg');
export const StraightCornerIcon = createAssetGetter('img/StraightCorner.svg');
export const NoCornerIcon = createAssetGetter('img/NoCorner.svg');

export const ExitFullscreenIcon = createAssetGetter('img/ExitFullscreen.svg');
export const FullscreenIcon = createAssetGetter('img/Fullscreen.svg');
export const PlayersIcon = createAssetGetter('img/PlayerIcon.svg');
export const EditIcon = createAssetGetter('img/EditPencil.svg');
export const MoreMenuIcon = createAssetGetter('img/MoreMenuIcon.svg');
export const ShareIcon = createAssetGetter('img/ShareIcon.svg');
export const ViewOptionsIcon = createAssetGetter('img/ViewOptions.svg');
export const PrintIcon = createAssetGetter('img/Print.svg');
export const UndoIcon = createAssetGetter('img/Undo.svg');
export const ChristmasLight = createAssetGetter('img/ChristmasLight.svg');

export const Goal = createAssetGetter('img/Goal.svg');
export const Cone = createAssetGetter('img/Cone.svg');
export const Ball = createAssetGetter('img/BallMarker.svg');
export const Coach = createAssetGetter('img/CoachMarker.svg');

export const HelpIcon = createAssetGetter('img/HelpIcon.svg');

export const DrillIcon = createAssetGetter('img/Drill.svg');

export const AddPlayIcon = createAssetGetter('img/AddFrame.svg');
export const AddFolderIcon = createAssetGetter('img/AddFolder.svg');

export const FolderIcon = createAssetGetter('img/Folder.svg');

export const StudyReminderIcon = createAssetGetter('img/Email.svg');

export const PrintOneIcon = createAssetGetter('img/PrintOne.svg');
export const PrintThreeIcon = createAssetGetter('img/PrintThree.svg');

export const TeamShareIcon = createAssetGetter('img/ShareTeam.svg');

export const TeamAdminIcon = createAssetGetter('img/Team.svg');
export const AccountInfoIcon = createAssetGetter('img/AccountInformation.svg');
export const BillingHistoryIcon = createAssetGetter('img/BillingHistory.svg');

export const SortDescIcon = createAssetGetter('img/SortDescending.svg');
export const SortAscIcon = createAssetGetter('img/SortAscending.svg');

export const CheckIcon = createAssetGetter('img/CheckIcon.svg');

export const ListIcon = createAssetGetter('img/List.svg');
export const CardsIcon = createAssetGetter('img/Cards.svg');

export const DeletePlayerIcon = createAssetGetter('img/DeletePlayer.svg');

export const SharePlayIcon = createAssetGetter('img/ShareArrow.svg');
export const InviteTeamIcon = createAssetGetter('img/InviteEmail.svg');
export const FirstPlayIcon = createAssetGetter('img/FirstPlay.svg');
export const FollowSocialIcon = createAssetGetter('img/FollowSocial.svg');
export const ShareSocialIcon = createAssetGetter('img/ShareSocial.svg');
export const AppStoreIcon = createAssetGetter('img/AppStore.svg');

export const AddFrameWhiteIcon = createAssetGetter('img/AddFrameWhite.svg');

export const InstagramLogo = createAssetGetter('img/Instagram.svg');
export const InstagramLogoWhite = createAssetGetter('img/InstagramWhite.svg');
export const FacebookLogo = createAssetGetter('img/Facebook.svg');
export const FacebookLogoWhite = createAssetGetter('img/FacebookWhite.svg');
export const YouTubeLogo = createAssetGetter('img/YouTube.svg');
export const YouTubeLogoWhite = createAssetGetter('img/YouTubeWhite.svg');
export const TwitterLogo = createAssetGetter('img/Twitter.svg');
export const TwitterLogoWhite = createAssetGetter('img/TwitterWhite.svg');

export const HamburgerButton = createAssetGetter('img/SplitPanelHamburger.svg');

export const VideoThumbnailPlayIcon = createAssetGetter('img/VideoThumbnailPlay.svg');

export const RemoveIcon = createAssetGetter('img/DeleteIcon.svg');

export const Backdrop = createAssetGetter('img/DepthChartBackground.svg');

export const BallMarker = createAssetGetter('img/BallMarker.svg');

export const Comment = createAssetGetter('img/AddComment.svg');

export const CoachMarker = createAssetGetter('img/CoachMarker.svg');

export const GoalIcon = createAssetGetter('img/Goal.svg');

export const LoadingIcon = createAssetGetter('img/loading.gif');

export const FacebookShareIcon = createAssetGetter('img/FacebookShare.png');
export const TwitterShareIcon = createAssetGetter('img/TwitterShare.png');
export const GooglePlayIcon = createAssetGetter('img/GooglePlay.png');
export const HudlLogo = createAssetGetter('img/Hudl-logo.jpg');
export const CoachIcon = createAssetGetter('img/coach-icon.png');

export const ImportIcon = createAssetGetter('img/Import.svg');
export const ExportGIFIcon = createAssetGetter('img/ExportGIF.svg');
export const PaymentsIcon = createAssetGetter('img/Payment.svg');
export const LinkIcon = createAssetGetter('img/LinkIcon.svg');
export const CSVIcon = createAssetGetter('img/CSVIcon.svg');

export const DeleteIconWhite = createAssetGetter('img/DeleteWhite.svg');
export const EmailIconWhite = createAssetGetter('img/EmailWhite.svg');

export const SearchEmpty = createAssetGetter('img/SearchEmpty.svg');
export const SearchIcon = createAssetGetter('img/SearchIconDark.svg');
export const SearchIconWhite = createAssetGetter('img/SearchIconWhite.svg');
export const MoreInfoIcon = createAssetGetter('img/MoreInfoIcon.svg');
export const FeedEmpty = createAssetGetter('img/FeedEmpty.svg');
export const RosterEmpty = createAssetGetter('img/RosterEmpty.svg');
export const PlaybookEmpty = createAssetGetter('img/PlaybookHomePage.svg');
export const ScheduleEmpty = createAssetGetter('img/Schedule.svg');
export const BillingEmpty = createAssetGetter('img/BillingEmpty.svg');
export const AdminEmpty = createAssetGetter('img/AdminEmpty.svg');

export const RecurringIcon = createAssetGetter('img/Recurring.svg');
export const CancelEventIcon = createAssetGetter('img/CancelEvent.svg');
export const EditEventIcon = createAssetGetter('img/EditEvent.svg');
export const ReminderBellIcon = createAssetGetter('img/ReminderBell.svg');
export const StarIcon = createAssetGetter('img/Star.svg');

export const DOCIcon = createAssetGetter('img/DOCIcon.svg');
export const PDFIcon = createAssetGetter('img/PDFIcon.svg');
export const AlertIcon = createAssetGetter('img/Alert.svg');
export const WebLinkIcon = createAssetGetter('img/WebLink.svg');

export const FontSizeUpIcon = createAssetGetter('img/FontSizeUp.svg');
export const FontSizeDownIcon = createAssetGetter('img/FontSizeDown.svg');
