 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface CommunityState {
    plays: any[];
    playId: string;
    play: any;
    playComments: any[];
    profileId: string;
    profile: any;
    searchResults: any[];
    searchText: string;
    showSignupPrompt: boolean;
    pageNumber: number;
    numPages: number;
    newPlays: any[];
    popularPlays: any[];
    trendingPlays: any[];
}

const communityDefaultState: CommunityState = {
    plays: null,
    playId: undefined,
    play: undefined,
    playComments: undefined,
    profileId: undefined,
    profile: undefined,
    searchResults: undefined,
    searchText: undefined,
    showSignupPrompt: false,
    pageNumber: 1,
    numPages: 1,
    newPlays: undefined,
    popularPlays: undefined,
    trendingPlays: undefined,
};

const { reducer: communityReducer, actions: communityActions } = createSlice({
    name: 'community',
    initialState: communityDefaultState,
    reducers: {
        CommunityPlaybookLoaded: (state, action) => {
            state.plays = action.payload.plays;

            if (action.payload.estimatedPages) {
                state.numPages = action.payload.estimatedPages;
            }
        },

        CommunityHomepageLoaded: (state, action) => {
            state.newPlays = action.payload.newPlays;
            state.popularPlays = action.payload.popularPlays;
            state.trendingPlays = action.payload.trendingPlays;
        },

        OpenPage: (state, action) => {
            state.pageNumber = action.payload ?? 1;
        },

        OpenCommunityPlay: (state, action: PayloadAction<string | null>) => {
            state.playId = action.payload;

            if (!action.payload) {
                state.play = undefined;
                state.playComments = undefined;
            }
        },

        CommunityPlayLoaded: (state, action: PayloadAction<any | null>) => {
            state.play = action.payload;
        },

        CommunityPlayCommentsLoaded: (state, action: PayloadAction<any | null>) => {
            state.playComments = action.payload;
        },

        SetCommunityProfileId: (state, action: PayloadAction<string | null>) => {
            state.profileId = action.payload;
        },

        CommunityProfileLoaded: (state, action: PayloadAction<Record<string, string | Record<string, any>> | null>) => {
            state.profile = action.payload;
        },

        SetCommunitySearch: (state, action: PayloadAction<string | null>) => {
            state.searchText = action.payload;
        },

        SetCommunitySearchResults: (state, action: PayloadAction<any>) => {
            state.searchResults = action.payload.plays;
            state.numPages = action.payload.estimatedPages;
        },

        ShowSignupPrompt: (state, action) => {
            state.showSignupPrompt = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: (state) => {
            return {
                ...state,
                profileId: state.profileId === 'me' ? undefined : state.profileId,
            };
        },
    },
});

export { communityReducer, communityActions, communityDefaultState };
