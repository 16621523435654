import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { useAssets } from '@labradorsports/assets';
import { Plans } from '@labradorsports/constants';
import {
    FieldViewportProvider,
    PlaybackProvider,
    PlayEditorProvider,
    PlayThemeProvider,
    SelectedProvider,
} from '@labradorsports/play-rendering';
import { useSite } from '@labradorsports/utils';

import { useDispatcher } from '../shared/hooks/index.js';
import {
    RootState,
    playEditorActions,
    Selectors,
    useTeamPlaybookQuery,
    useLoadPlayQuery,
    useLoadEntityLogoQuery,
} from '../store/index.js';

import { PluginsProvider } from './providers/index.js';

export interface PlayEditorWrapperProps {
    children: ReactNode;
    dimensions?: Dimensions;
    readOnly?: boolean;
}

const PlayEditorWrapper: FC<PlayEditorWrapperProps> = ({ children, dimensions, readOnly }) => {
    const { Logo } = useAssets();
    const { Config } = useSite();
    const selectFrame = useDispatcher(playEditorActions.SelectFrame);

    const teamLogoId = useSelector((state: RootState) => {
        if (state.playEditor.play?.branding) return state.playEditor.play.branding.logoEntityId;

        return Selectors.currentTeam(state)?.programId;
    });

    const {
        data: { shared = [] },
    } = useTeamPlaybookQuery();

    const playId = useSelector((state: RootState) => state.playbook.playId);
    useLoadPlayQuery({ playId });

    const isTeamPlay = shared.some((play) => play.id === playId);

    const isCommunityPlay = useSelector((state: RootState) =>
        state.community.playId?.includes(state.playEditor.play?.id)
    );

    const playData = useSelector((state: RootState) => state.playEditor.play);
    const debugOptions = useSelector((state: RootState) => state.playEditor.debugOptions);
    const debugMarkers = useSelector((state: RootState) => state.playEditor.debugMarkers);
    const currentFrameIdx = useSelector((state: RootState) => state.playEditor.currentFrame);
    const branding = useSelector((state: RootState) =>
        Selectors.currentPlan(state) === Plans.BASIC ? null : Selectors.currentBranding(state)
    );
    const { data: teamLogoUrl } = useLoadEntityLogoQuery({ programId: teamLogoId });

    if (!playData) {
        return null;
    }

    return (
        <PlayEditorProvider
            dimensions={dimensions}
            playData={playData}
            debugOptions={debugOptions}
            debugMarkers={debugMarkers}
            currentFrameIdx={currentFrameIdx}
            selectFrame={selectFrame}
            fieldUrl={Config.SiteConfig.FriendlyUrl}
            readOnly={readOnly}
        >
            <PlayThemeProvider
                branding={isTeamPlay ? branding : null}
                teamLogoUrl={isTeamPlay || isCommunityPlay ? teamLogoUrl : null}
                watermark={Logo}
            >
                <FieldViewportProvider>
                    <PlaybackProvider>
                        <SelectedProvider>
                            <PluginsProvider>{children}</PluginsProvider>
                        </SelectedProvider>
                    </PlaybackProvider>
                </FieldViewportProvider>
            </PlayThemeProvider>
        </PlayEditorProvider>
    );
};

export default PlayEditorWrapper;
