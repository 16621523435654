import { Sites, ToolTypes } from '@labradorsports/constants';
import { arraySlidingWindow, getSiteForSport } from '@labradorsports/utils';

import { FieldPiece, FieldViewport, Field, Move, FrameSnapshot, Play } from '../models/index.js';

import { areClose, findBestPair } from './misc.js';

export interface ZoomOffset {
    x: number;
    y: number;
}

export function findClosestPiece<T extends FieldPiece>(coords: Point, nodes: T[], exclude: T[] = []): T {
    const filteredNodes = nodes.filter((node: T) => !exclude.includes(node));

    const adaptedNodes = filteredNodes.map((node: T, idx: number) => ({ ...node.origin, originalIdx: idx }));
    const sorted = sortByDistance(coords, adaptedNodes);

    return sorted[0] ? filteredNodes[sorted[0].originalIdx] : null;
}

export function getLinePlayerPosition(
    lineOrigin: Point,
    playerCount: number,
    pos: number,
    fieldConfig: Field,
    rotation?: number
): Point {
    const dist = (playerCount - pos - 1) * (fieldConfig.getDiagonal() / 40);
    if (typeof rotation !== 'number') {
        return moveTowardsPoint(lineOrigin, fieldConfig.getCenter(), dist);
    }

    return translateAtAngle(lineOrigin, rotation, dist);
}

export function getLineEndpoints(line: FieldPiece, fieldViewport: FieldViewport): [Point, Point] {
    const playerCount = line.props.playerCount ?? 3;
    return [
        getLinePlayerPosition(line.origin, playerCount, playerCount + 1, fieldViewport.field, line.rotation),
        getLinePlayerPosition(line.origin, playerCount, -1, fieldViewport.field, line.rotation),
    ];
}

export function snapMoveToPlayerLine(
    frame: FrameSnapshot,
    fieldViewport: FieldViewport,
    coords: Point,
    targetId: number
): FieldPiece {
    const target = frame.getPlayer(targetId);

    const closestLine = frame.field.findClosestPiece(coords, [
        target.type === ToolTypes.BLUEPLAYER ? ToolTypes.OLINE : ToolTypes.DLINE,
    ]);

    if (closestLine && fieldViewport.getPixelLength(distance(closestLine.origin, coords)) < 30) {
        return closestLine;
    }

    return null;
}

export function clampZoomOffset(fieldViewport: FieldViewport, coords: ZoomOffset): ZoomOffset {
    return {
        x: Math.min(Math.max(coords.x, 0), fieldViewport.w * fieldViewport.zoomFactor - fieldViewport.w),
        y: Math.min(Math.max(coords.y, 0), fieldViewport.h * fieldViewport.zoomFactor - fieldViewport.h),
    };
}

export function getDefaultZoomOffset(PlayConfig: PlayConfigSpec, fieldViewport: FieldViewport, field: Field) {
    const { FieldTypes } = PlayConfig;
    // Always focus on the first goal
    const focusGoalie = field.fieldType === FieldTypes.CUSTOM ? null : field.pieces[0];
    const goalieDiffX = focusGoalie ? fieldViewport.getPixelLength(focusGoalie.origin.x - field.width / 2) : 0;
    const magnification = fieldViewport.zoomFactor - 1;

    const offset = fieldViewport.flipped
        ? {
              x: -(goalieDiffX - fieldViewport.w * magnification) / 2,
              y: -(fieldViewport.h * magnification),
          }
        : {
              x: (fieldViewport.w * magnification + goalieDiffX) / 2,
              y: fieldViewport.h * magnification,
          };

    return clampZoomOffset(fieldViewport, offset);
}

export function getDefaultZoomFactor(PlayConfig: PlayConfigSpec, play: Play): number {
    const { PlayTypes } = PlayConfig;
    const { sport, fieldType, unifiedField } = play ?? {};
    const { zoomLevels = [1, 1.5] } = PlayConfig.FieldSettings[sport]?.[fieldType] ?? {};

    if (unifiedField && fieldType === PlayTypes.HALF_FIELD) {
        return zoomLevels[1];
    }

    return getSiteForSport(sport) === Sites.footballlab ? zoomLevels[2] : zoomLevels[0];
}

export function getStraightMoveDuration(move: Move, totalDuration: number, leg: number): number {
    if (!move || move.anchors.length === 1 || leg === -1) {
        return totalDuration;
    }

    const distances = arraySlidingWindow([move.origin, ...move.anchors], distance);
    const totalDist = distances.reduce((acc, dist) => acc + dist, 0);

    return distances.map((dist) => (dist / totalDist) * totalDuration)[leg];
}

export function resizeShading(shading: FieldPiece, anchorStart: Point, anchorEnd: Point): [Point, number, number] {
    return resizeRectangle(
        shading.origin,
        shading.props.width,
        shading.props.height,
        shading.rotation,
        anchorStart,
        anchorEnd
    );
}

export function negateOffset(offset: Point): Point {
    return {
        x: -offset.x,
        y: -offset.y,
    };
}

export function findFurthestPoints(points: Point[]): [Point, Point] {
    return findBestPair(points, (pointA, pointB) => distance(pointA, pointB));
}

export function centerOfMass(points: Point[]): Point {
    const [xSum, ySum] = points.reduce((accumulator, pt) => [accumulator[0] + pt.x, accumulator[1] + pt.y], [0, 0]);

    return {
        x: xSum / points.length,
        y: ySum / points.length,
    };
}

export function isPointAboveLine(pt: Point, a: Point, b: Point): boolean {
    const lineAngle = angleBetweenPoints(a, b);
    // Adjust the angle of the point in question to be in the frame of reference of the line
    // by choosing the minimum rotation direction
    const pointAngle = angleBetweenPoints(a, pt);
    const adjustmentAngle = lineAngle < 180 ? -lineAngle : 360 - lineAngle;
    const adjustedPointAngle = (pointAngle + adjustmentAngle + 360) % 360;

    // 0 rotation is the right half of the x-axis
    // From the frame of reference of the line, the point is above the line if it has > 180 degree clockwise rotation
    return adjustedPointAngle > 180;
}

export function arePointsSeparatedByLine(a: Point, b: Point, linePoint1: Point, linePoint2: Point): boolean {
    return isPointAboveLine(a, linePoint1, linePoint2) !== isPointAboveLine(b, linePoint1, linePoint2);
}

export function moveAlongStraightMovement(points: Point[], percentage: number): [leg: number, percentage: number] {
    if (points.length === 2) {
        return [1, percentage];
    }

    const distances = arraySlidingWindow(points, distance);
    const totalDist = distances.reduce((acc, dist) => acc + dist, 0);
    const percentageDist = percentage * totalDist;

    let leg = 1;
    let cumulativeDistance = distances[0];
    while (cumulativeDistance < percentageDist) {
        cumulativeDistance += distances[leg];
        leg += 1;
    }

    const coveredDistance = cumulativeDistance - distances[leg - 1];
    const remainingDistance = percentageDist - coveredDistance;
    const modifiedPercentage = remainingDistance / distances[leg - 1];

    return [leg, modifiedPercentage];
}

export function getBezierControlPoints(anchors: Point[]) {
    const [firstAnchor, ...restAnchors] = anchors;
    const midpoints = arraySlidingWindow(restAnchors, getMidpoint);
    const points = [firstAnchor, ...midpoints.slice(0, -1), restAnchors[restAnchors.length - 1]];

    const controlPoints = arraySlidingWindow(points, (a, c, idx) => {
        const b = restAnchors[idx];

        const controlPoint = getBezierControlPoint(a, b, c);
        const startAngle = angleBetweenPoints(a, controlPoint);
        const endAngle = angleBetweenPoints(c, controlPoint);

        return {
            controlPoint,
            startAngle,
            endAngle,
        };
    });

    const controlPointCorrections = arraySlidingWindow(controlPoints, (a, b) => {
        const angleDiff = 180 - ((360 + b.startAngle - a.endAngle) % 360);

        return angleDiff / 2;
    });

    const correctedControlPoints = controlPoints.map((ctrl, idx) => {
        const correction = controlPointCorrections[idx] ?? 0;
        const anchorPoint = midpoints[idx];

        if (!anchorPoint) {
            return ctrl.controlPoint;
        }

        return rotateAroundOrigin(anchorPoint, ctrl.controlPoint, -correction);
    });

    const correctedPoints = points.map((p, idx) => {
        if (idx === 0 || idx === points.length - 1) {
            return p;
        }

        const correction = controlPointCorrections[idx - 1] ?? 0;

        const anchorPoint = restAnchors[idx - 1];

        return rotateAroundOrigin(anchorPoint, p, -correction);
    });

    return {
        correctedPoints,
        correctedControlPoints,
    };
}

export function getMidpoint(p1: Point, p2: Point): Point {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

export function distance(pt1: Point, pt2: Point): number {
    const a = pt1.x - pt2.x;
    const b = pt1.y - pt2.y;

    return Math.sqrt(a * a + b * b);
}

export function weightedDistance(pt1: Point, pt2: Point, bias = 1): number {
    return distance(pt1, pt2) * bias;
}

export function sortByDistance<T extends Point & { distanceBias?: number }>(coords: Point, nodes: T[]): T[] {
    return nodes.slice().sort((p1: T, p2: T) => {
        const p1Dist = weightedDistance(p1, coords, p1.distanceBias);
        const p2Dist = weightedDistance(p2, coords, p2.distanceBias);

        return p1Dist - p2Dist;
    });
}

export function findClosestNode<T extends Point>(coords: Point, nodes: T[], exclude: T[] = []): T {
    const filteredNodes = nodes.filter((node: T) => !exclude.includes(node));

    const sorted = sortByDistance(coords, filteredNodes);

    return sorted[0];
}

export function findClosestLine(coords: Point, lines: Line[], exclude: Line[] = []): Line {
    const filteredLines = lines.filter((line) => !exclude.includes(line));

    const sorted = filteredLines.sort((l1, l2) => {
        return distanceFromLine(coords, l1) - distanceFromLine(coords, l2);
    });

    return sorted[0] ?? null;
}

export function angleBetweenPoints(origin: Point, coords: Point): number {
    const height = coords.y - origin.y;
    const width = coords.x - origin.x;
    const angle = (Math.atan2(height, width) * 180) / Math.PI;

    return angle < 0 ? angle + 360 : angle;
}

export function rotateAroundOrigin(origin: Point, pt: Point, angle: number): Point {
    const convertedAngle = (angle * Math.PI) / 180;
    const x = pt.x - origin.x;
    const y = pt.y - origin.y;

    const newX = x * Math.cos(convertedAngle) - y * Math.sin(convertedAngle);
    const newY = x * Math.sin(convertedAngle) + y * Math.cos(convertedAngle);

    return {
        x: newX + origin.x,
        y: newY + origin.y,
    };
}

export function translateAtAngle<T extends Point>(coords: T, angle: number, dist: number): T {
    const convertedAngle = angle * (Math.PI / 180);

    const result = {
        x: coords.x + dist * Math.cos(convertedAngle),
        y: coords.y + dist * Math.sin(convertedAngle),
    } as T;

    if (coords.space) {
        result.space = coords.space;
    }

    return result;
}

export function moveTowardsPointPercentage(from: Point, to: Point, percentage: number) {
    return {
        x: from.x + (to.x - from.x) * percentage,
        y: from.y + (to.y - from.y) * percentage,
    };
}

export function distanceFromLine(pt: Point, line: Line): number {
    const { a, b } = line;
    const dist = distance(a, b);

    const area = Math.abs((b.y - a.y) * pt.x - (b.x - a.x) * pt.y + b.x * a.y - b.y * a.x);

    return area / dist;
}

export function moveTowardsPoint(from: Point, to: Point, dist: number): Point {
    return translateAtAngle(from, angleBetweenPoints(from, to), dist);
}

export function applyOffset(coords: Point, offset: Partial<Point>): Point {
    return {
        x: coords.x + (offset.x ?? 0),
        y: coords.y + (offset.y ?? 0),
    };
}

export function getCoordsOffset(origin: Point, coords: Point): Point {
    return {
        x: coords.x - origin.x,
        y: coords.y - origin.y,
    };
}

// t is the proportion of the curve that should occur before the midpoint
export function getBezierControlPoint(start: Point, mid: Point, end: Point, t = 0.5): Point {
    const [P0, P1, P2] = [start, mid, end].map((pt) => getCoordsOffset(start, pt));
    return {
        x: P1.x / (2 * t * (1 - t)) - (P0.x * t) / (2 * (1 - t)) - (P2.x * (1 - t)) / (2 * t) + start.x,
        y: P1.y / (2 * t * (1 - t)) - (P0.y * t) / (2 * (1 - t)) - (P2.y * (1 - t)) / (2 * t) + start.y,
    };
}

// P0, P1, P2 are either the x or y coordinate of the points
export function bez(P0: number, P1: number, P2: number, t: number): number {
    return (1 - t) ** 2 * P0 + 2 * (1 - t) * t * P1 + t ** 2 * P2;
}

export function getPointAlongBezier(start: Point, control: Point, end: Point, t: number): Point {
    return {
        x: bez(start.x, control.x, end.x, t),
        y: bez(start.y, control.y, end.y, t),
    };
}

// https://gamedev.stackexchange.com/questions/5373/moving-ships-between-two-planets-along-a-bezier-missing-some-equations-for-acce/5427#5427
export function getBezierDistanceFunction(start: Point, control: Point, end: Point): [number, (p: number) => Point] {
    let prev = start;

    const arclengths = [0];
    let arclength = 0;

    for (let a = 1; a <= 100; a += 1) {
        const bezierSegmentEnd = getPointAlongBezier(start, control, end, a * 0.01);

        arclength += distance(prev, bezierSegmentEnd);
        arclengths.push(arclength);
        prev = bezierSegmentEnd;
    }

    return [
        arclength,
        (p: number) => {
            // Binary search for performance over .findIndex
            let low = 0;
            let high = 100;
            let index = 0;

            while (low < high) {
                // | 0 is a faster way to truncate to integer part

                index = low + (((high - low) / 2) | 0);
                if (arclengths[index] < p) {
                    low = index + 1;
                } else {
                    high = index;
                }
            }

            if (arclengths[index] > p) {
                index -= 1;
            }

            const bottom = arclengths[index];
            const top = arclengths[index + 1];

            const proportionBetween = (p - bottom) / (top - bottom);
            const t = (index + proportionBetween) / 100;
            return getPointAlongBezier(start, control, end, t);
        },
    ];
}

export function getWavePointsStraight(start: Point, end: Point, spacing: number, padding: number): Point[] {
    const angle = angleBetweenPoints(start, end);
    const dist = distance(start, end);
    const numberOfSegments = Math.max(Math.floor(dist / spacing) - padding * 2, 0);
    const points = [];
    for (let i = padding; i <= numberOfSegments + padding; i += 1) {
        points.push(translateAtAngle(start, angle, i * spacing));
    }

    return points;
}

export function getWavePointsCurved(
    start: Point,
    control: Point,
    end: Point,
    spacing: number,
    padding: number
): Point[] {
    const [bezierLength, getDistanceAlongBezier] = getBezierDistanceFunction(start, control, end);
    const numberOfSegments = Math.max(Math.floor(bezierLength / spacing) - padding * 2, 0);

    const points = [];

    for (let i = padding; i <= numberOfSegments + padding; i += 1) {
        points.push(getDistanceAlongBezier(i * spacing));
    }

    return points;
}

export function getPresetCoords(coords: Point[], center: Point, fieldWidth: number): Point[] {
    return coords.map((coord) => ({
        x: center.x - ((coord.x - 50) / 100) * fieldWidth,
        y: center.y + ((coord.y - 50) / 100) * fieldWidth,
    }));
}

export function getShadingCorners(center: Point, width: number, height: number): Point[] {
    if (!center) {
        return [];
    }

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    return [
        // Top left
        applyOffset(center, {
            x: -halfWidth,
            y: -halfHeight,
        }),
        // Top right
        applyOffset(center, {
            x: halfWidth,
            y: -halfHeight,
        }),
        // Bottom left
        applyOffset(center, {
            x: -halfWidth,
            y: halfHeight,
        }),
        // Bottom right
        applyOffset(center, {
            x: halfWidth,
            y: halfHeight,
        }),
    ];
}

export function getShadingRotationHandle(center: FieldPoint, height: number, fieldViewport: FieldViewport): FieldPoint {
    return {
        x: center.x,
        y: center.y - height / 2 - fieldViewport.getFieldLength(15),
    };
}

export function resizeRectangle(
    origin: Point,
    width: number,
    height: number,
    rotation = 0,
    anchorStart: Point,
    anchorEnd: Point
): [Point, number, number] {
    // Update reference frame to 0 effective rotation
    const unrotatedAnchorStart = rotateAroundOrigin(origin, anchorStart, -rotation);
    const unrotatedHandle = rotateAroundOrigin(origin, anchorEnd, -rotation);

    // Use the relation between the starting corner and the center to determine how to modify center and w/h calculations
    const xMultiplier = unrotatedAnchorStart.x < origin.x ? -1 : 1;
    const yMultiplier = unrotatedAnchorStart.y < origin.y ? -1 : 1;

    // Find the opposite corner by calculating from the center based on the width and height
    const oppositeCorner = applyOffset(origin, {
        x: -xMultiplier * (width / 2),
        y: -yMultiplier * (height / 2),
    });

    // Use the opposite corner to find a new center assuming the ending point should be the new corner position
    const newCenter = getMidpoint(unrotatedHandle, oppositeCorner);

    // Calculate the amount the center has shifted
    const centerShift = getCoordsOffset(newCenter, origin);

    return [
        // Rotate the new center back into the correct reference frame using the old center
        rotateAroundOrigin(origin, newCenter, rotation),
        // Calculate new w/h based on movement of the center
        Math.abs(width - xMultiplier * centerShift.x * 2),
        Math.abs(height - yMultiplier * centerShift.y * 2),
    ];
}

export function isPointOnLine(point: Point, line: Line): boolean {
    if (pointsAreClose(point, line.a) || pointsAreClose(point, line.b)) {
        return true;
    }

    const lineAngle = angleBetweenPoints(line.a, line.b);
    const lineLength = distance(line.a, line.b);
    const pointAngle = angleBetweenPoints(line.a, point);
    const pointDistance = distance(line.a, point);

    return areClose(lineAngle, pointAngle) && pointDistance < lineLength;
}

export function findClosestPointOnLine<T extends Point>(point: T, line: Line<T>): T {
    const lineDist = distanceFromLine(point, line);
    const lineSlope = angleBetweenPoints(line.a, line.b);
    const aAngle = (angleBetweenPoints(line.a, point) - lineSlope + 360) % 360;
    const clockwise = lineSlope - 90 + 360;
    const counterClockwise = lineSlope + 90;

    const angle = aAngle < 180 ? clockwise : counterClockwise;
    const closestPoint = translateAtAngle(point, angle, lineDist);

    return closestPoint;
}

export function findClosestPointOnLineSegment<T extends Point>(point: T, line: Line<T>): T {
    const closestPoint = findClosestPointOnLine(point, line);

    const aAngle = angleBetweenPoints(closestPoint, line.a);
    const bAngle = angleBetweenPoints(closestPoint, line.b);

    if (areClose(aAngle, bAngle)) {
        const aDist = distance(point, line.a);
        const bDist = distance(point, line.b);
        return aDist < bDist ? line.a : line.b;
    }

    return closestPoint;
}

export function distanceFromLineSegment(point: Point, line: Line): number {
    const closestPoint = findClosestPointOnLineSegment(point, line);

    return distance(point, closestPoint);
}

export function findClosestLineSegment<L extends Line>(coords: Point, lines: L[], exclude: L[] = []): L {
    const filteredLines = lines.filter((line) => !exclude.includes(line));

    const sorted = filteredLines.sort((l1, l2) => {
        return distanceFromLineSegment(coords, l1) - distanceFromLineSegment(coords, l2);
    });

    return sorted[0] ?? null;
}

export function findClosestPointOnLineSegments<T extends Point>(
    point: T,
    lines: Line<T>[],
    exclude: Line<T>[] = []
): T {
    const closestLine = findClosestLineSegment(point, lines, exclude);
    const closestPoint = findClosestPointOnLineSegment(point, closestLine);

    return closestPoint as T;
}

export function pointsAreClose(pt1: Point, pt2: Point, precision = 0.01): boolean {
    return areClose(pt1.x, pt2.x, precision) && areClose(pt1.y, pt2.y, precision);
}

export function findPercentageAlongStraightMovement(points: Point[], point: Point): number {
    const distances = arraySlidingWindow(points, distance);
    const totalDist = distances.reduce((acc, dist) => acc + dist, 0);

    let reached = false;
    let idx = 0;
    let cumulativeDistance = 0;

    while (!reached && idx < points.length - 1) {
        const currentLine = {
            a: points[idx],
            b: points[idx + 1],
        };

        reached = isPointOnLine(point, currentLine);
        idx += 1;

        if (reached) {
            cumulativeDistance += distance(currentLine.a, point);
        } else {
            cumulativeDistance += distance(currentLine.a, currentLine.b);
        }
    }

    return cumulativeDistance / totalDist;
}
