import { applyOffset } from '../utils/index.js';

import FieldPiece from './field-piece.js';
import Field from './field.js';

interface MoveProps {
    type?: string;
    anchors?: any[];
    target?: number;
    source?: number;
    origin?: FieldPoint;
    curved?: boolean;
    id?: number;
    [k: string]: any;
}

// Coordinates should always be in units of the field (not pixels)
// a FieldViewport will be used to translate those coordinates into screen space
export default class Move extends FieldPiece {
    anchors: FieldPoint[];

    target?: number;

    source?: number;

    curved?: boolean;

    points?: FieldPoint[];

    lengthRatio: number;

    // Because of how extra props are handled, a new Move cannot be instantiated directly from an existing one
    constructor({ type, anchors = [], target, source, origin, curved, id, ...props }: MoveProps) {
        super({ type, origin, id, ...props });

        this.anchors = anchors;
        this.target = target;
        this.source = source;
        this.curved = curved;
        this.lengthRatio = 1;
    }

    copy(): Move {
        return new Move(this.toObj());
    }

    insertAnchor(idx: number, coords: FieldPoint, field: Field): void {
        const anchors = this.anchors.slice();
        anchors.splice(idx, 0, field.clampCoords(coords));
        this.anchors = anchors;
    }

    updateAnchor(anchorId: number, coords: FieldPoint, field: Field): void {
        const anchors = this.anchors.slice();
        anchors.splice(anchorId, 1, field.clampCoords(coords));
        this.anchors = anchors;
    }

    applyOffset(offset: FieldPoint): Move {
        const copy = this.copy();
        copy.origin = applyOffset(copy.origin, offset);
        copy.anchors = copy.anchors.map((anc) => applyOffset(anc, offset));
        return copy;
    }

    override validate(): boolean {
        const values = [
            this.origin.x,
            this.origin.y,
            this.rotation,
            ...this.anchors.map((anc) => anc.x),
            ...this.anchors.map((anc) => anc.y),
        ];

        const invalid = values.some((n) => typeof n === 'number' && isNaN(n));

        return !invalid;
    }
}
