import { useSelector } from 'react-redux';

import { Selectors } from '../state.js';

import {
    useFetchTeamsQuery,
    useGetTeamMembersQuery,
    usePendingRosterQuery,
    useRetrieveRosterQuery,
    useSetCoachPlaceholdersMutation,
} from './api.js';

export const useAddCoachPlaceholderMutation = () => {
    const currentTeam = useSelector(Selectors.currentTeam);
    const [setCoachPlaceholders] = useSetCoachPlaceholdersMutation();

    const addCoachPlaceholder = async ({ coach }) => {
        await setCoachPlaceholders({
            activeTeam: currentTeam.id,
            coaches: (currentTeam.coachPlaceholders ?? []).concat([coach]),
        });
    };

    return [addCoachPlaceholder];
};

export const useRemoveCoachPlaceholderMutation = () => {
    const currentTeam = useSelector(Selectors.currentTeam);
    const [setCoachPlaceholders] = useSetCoachPlaceholdersMutation();

    const removeCoachPlaceholder = async ({ idx }: { idx: number }) => {
        await setCoachPlaceholders({
            activeTeam: currentTeam.id,
            coaches: currentTeam.coachPlaceholders.filter((_: any, i: number) => i !== idx),
        });
    };

    return [removeCoachPlaceholder];
};

export const useAllTeamsMembersQuery = () => {
    const { data: { ownedTeams = [], joinedTeams = [], programs = [] } = {} } = useFetchTeamsQuery();

    const teamIds = ownedTeams.concat(joinedTeams).map((tm: any) => tm.id);
    const programIds = programs.map((program) => program.id);

    const { data: teamMembers } = useGetTeamMembersQuery(
        { teamIds, programIds },
        { skip: teamIds.length === 0 && programIds.length === 0 }
    );

    return { data: teamMembers };
};

export const useLoadRosterQuery = () => {
    const uid = useSelector(Selectors.uid);
    const activeTeam = useSelector(Selectors.activeTeam);

    const { data: { roster = [], playerRoster = [], contactMap = {}, depthChart } = {} } = useRetrieveRosterQuery(
        {
            uid,
            teamId: activeTeam,
        },
        {
            skip: !uid || !activeTeam,
        }
    );

    return {
        data: {
            roster,
            playerRoster,
            contactMap,
            depthChart,
        },
    };
};

export const useLoadPendingRosterQuery = () => {
    const activeTeam = useSelector(Selectors.activeTeam);
    const canViewRoster = useSelector(Selectors.canViewRoster);
    const teamViewable = useSelector(Selectors.teamViewable);

    const { data: pendingRoster = [] } = usePendingRosterQuery(
        { teamId: activeTeam },
        { skip: !activeTeam || !canViewRoster || !teamViewable }
    );

    return {
        data: pendingRoster,
    };
};
