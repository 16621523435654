import HighlightedSearchText from '../highlighted-search-text/highlighted-search-text.js';

const nameIsSame = (name: string, streetAddress: string) => {
    if (!name || !streetAddress) {
        return false;
    }

    let i = 0;
    while (i < name.length && name.charAt(i) === streetAddress.charAt(i)) i++;
    return i > 5;
};

interface Props {
    addressComponents: {
        name?: string;
        streetAddress?: string;
        city?: string;
        state?: string;
        zipCode?: string;
    };
}

const AddressDisplay: FC<Props> = ({ addressComponents }) => {
    if (!addressComponents) return null;

    const { name, streetAddress, city, state, zipCode } = addressComponents;

    return (
        <div className="address-display">
            {name && !nameIsSame(name, streetAddress) ? (
                <>
                    <HighlightedSearchText text={name} />,{' '}
                </>
            ) : null}
            {streetAddress ? (
                <>
                    <HighlightedSearchText text={streetAddress} />,{' '}
                </>
            ) : null}
            <span className="text-nowrap">
                <HighlightedSearchText text={city ?? ''} />, <HighlightedSearchText text={state ?? ''} />{' '}
                <HighlightedSearchText text={zipCode ?? ''} />
            </span>
        </div>
    );
};

export default AddressDisplay;
