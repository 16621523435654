import { useSelector } from 'react-redux';

import { PanelHeader } from '@labradorsports/components';

import { useDispatcher } from '../../shared/hooks/index.js';
import { TeamHeader } from '../../shared/interface/index.js';
import { Selectors, playbookActions, useCurrentFolderQuery } from '../../store/index.js';

interface Props {
    team?: any;
    actionMenu?: any;
    title?: string;
    subtitle?: string;
    isTeam?: boolean;
    onBack?: AnyFunction;
}

const PlaybookHeader: FC<Props> = ({ team, actionMenu, title, subtitle, isTeam, onBack }) => {
    const openPlayFolder = useDispatcher(playbookActions.OpenPlayFolder);
    const parentFolderId = useSelector(Selectors.parentFolderId);
    const currentFolder = useCurrentFolderQuery();

    const openParentFolder = () => {
        openPlayFolder(parentFolderId);
    };

    const commonProps = {
        actionMenu,
        onBack: currentFolder ? () => openParentFolder() : onBack,
        title: currentFolder?.name ?? title,
        subtitle: currentFolder ? 'Folder' : subtitle,
    };

    return isTeam ? <TeamHeader team={team} {...commonProps} /> : <PanelHeader {...commonProps} />;
};

export default PlaybookHeader;
