import { ToolBaseTypes, ToolTypes } from '@labradorsports/constants';

import { Move } from '../models/index.js';

import { PluginContext } from './api.js';

export default {
    baseType: ToolBaseTypes.BALL,
    types: [ToolTypes.BALL],

    handleDragEnd: ({ api, frame }, anchor, offset) => {
        const closest = frame.findClosestPassable(offset, ToolTypes.PASS);

        // If there are no offense, we can't place a ball
        if (!closest) return;

        api.moveInitialBall(closest.id);
    },

    getDefaultMove({ frame }, type, offset) {
        const closest = frame.findClosestPassable(offset, ToolTypes.PASS);

        // If there are no offense, we can't place a ball
        if (!closest) return null;

        return new Move({
            type,
            origin: closest.origin,
        });
    },
} as PlayPlugin<PluginContext>;
