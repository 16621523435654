import { ToolBaseTypes, ToolTypes } from '@labradorsports/constants';

import Field from './field.js';

function defaultProps(type: string) {
    switch (type) {
        case ToolTypes.RECTANGLE:
        case ToolTypes.ELLIPSE: {
            return {
                width: 10,
                height: 5,
            };
        }

        case ToolTypes.COMMENT: {
            return {
                width: 150,
                height: 50,
            };
        }

        default: {
            return {};
        }
    }
}

// Coordinates should always be in units of the field (not pixels)
// a FieldViewport will be used to translate those coordinates into screen space
export default class FieldPiece {
    type: string;

    origin: FieldPoint;

    rotation?: number;

    id?: number;

    // eslint-disable-next-line react/static-property-placement
    props: any;

    // Because of how extra props are handled, a new FieldPiece cannot be instantiated directly from an existing one
    constructor({ type, origin, id, rotation, x, y, ...props }: any) {
        this.type = type;
        this.origin = origin;
        this.id = id;
        this.rotation = rotation;
        this.props = {
            ...defaultProps(type),
            ...props,
        };
    }

    validate(): boolean {
        const values = [this.origin.x, this.origin.y, this.rotation];

        const invalid = values.some((n) => typeof n === 'number' && isNaN(n));

        return !invalid;
    }

    toObj(): any {
        const obj: any = {};

        Object.keys(this).forEach((k) => {
            if ((this as any)[k] !== undefined) {
                obj[k] = (this as any)[k];
            }
        });

        delete obj.props;

        Object.assign(obj, this.props);

        // copy origin so it can be modified without affecting the original
        obj.origin = { ...this.origin };

        return obj;
    }
}

export function defaultPiece(PlayConfig: PlayConfigSpec, field: Field, playType: string, type: string): FieldPiece {
    const { PlayTypes } = PlayConfig;
    const origin =
        type === ToolBaseTypes.PRESET
            ? {
                  x: field.width / 2,
                  y: playType === PlayTypes.FULL_FIELD ? field.height * 0.75 : field.height / 2,
              }
            : { x: field.width / 2, y: field.height / 4 };

    return new FieldPiece({
        type,
        origin,
    });
}
