import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Button } from '@mui/material';

import { useAssets } from '@labradorsports/assets';
import { useSite } from '@labradorsports/utils';

import { Selectors } from '../../store/index.js';
import { HeaderNav, TeamsMenu, LoggedInMenu, Avatar, GlobalSearchBox } from '../index.js';

interface Props {
    hidden?: boolean;
}

const Header: FC<Props> = ({ hidden }) => {
    const user = useSelector(Selectors.user);

    const { Config } = useSite();
    const { LogoFullImg, LogoImg, SearchIcon, SearchIconWhite } = useAssets();
    const location = useLocation();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [showingSearch, setShowingSearch] = useState(false);

    useEffect(() => {
        setShowMenu(false);
    }, [location.pathname, user]);

    const showSearch = (evt) => {
        evt.stopPropagation();
        setShowingSearch(true);
    };
    const hideSearch = () => setShowingSearch(false);

    const showHome = () => {
        const { pathname } = location;

        if (pathname === '/feed') return;

        if (user) {
            navigate('/feed');
        } else {
            window.location.href = Config.SiteConfig.Url;
        }
    };

    const showLogin = () => {
        const { pathname } = location;

        if (pathname === '/login') return;

        navigate('/login');
    };

    const showSignup = () => {
        const { pathname } = location;

        if (pathname === '/signup') return;

        navigate('/signup');
    };

    const features = (evt: any) => {
        evt.preventDefault();

        window.open(`${Config.SiteConfig.PublicUrl}-features`);
    };

    const hideMenu = () => {
        requestAnimationFrame(() => {
            setShowMenu(false);
        });
    };

    const doShowMenu = () => {
        // Run on next frame so click to open menu does not instantly close it
        requestAnimationFrame(() => {
            setShowMenu(true);
        });
    };

    if (hidden) return <div />;

    const signupButton = (
        <Button className="bs signup-button" onClick={showSignup}>
            Sign Up Free
        </Button>
    );

    const loginButtons = (
        <>
            <Button className="bs" href={`${Config.SiteConfig.Url}/features/`} onClick={features} color="primary">
                Features
            </Button>
            <Button className="bs blue" onClick={showLogin}>
                Login
            </Button>
        </>
    );

    const classNames = ['header-bar'];

    if (user) {
        classNames.push('small-header');
    }

    if (showMenu) {
        classNames.push('menu-open');
    }

    if (APP) {
        classNames.push('app-header');
    }

    const headerMenuClassNames = ['header-menu'];
    if (!showMenu) {
        headerMenuClassNames.push('hidden');
    }

    if (!user) {
        headerMenuClassNames.push('d-md-none');
    }

    // APP nav has links for these pages so links here would be redundant
    const headerNavLinks: [string, string][] = [];

    if (!APP) {
        headerNavLinks.push(['Home', '/feed']);

        if (Config.Features.PlayDesigner) {
            headerNavLinks.push(['Playbook', '/playbook']);
        }
        if (Config.Features.Roster && Config.Features.TeamAdmin) {
            headerNavLinks.push(['Roster & Depth Chart', '/team']);
        }
        if (Config.Features.Schedule && Config.Features.TeamAdmin) {
            headerNavLinks.push(['Schedule', '/schedule']);
        }
    }

    return (
        <div className={classNames.join(' ')}>
            <div className="header-contents">
                <span onClick={showHome} className="logo">
                    <img className="logo-img d-none d-md-inline-block" src={LogoFullImg} alt="Lacrosse Lab Logo" />
                    <img className="logo-img d-md-none" src={LogoImg} alt="Lacrosse Lab Logo" />
                </span>
                <div className="header-buttons">
                    <div className="desktop-header-buttons">
                        {user ? null : (
                            <>
                                {loginButtons}
                                {signupButton}
                            </>
                        )}
                    </div>
                    <div className="mobile-header-buttons">
                        {user ? (
                            Config.Features.TeamAdmin ? (
                                <TeamsMenu onSelect={hideMenu} onOpen={hideMenu} />
                            ) : null
                        ) : (
                            signupButton
                        )}
                    </div>
                    {user ? <GlobalSearchBox shown={showingSearch} onHide={hideSearch} /> : null}
                    {user ? (
                        <button className="search-button" onClick={showSearch}>
                            <img className="button-icon" src={APP ? SearchIconWhite : SearchIcon} alt="" />
                        </button>
                    ) : null}
                </div>
                {user ? (
                    <div className={`avatar-container ${showMenu ? 'menu-open' : ''}`} onClick={doShowMenu}>
                        <Avatar />
                        <div className="close-menu">&times;</div>
                    </div>
                ) : null}
            </div>
            <div className={headerMenuClassNames.join(' ')} data-cy="header-menu">
                {user && Config.Features.TeamAdmin ? <TeamsMenu onSelect={hideMenu} onOpen={hideMenu} /> : null}
                <HeaderNav navLinks={headerNavLinks} />
            </div>
            <LoggedInMenu onClose={hideMenu} extraNavLinks={headerNavLinks} open={showMenu} />
        </div>
    );
};

export default Header;
