import { RefObject } from 'react';
import { useSelector } from 'react-redux';

import { useAssets } from '@labradorsports/assets';
import { DropdownMenu } from '@labradorsports/components';
import { PlaybookTabs } from '@labradorsports/constants';
import { useSite, getFilteredFolderPlayCount, getAllowedFolderOperations } from '@labradorsports/utils';

import { Selectors, useShiftPlaybookItemsMutation } from '../../store/index.js';
import { useStartSharing } from '../sharing/index.js';

interface Props {
    folder: Folder;
    readOnly?: boolean;
    playlist: any[];
    moveToFolder?: AnyFunction;
    deleteClick?: AnyFunction;
    unshareClick?: AnyFunction;
    onClick: AnyFunction;
    activeTags?: string[];
    playbookType: string;
    hideDropdown?: boolean;
    onRename: AnyFunction;
    getMenuPosition?: (menuHeight: number) => 'bottom' | 'top';
    dropdownMenuRef?: RefObject<HTMLDivElement>;
}

const PlayListFolder: FC<Props> = ({
    folder,
    readOnly,
    onClick,
    deleteClick,
    activeTags,
    playlist,
    unshareClick,
    moveToFolder,
    playbookType,
    hideDropdown,
    onRename,
    getMenuPosition,
    dropdownMenuRef,
}) => {
    const { FolderIcon } = useAssets();
    const [movePlaybookItem] = useShiftPlaybookItemsMutation();
    const startShare = useStartSharing();
    const folders = useSelector(Selectors.currentFolders);
    const team = useSelector(Selectors.currentTeam);
    const teamRole = useSelector(Selectors.currentUserTeamRole);

    const { Config } = useSite();

    const isTeamPlaybook = playbookType === PlaybookTabs.TEAM;

    const _onClick = (evt: any) => {
        // Ignore clicks from the dropdown menu
        if (!evt.target.closest('.dropdown-menu-container')) {
            onClick();
        }
    };

    const moveItem = (up: boolean) => {
        movePlaybookItem({
            itemIds: [folder.id],
            up,
        });
    };

    const allowedTo = getAllowedFolderOperations(team, readOnly, playbookType, teamRole);

    const menuOptions: {
        text: string;
        onClick?: () => any;
        show: boolean;
    }[] = [
        {
            text: 'Rename',
            onClick: onRename,
            show: allowedTo.RENAME,
        },
        {
            text: 'Delete',
            onClick: deleteClick,
            show: allowedTo.DELETE,
        },
        {
            text: 'Move to Folder',
            onClick: moveToFolder,
            show: allowedTo.MOVE,
        },
        isTeamPlaybook
            ? {
                  text: 'Unshare',
                  onClick: unshareClick,
                  show: allowedTo.SHARE,
              }
            : {
                  text: 'Share',
                  onClick: () =>
                      startShare({
                          itemIds: [folder.id],
                      }),
                  show: allowedTo.SHARE,
              },
        {
            text: 'Move Up',
            onClick: () => moveItem(true),
            show: allowedTo.MOVE,
        },
        {
            text: 'Move Down',
            onClick: () => moveItem(false),
            show: allowedTo.MOVE,
        },
    ].filter(({ show }) => show);

    const totalCount = getFilteredFolderPlayCount(Config, folders, folder.id, playlist);
    const filteredCount = getFilteredFolderPlayCount(Config, folders, folder.id, playlist, activeTags);

    return (
        <div className="play-list-folder list-item-content" onClick={_onClick}>
            <img src={FolderIcon} className="folder-icon" alt="" />
            <span className="folder-name">{folder.name}</span>
            <span className="play-count">
                {totalCount > 0 ? (
                    <>
                        {activeTags.length > 0 ? <i className="text-muted small">({filteredCount}) </i> : null}
                        {`${totalCount} Play${totalCount > 1 ? 's' : ''}`}
                    </>
                ) : (
                    'Empty'
                )}
            </span>
            {!readOnly && !hideDropdown ? (
                <DropdownMenu options={menuOptions} getMenuPosition={getMenuPosition} containerRef={dropdownMenuRef} />
            ) : null}
        </div>
    );
};

export default PlayListFolder;
