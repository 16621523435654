import { createContext, useContext, useState } from 'react';

import { PlayerShapes, ToolTypes } from '@labradorsports/constants';

import { usePlayEditor } from './playEditor.js';

export interface PlayThemeContextValue {
    colorPrimary: string;
    colorSecondary: string;
    setColorPrimary: (value: string) => void;
    setColorSecondary: (value: string) => void;
    logoUrl: string;
    getPlayerStyle: (type: string, role: string, id: number) => PlayerStyle;
    getThemeSetting: (prop: string, player: any) => { allFrames: boolean; wholeTeam: boolean };
    getBallStyle: () => BallStyle;
}

export const PlayThemeContext = createContext<PlayThemeContextValue>(null);

export const usePlayTheme = (): PlayThemeContextValue => {
    return useContext(PlayThemeContext);
};

interface Props {
    branding: any;
    teamLogoUrl?: string;
    watermark: string;
}

export const PlayThemeProvider: FC<Props> = ({ children, branding, teamLogoUrl, watermark }) => {
    const { playData, PlayConfig, currentFrame } = usePlayEditor();
    const { colorPrimary: colorPrimaryBranding = '#003e5e', colorSecondary: colorSecondaryBranding = '#ff6700' } =
        branding ?? playData?.branding ?? {};

    const [colorPrimaryOverride, setColorPrimary] = useState(null);
    const [colorSecondaryOverride, setColorSecondary] = useState(null);

    const { PlayTheme } = PlayConfig;

    const colorPrimary = colorPrimaryOverride ?? colorPrimaryBranding;
    const colorSecondary = colorSecondaryOverride ?? colorSecondaryBranding;

    const getThemeSetting = (prop: string, player: any) => {
        const wholeTeamThisFrame = Boolean(currentFrame.theme?.find((theme) => theme.target === player.type)?.[prop]);
        const thisPlayerAllFrames = Boolean(player.props?.[prop]);
        const wholeTeamAllFrames = Boolean(playData.theme?.[player.type]?.[prop]);

        return {
            wholeTeam: wholeTeamAllFrames || wholeTeamThisFrame,
            allFrames: thisPlayerAllFrames || wholeTeamAllFrames,
        };
    };

    const getPlayerColor = (type: string, role: string, id: number) => {
        const defaultFill = type === ToolTypes.BLUEPLAYER ? colorPrimary : colorSecondary;

        const typeFill = PlayTheme?.Players?.[type]?.fill;
        const roleFill = PlayTheme?.Players?.[role]?.fill;

        const globalFill = playData.theme?.[type]?.color;
        const teamFill = currentFrame.theme?.find((theme) => theme.target === type)?.color;
        const playerFill = playData.initial?.find((player) => player.id === id)?.color;
        const frameFill = currentFrame.theme?.find((theme) => theme.target === id)?.color;

        return globalFill ?? teamFill ?? playerFill ?? frameFill ?? roleFill ?? typeFill ?? defaultFill;
    };

    const getPlayerShape = (type: string, role: string, id: number) => {
        const typeShape = PlayTheme?.Players?.[type]?.shape;
        const roleShape = PlayTheme?.Players?.[role]?.shape;

        const globalShape = playData.theme?.[type]?.shape;
        const teamShape = currentFrame.theme?.find((theme) => theme.target === type)?.shape;
        const playerShape = playData.initial?.find((player) => player.id === id)?.shape;
        const frameShape = currentFrame.theme?.find((theme) => theme.target === id)?.shape;

        return globalShape ?? teamShape ?? playerShape ?? frameShape ?? roleShape ?? typeShape ?? PlayerShapes.CIRCLE;
    };

    const getPlayerStyle = (type: string, role: string, id: number): PlayerStyle => {
        const fill = getPlayerColor(type, role, id);
        const shape = getPlayerShape(type, role, id);

        return {
            fill,
            shape,
        };
    };

    const getBallStyle = () => {
        return PlayTheme?.Balls;
    };

    const value = {
        colorPrimary,
        colorSecondary,
        setColorPrimary,
        setColorSecondary,
        logoUrl: teamLogoUrl ?? watermark,
        getPlayerStyle,
        getThemeSetting,
        getBallStyle,
    };

    return <PlayThemeContext.Provider value={value}>{children}</PlayThemeContext.Provider>;
};
