import {
    serverTimestamp,
    doc,
    deleteDoc,
    updateDoc,
    addDoc,
    collection,
    setDoc,
    getDocs,
    DocumentSnapshot,
    query,
    orderBy,
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import { Addons, JoinTypes, ProfileFlags } from '@labradorsports/constants';
import {
    createNestedUpdates,
    setCookie,
    getUserRole,
    createException,
    GeneralErrors,
    getCommonError,
    processTeam,
    processProgram,
    getContactMap,
} from '@labradorsports/utils';

import firestore from '../../firebase-config.js';
import { AffiliateCouponCookieName, DataTags } from '../../shared/constants.js';
import { trackUserProperties } from '../../shared/utils.js';
import { createApiSlice } from '../api.js';
import { authApi } from '../auth/api.js';
import { BillingSettings, ProgramSettings, teamsActions } from '../index.js';
import { mainActions } from '../index.js';
import { Selectors } from '../state.js';
import { CustomMutationDefinition, CustomQueryDefinition, QueryApi } from '../types.js';

const joinTeamSuccess = ({ data: result }, { dispatch, extra }: QueryApi) => {
    const { logger } = extra;

    if (result.joinedTeams) {
        const teamId = Object.keys(result.joinedTeams)[0];

        dispatch(teamsActions.SetActiveTeam(teamId));
    }

    logger.log('joinTeam complete', { joinedAs: result.joinedAs, result });

    return result;
};

function fixCreatedDate(entity) {
    return {
        ...entity,
        createdDate: new Date(entity.createdDate),
    };
}

function prepRatingSnap(snap: DocumentSnapshot) {
    const ratingDoc = snap.data();
    ratingDoc.id = snap.id;
    ratingDoc.timestamp = ratingDoc.timestamp.toMillis();
    return ratingDoc;
}

export const teamsApi = createApiSlice({
    fetchTeams: {
        type: 'query',
        query: () => ({
            path: 'teams/retrieveTeams',
        }),
        transformResponse: (result) => {
            const { owned = [], joined = [], programs = [], pendingJoin = [] } = result;

            return {
                ownedTeams: owned.map(fixCreatedDate).map(processTeam),
                joinedTeams: joined.map(fixCreatedDate).map(processTeam),
                programs: programs
                    .map(fixCreatedDate)
                    .map(processProgram)
                    .map((program) => ({
                        ...program,
                        teams: owned.filter((team: any) => team.programId === program.id),
                    })),
                pendingJoinTeams: pendingJoin.map((team) => ({
                    ...team,
                    pending: true,
                })),
            };
        },
        onQueryEnded: (
            { data: { ownedTeams = [], joinedTeams = [], programs = [], pendingJoinTeams = [] } = {} },
            { dispatch, extra, getState }
        ) => {
            const state = getState();
            const { logger } = extra;

            logger.log('fetchTeams complete', {
                programs: programs.length,
                ownedTeams: ownedTeams.length,
                joinedTeams: joinedTeams.length,
                pendingJoinTeams: pendingJoinTeams.length,
            });

            const uid = Selectors.uid(state);

            trackUserProperties(uid, {
                ownedPrograms: programs.length,
                ownedTeams: ownedTeams.length,
                role: getUserRole(uid, programs, joinedTeams),
            });

            const teamsList = (ownedTeams ?? []).concat(joinedTeams ?? []).concat(pendingJoinTeams ?? []);

            if (teamsList.length > 0) {
                if (
                    !state.teams.activeTeam ||
                    teamsList.findIndex((tm: any) => tm.id === state.teams.activeTeam) === -1
                ) {
                    dispatch(teamsActions.SetActiveTeam(teamsList[0].id));
                }
            } else {
                dispatch(teamsActions.SetActiveTeam(undefined));
            }

            programs.map((program: any) =>
                dispatch(teamsApi.endpoints.loadEntityLogo.initiate({ programId: program.id }))
            );
        },
        providesTags: ({ ownedTeams = [], joinedTeams = [], programs = [], pendingJoinTeams = [] } = {}) => {
            const listTags = [
                { type: DataTags.OWNED_TEAM, id: 'LIST' },
                { type: DataTags.JOINED_TEAM, id: 'LIST' },
                { type: DataTags.OWNED_PROGRAM, id: 'LIST' },
                { type: DataTags.PENDING_TEAM, id: 'LIST' },
            ];

            const ownedTags = ownedTeams.map((team) => ({ type: DataTags.OWNED_TEAM, id: team.id }));
            const joinedTags = joinedTeams.map((team) => ({ type: DataTags.JOINED_TEAM, id: team.id }));
            const programTags = programs.map((program) => ({ type: DataTags.OWNED_PROGRAM, id: program.id }));
            const pendingTags = pendingJoinTeams.map((team) => ({ type: DataTags.PENDING_TEAM, id: team.id }));

            return listTags.concat(ownedTags).concat(joinedTags).concat(programTags).concat(pendingTags);
        },
    } as CustomQueryDefinition<void, any>,

    deleteProgram: {
        type: 'mutation',
        queryFn: async ({ programId }) => {
            // Delete the program - cloud functions will delete related data automatically
            await deleteDoc(doc(firestore, 'programs', programId));
        },
        invalidatesTags: (result, meta, { programId }) => [{ type: DataTags.OWNED_PROGRAM, id: programId }],
    } as CustomMutationDefinition<{ programId: string }, any>,

    createProgramSubscription: {
        type: 'mutation',
        query: ({ creatingProgram, creatingBilling }) => {
            const { logoFile, teams, ...teamConfig } = creatingProgram;

            const { couponProperties, ...billingConfig } = creatingBilling;

            return {
                path: 'teams/createProgramSubscription',
                body: {
                    teamSettings: {
                        teams: teams?.filter((team) => team.name !== '' && team.sport !== ''),
                        ...teamConfig,
                        ...billingConfig,
                    },
                },
            };
        },
        onQueryEnded: ({ data: { entity, billingEntity } }, { extra }) => {
            const { logger } = extra;

            logger.log('createProgramSubscription complete', {
                entityId: entity.id,
                cardId: billingEntity.cardId,
            });

            // Clear affiliate cookie as the coupon should only show up once
            setCookie(AffiliateCouponCookieName, '', new Date(0));
        },
        invalidatesTags: () => [
            { type: DataTags.BILLING_ENTITY, id: 'LIST' },
            { type: DataTags.OWNED_TEAM, id: 'LIST' },
            { type: DataTags.OWNED_PROGRAM, id: 'LIST' },
        ],
    } as CustomMutationDefinition<{ creatingProgram: ProgramSettings; creatingBilling: BillingSettings }, any>,

    updateProgramTeams: {
        type: 'mutation',
        query: ({ programId, oldTeams, newTeams, programAddons }) => {
            const [added, modified] = newTeams.reduce(
                ([addedAcc, modifiedAcc], newTeam) => {
                    if (!newTeam.id) {
                        return [[...addedAcc, newTeam], modifiedAcc];
                    }

                    const existing = oldTeams.find((tm) => tm.id === newTeam.id);

                    if (
                        existing.name !== newTeam.name ||
                        existing.sport !== newTeam.sport ||
                        existing.collegiateTeam !== newTeam.collegiateTeam ||
                        existing.addons?.[Addons.PLAYERS] !== newTeam.addons?.[Addons.PLAYERS] ||
                        existing.addons?.[Addons.COACHES] !== newTeam.addons?.[Addons.COACHES]
                    ) {
                        return [addedAcc, [...modifiedAcc, { ...newTeam }]];
                    }

                    return [addedAcc, modifiedAcc];
                },
                [[], []]
            );

            const removed = oldTeams.filter((oldTeam) => !newTeams.find((tm) => tm.id === oldTeam.id));

            return {
                path: 'teams/updateProgramTeams',
                body: {
                    programId,
                    added,
                    modified,
                    removed,
                    addons: programAddons,
                    quantity: newTeams.length,
                },
            };
        },
        invalidatesTags: (results, meta, { programId, oldTeams }) => [
            { type: DataTags.BILLING_ENTITY, id: programId },
            { type: DataTags.OWNED_PROGRAM, id: programId },
            { type: DataTags.OWNED_TEAM, id: 'LIST' },
            ...oldTeams.map(({ id }) => ({ type: DataTags.OWNED_TEAM, id })),
        ],
    } as CustomMutationDefinition<{ programId: string; oldTeams: any[]; newTeams: any[]; programAddons: any }, any>,

    loadEntityLogo: {
        type: 'query',
        queryFn: async ({ programId }, { extra }) => {
            const { logger } = extra;

            try {
                const url = await getDownloadURL(ref(getStorage(), `teams/${programId}/logo.png`));

                return {
                    data: url,
                };
            } catch (error) {
                if (error.code !== 'storage/object-not-found') {
                    logger.exception(getCommonError(error));
                }
            }

            return {
                data: undefined,
            };
        },
        providesTags: (result, meta, { programId }) => [{ type: DataTags.ENTITY_LOGO, id: programId }],
    } as CustomQueryDefinition<{ programId: string }, string | null>,

    updateEntityLogo: {
        type: 'mutation',
        query: ({ entityId, logo }) => ({
            path: 'teams/updateEntityLogo',
            body: {
                entityId,
                logo,
            },
        }),
        invalidatesTags: ({ entityId }) => [{ type: DataTags.ENTITY_LOGO, id: entityId }],
    } as CustomMutationDefinition<{ entityId: string; logo: any }, any>,

    updateProgram: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['updates'],
        },
        queryFn: async ({ entityId, updates }, { dispatch, getState }) => {
            const state = getState();
            const selector = teamsApi.endpoints.fetchTeams.select();
            const {
                data: { programs },
            } = selector(state) as any;

            const { logo, logoFile, teams, addons, ...entityUpdates } = updates;

            const existing = programs.find((program) => program.id === entityId);

            if (teams || addons) {
                await dispatch(
                    teamsApi.endpoints.updateProgramTeams.initiate({
                        programId: entityId,
                        oldTeams: existing.teams,
                        newTeams: teams ?? existing.teams,
                        programAddons: addons,
                    })
                );
            }

            await updateDoc(doc(firestore, 'programs', entityId), entityUpdates);

            if (logo) {
                await dispatch(
                    teamsApi.endpoints.updateEntityLogo.initiate({
                        entityId,
                        logo,
                    })
                );
            }
        },
        invalidatesTags: (results, meta, { entityId }) => [{ type: DataTags.OWNED_PROGRAM, id: entityId }],
    } as CustomMutationDefinition<{ entityId: string; updates: any }, any>,

    getTeamMembers: {
        type: 'query',
        query: ({ teamIds, programIds }) => ({
            path: 'teams/getTeamMembers',
            query: {
                teams: teamIds,
                programs: programIds,
            },
        }),
        providesTags: (result, meta, { teamIds, programIds }) => {
            const memberTags = Object.keys(result).map((k) => ({ type: DataTags.TEAM_MEMBER, id: k }));
            const teamTags = teamIds.map((id) => ({ type: DataTags.TEAM_MEMBER, id: `${id}_LIST` }));
            const programTags = programIds.map((id) => ({ type: DataTags.TEAM_MEMBER, id: `${id}_LIST` }));

            return memberTags.concat(teamTags).concat(programTags);
        },
    } as CustomQueryDefinition<{ teamIds: string[]; programIds: string[] }, any>,

    joinTeam: {
        type: 'mutation',
        extraOptions: {
            suppressError: true,
        },
        query: ({ teamKey }) => ({
            path: 'teams/joinTeam',
            body: { teamKey },
        }),
        onQueryEnded: joinTeamSuccess,
        invalidatesTags: () => [{ type: DataTags.JOINED_TEAM, id: 'LIST' }],
    } as CustomMutationDefinition<{ teamKey: string }, any>,

    joinTeamLink: {
        type: 'mutation',
        query: ({ entityId, linkCode }) => ({
            path: 'teams/joinTeamLink',
            body: {
                entityId,
                linkCode,
            },
        }),
        onQueryEnded: joinTeamSuccess,
        invalidatesTags: () => [{ type: DataTags.JOINED_TEAM, id: 'LIST' }],
    } as CustomMutationDefinition<{ entityId: string; linkCode: string }, any>,

    removeTeamMembers: {
        type: 'mutation',
        query: ({ entityId, memberIds }) => ({
            path: 'teams/removeTeamMembers',
            body: {
                entityId,
                memberIds,
            },
        }),
        invalidatesTags: (result, meta, { memberIds }) => memberIds.map((k) => ({ type: DataTags.TEAM_MEMBER, id: k })),
    } as CustomMutationDefinition<{ entityId: string; memberIds: string[] }, any>,

    updatePlayers: {
        type: 'mutation',
        queryFn: async ({ teamId, playerOrPlayers }, { extra }) => {
            const { logger } = extra;
            const players = Array.isArray(playerOrPlayers) ? playerOrPlayers : [playerOrPlayers];

            const updatedPlayers = await players.reduce(async (accumulator, { contacts, ...player }) => {
                const updated = await accumulator;

                if (player.id) {
                    logger.log('updatePlayer existing', { teamId, id: player.id });
                    const { id, ...update } = player;

                    await updateDoc(doc(firestore, 'teams', teamId, 'roster', id), update);

                    logger.log('updatePlayer existing complete');

                    return [...updated, player];
                }

                logger.log('updatePlayer new', { teamId });
                const snapshot = await addDoc(collection(firestore, 'teams', teamId, 'roster'), player);

                logger.log('updatePlayer new complete', { id: snapshot.id });

                const newPlayer = {
                    ...player,
                    id: snapshot.id,
                };

                if (contacts?.length > 0) {
                    logger.log('adding contacts', { contacts: contacts.length });

                    await contacts.reduce(async (acc: any, contact: any) => {
                        await acc;

                        const fullContact = {
                            ...contact,
                            playerId: newPlayer.id,
                        };

                        const added = await addDoc(collection(firestore, 'teams', teamId, 'roster'), fullContact);

                        logger.log('added contact', { id: added.id });
                    }, Promise.resolve());
                }

                return [...updated, newPlayer];
            }, Promise.resolve([]));

            return {
                data: updatedPlayers,
            };
        },
        invalidatesTags: (result, meta, { teamId }) => [{ type: DataTags.ROSTER_PERSON, id: `${teamId}_LIST` }],
    } as CustomMutationDefinition<{ teamId: string; playerOrPlayers: any | any[] }, any>,

    saveDepthChart: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['depth'],
        },
        queryFn: async ({ activeTeam, depth = {} }) => {
            await setDoc(doc(firestore, 'teams', activeTeam, 'roster', 'depthChart'), depth);
        },
        onQueryEnded: (result, { dispatch }) => {
            dispatch(teamsActions.DepthChartModified(false));
        },
        invalidatesTags: (result, meta, { activeTeam }) => [{ type: DataTags.DEPTH_CHART, id: activeTeam }],
    } as CustomMutationDefinition<{ activeTeam: string; depth?: any }, any>,

    removePlayers: {
        type: 'mutation',
        query: ({ activeTeam, playerIds }) => ({
            path: 'teams/removeTeamPlayers',
            body: {
                teamId: activeTeam,
                playerIds,
            },
        }),
        invalidatesTags: (result, meta, { activeTeam }) => [{ type: DataTags.DEPTH_CHART, id: activeTeam }],
    } as CustomMutationDefinition<{ activeTeam: string; playerIds: string[] }, any>,

    retrieveRoster: {
        type: 'query',
        isValid: ({ teamId }) => Boolean(teamId),
        queryFn: async ({ teamId }, { extra }) => {
            const { logger } = extra;
            const snapshot = await getDocs(collection(firestore, 'teams', teamId, 'roster'));

            const roster: any[] = [];
            let depthChart;
            snapshot.docs.forEach((rosterDoc) => {
                if (rosterDoc.id === 'depthChart') {
                    depthChart = rosterDoc.data();
                    return;
                }

                const player = rosterDoc.data();

                if (player) {
                    player.id = rosterDoc.id;
                    player.joinedAt = player.joinedAt ? player.joinedAt.toDate() : null;
                    roster.push(player);
                }
            });

            logger.log('retrieveRoster complete', { size: roster.length });

            const playerRoster = roster.filter((person: any) => !person.playerId);

            const contactMap = getContactMap(roster, playerRoster);

            return {
                data: {
                    roster,
                    depthChart,
                    playerRoster,
                    contactMap,
                },
            };
        },
        onQueryEnded: ({ data: { roster } }, { dispatch, getState }) => {
            const state = getState();

            if (roster.length > 0 && Selectors.currentUserTeamRole(state) !== JoinTypes.PLAYER) {
                const uid = Selectors.uid(state);

                dispatch(
                    authApi.endpoints.setProfileFlag.initiate({ flag: ProfileFlags.INVITED_TEAM, value: true, uid })
                );
            }

            if (!roster.find(({ id }) => id === state.teams.rosterPlayerId)) {
                dispatch(teamsActions.SetRosterPlayer(null));
            }

            dispatch(teamsActions.DepthChartModified(false));
        },
        providesTags: ({ roster }, meta, { teamId }) =>
            roster
                .map((person) => ({ type: DataTags.ROSTER_PERSON, id: person.id }))
                .concat([{ type: DataTags.ROSTER_PERSON, id: `${teamId}_LIST` }])
                .concat([{ type: DataTags.DEPTH_CHART, id: teamId }]),
    } as CustomQueryDefinition<{ teamId: string; uid: string }, any>,

    saveRating: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['player'],
        },
        queryFn: async ({ activeTeam, ratings, player }, { dispatch, extra }) => {
            const { site, logger } = extra;
            const playerRef = doc(firestore, 'teams', activeTeam, 'roster', player.id);

            const ratingsRef = await addDoc(collection(playerRef, 'ratings'), {
                timestamp: serverTimestamp(),
                overallRating: Selectors.playerRating(site.Config, player.position, ratings),
                ...ratings,
            });

            logger.log('saveRating rating add complete');

            dispatch(teamsActions.SetTempRatings());
            dispatch(teamsActions.SetActiveRatingId(ratingsRef.id));

            logger.log('saveRating update player');
            const overallRating = Selectors.playerRating(site.Config, player.position, ratings);

            await updateDoc(playerRef, {
                overallRating,
            });

            return {
                meta: { ratingId: ratingsRef.id },
                data: null,
            };
        },
        invalidatesTags: (result, { ratingId }, { player }) => [
            { type: DataTags.ROSTER_PERSON, id: player.id },
            { type: DataTags.PLAYER_RATING, id: ratingId },
        ],
    } as CustomMutationDefinition<{ activeTeam: string; ratings: any; player: any }, any>,

    getRatings: {
        type: 'query',
        queryFn: async ({ activeTeam, playerId }) => {
            const playerRef = doc(firestore, 'teams', activeTeam, 'roster', playerId);

            const snapshot = await getDocs(query(collection(playerRef, 'ratings'), orderBy('timestamp', 'desc')));

            return {
                data: snapshot.docs.map(prepRatingSnap),
            };
        },
        onQueryEnded: ({ data: ratings }, { dispatch }) => {
            if (ratings.length > 0) {
                dispatch(teamsActions.SetActiveRatingId(ratings[0].id));
            }
        },
        providesTags: (ratings = []) => ratings.map(({ id }) => ({ type: DataTags.PLAYER_RATING, id })),
    } as CustomQueryDefinition<{ activeTeam: string; playerId: string }, any>,

    inviteMembers: {
        type: 'mutation',
        query: ({ entityId, playerEmails = [], coachEmails = [], adminEmails = [] }) => ({
            path: 'teams/inviteMembers',
            body: {
                entityId,
                playerEmails,
                coachEmails,
                adminEmails,
            },
        }),
    } as CustomMutationDefinition<
        { entityId: string; playerEmails?: string[]; coachEmails?: string[]; adminEmails?: string[] },
        any
    >,

    emailTeam: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['emails', 'subject', 'body'],
        },
        query: ({ emails, subject, body, roster }) => {
            const recipients = emails.map((email) => {
                const foundContact = roster.find((contact) => contact.email === email);

                return {
                    email,
                    firstName: foundContact?.firstName,
                };
            });

            return {
                path: 'teams/emailTeam',
                body: {
                    recipients,
                    subject,
                    body,
                },
            };
        },
    } as CustomMutationDefinition<{ emails: string[]; subject: string; body: string; roster: any[] }, any>,

    setTeamFlags: {
        type: 'mutation',
        queryFn: async ({ activeTeam, flags }) => {
            await updateDoc(
                doc(firestore, 'teams', activeTeam),
                createNestedUpdates({
                    flags,
                })
            );
        },
        invalidatesTags: (result, meta, { activeTeam }) => [{ type: DataTags.OWNED_TEAM, id: activeTeam }],
    } as CustomMutationDefinition<{ activeTeam: string; flags: any }, any>,

    setCoachPlaceholders: {
        type: 'mutation',
        extraOptions: {
            unloggableArg: ['coaches'],
        },
        queryFn: async ({ activeTeam, coaches }) => {
            await updateDoc(doc(firestore, 'teams', activeTeam), {
                coachPlaceholders: coaches,
            });
        },
        invalidatesTags: (result, meta, { activeTeam }) => [{ type: DataTags.OWNED_TEAM, id: activeTeam }],
    } as CustomMutationDefinition<{ activeTeam: string; coaches: any[] }, any>,

    getInviteLink: {
        type: 'query',
        extraOptions: {
            unloggableArg: ['email'],
        },
        query: ({ email, type, entityId }) => ({
            path: 'teams/inviteLink',
            query: {
                email,
                type,
                entityId,
            },
        }),
        transformResponse: ({ link }) => link,
    } as CustomQueryDefinition<{ email: string; type: string; entityId: string }, any>,

    getInvitePreview: {
        type: 'query',
        extraOptions: {
            unloggableArg: ['email'],
        },
        query: ({ type, entityId, email }) => ({
            path: 'teams/previewInvite',
            query: {
                email,
                type,
                entityId,
            },
        }),
        transformResponse: (result) => {
            return result.html;
        },
    } as CustomQueryDefinition<{ type: string; entityId: string; email?: string }, any>,

    pendingRoster: {
        type: 'query',
        isValid: ({ teamId }) => Boolean(teamId),
        queryFn: async ({ teamId }) => {
            const snapshot = await getDocs(collection(firestore, 'teams', teamId, 'pendingRoster'));

            const roster: any[] = snapshot.docs.map((rosterDoc) => {
                const player = rosterDoc.data();

                if (player) {
                    player.id = rosterDoc.id;
                    player.joinedAt = player.joinedAt ? player.joinedAt.toDate() : null;
                }

                return player;
            });

            return {
                data: roster,
            };
        },
    } as CustomQueryDefinition<{ teamId: string }, any>,

    updatePendingRoster: {
        type: 'mutation',
        query: ({ teamId, accept, reject, assign }) => ({
            path: 'teams/updatePendingMembers',
            body: {
                teamId,
                accept,
                reject,
                assign,
            },
        }),
        onQueryEnded: ({ data: result }) => {
            if (!result.success) {
                mainActions.GenericError(
                    createException(GeneralErrors.UNKNOWN_ERROR, { details: 'Some updates were not successful' })
                );
            }
        },
        invalidatesTags: (result, meta, { teamId }) => [{ type: DataTags.ROSTER_PERSON, id: `${teamId}_LIST` }],
    } as CustomMutationDefinition<{ teamId: string; accept: string[]; reject: string[]; assign: any }, any>,

    changeUserRole: {
        type: 'mutation',
        query: ({ teamId, uid, id, newRole }) => ({
            path: 'teams/changeUserRole',
            body: {
                teamId,
                uid,
                playerId: id,
                newRole,
            },
        }),
        invalidatesTags: (result, meta, { teamId }) => [{ type: DataTags.TEAM_MEMBER, id: `${teamId}_LIST` }],
    } as CustomMutationDefinition<{ teamId: string; uid: string; id: string; newRole: string }, any>,
});

export const {
    useChangeUserRoleMutation,
    useCreateProgramSubscriptionMutation,
    useDeleteProgramMutation,
    useEmailTeamMutation,
    useFetchTeamsQuery,
    useGetInviteLinkQuery,
    useGetInvitePreviewQuery,
    useGetRatingsQuery,
    useGetTeamMembersQuery,
    useInviteMembersMutation,
    useJoinTeamLinkMutation,
    useJoinTeamMutation,
    useLoadEntityLogoQuery,
    usePendingRosterQuery,
    useRemovePlayersMutation,
    useRemoveTeamMembersMutation,
    useRetrieveRosterQuery,
    useSaveDepthChartMutation,
    useSaveRatingMutation,
    useSetCoachPlaceholdersMutation,
    useSetTeamFlagsMutation,
    useUpdateEntityLogoMutation,
    useUpdatePlayersMutation,
    useUpdatePendingRosterMutation,
    useUpdateProgramMutation,
    useUpdateProgramTeamsMutation,
} = teamsApi;
