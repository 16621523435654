import { useState } from 'react';
import { Button } from '@mui/material';

import { StyledTextField } from '@labradorsports/components';
import { GeneralErrors, TeamErrors } from '@labradorsports/utils';

import { useJoinTeamMutation } from '../../store/index.js';

interface Props {
    onJoined?: AnyFunction;
    className?: string;
}

const JoinTeam: FC<Props> = ({ className, onJoined }) => {
    const [joinTeam] = useJoinTeamMutation();
    const [teamKey, setTeamKey] = useState('');
    const [error, setError] = useState<any>({});

    const validate = () => {
        const newError: any = {};

        if (teamKey === '') {
            newError.teamKey = 'Please enter a team key';
        }

        const valid = !Object.keys(newError).some((k) => newError[k] !== false);

        setError(newError);

        return valid;
    };

    const keyChange = (event: any) => {
        const { value } = event.target;
        if (value.length > 8) return;

        setTeamKey(value.toUpperCase());
    };

    const confirmJoinTeam = () => {
        if (validate()) {
            joinTeam({ teamKey }).then((result) => {
                if (onJoined && 'data' in result) {
                    onJoined(result.data);
                } else if ('error' in result) {
                    if (result.error.code === GeneralErrors.NOT_FOUND.code) {
                        error.teamKey = 'No team found with this code';
                    } else if (result.error.code === TeamErrors.TEAM_FULL.code) {
                        error.teamKey = 'This team is already full';
                    } else if (result.error.code === TeamErrors.ALREADY_JOINED.code) {
                        error.teamKey = 'You are already joined to this team';
                    } else {
                        error.teamKey = 'An error occurred';
                    }

                    setError(error);
                }
            });
        }
    };

    return (
        <div className={`join-team ${className ?? ''}`}>
            <div className="small text-muted">
                <i>Enter the code provided to you by your coach.</i>
            </div>
            <StyledTextField
                className="team-key"
                label="Team Key"
                onChange={keyChange}
                value={teamKey}
                helperText={error.teamKey}
                error={!!error.teamKey}
                fullWidth
            />
            <Button className="join-button" variant="contained" color="primary" onClick={confirmJoinTeam}>
                Join
            </Button>
        </div>
    );
};

export default JoinTeam;
