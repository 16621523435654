import { ToolBaseTypes, ToolTypes } from '@labradorsports/constants';
import { arraySlidingWindow } from '@labradorsports/utils';

import { Field, FieldPiece, FieldViewport, FrameSnapshot, Move } from '../models/index.js';

import {
    applyOffset,
    distance,
    findClosestLineSegment,
    findClosestPointOnLineSegment,
    findPercentageAlongStraightMovement,
    getBezierControlPoints,
    getBezierDistanceFunction,
    getCoordsOffset,
    getPointAlongBezier,
    moveAlongStraightMovement,
    moveTowardsPointPercentage,
    sortByDistance,
} from './geometry.js';
import { createLines } from './play.js';

export function defaultMove(field: Field, target: FieldPoint | FieldPiece, type: string, targetId?: number): Move {
    const origin = target instanceof FieldPiece ? target.origin : target;
    const mv = new Move({
        type,
        target: targetId,
        origin: { ...origin },
    });

    mv.anchors = [
        {
            x: origin.x + 5,
            y: origin.y - 5,
        },
    ].map((anc: any) => {
        return field.clampCoords(anc);
    });

    return mv;
}

export function standstillMove(target: FieldPiece) {
    return new Move({
        target: target.id,
        origin: { ...target.origin },
        type: ToolTypes.MOVE,
        anchors: [],
    });
}

export function getMoveLines(config: PlayConfigSpec, lines: AnchorLine[]) {
    return lines.filter((line) => 'move' in line && config.MoveConfig[line.move.type].baseType === ToolBaseTypes.MOVE);
}

export function calculatePartialMovement(move: Move, percentage = 1, lengthRatio = 1): Point {
    const adjustedPercentage = Math.min(percentage / lengthRatio, 1);

    if (move.anchors.length === 1) {
        return moveTowardsPointPercentage(move.origin, move.anchors[0], adjustedPercentage);
    }

    const points = move.curved ? getCurvedMovePoints(move) : [move.origin, ...move.anchors];
    const [leg, remainingPercentage] = moveAlongStraightMovement(points, adjustedPercentage);
    const startPoint = points[leg - 1];
    const endPoint = points[leg];

    return moveTowardsPointPercentage(startPoint, endPoint, remainingPercentage);
}

export function getCurvedMoveLegs(origin: Point, anchors: Point[]) {
    const { correctedPoints, correctedControlPoints } = getBezierControlPoints([origin, ...anchors]);

    return arraySlidingWindow(correctedPoints, (a, c, idx) => {
        const relativeControlPoint = getCoordsOffset(origin, correctedControlPoints[idx]);

        const relativeA = getCoordsOffset(origin, a);
        const relativeC = getCoordsOffset(origin, c);

        const [length] = getBezierDistanceFunction(relativeA, relativeControlPoint, relativeC);

        return {
            a: relativeA,
            c: relativeC,
            controlPoint: relativeControlPoint,
            length,
        };
    });
}

export function getMoveLength(move: Move) {
    if (move.curved) {
        const legs = getCurvedMoveLegs(move.origin, move.anchors);

        return legs.reduce((acc, leg) => acc + leg.length, 0);
    }

    const distances = arraySlidingWindow([move.origin, ...move.anchors], distance);
    return distances.reduce((acc, dist) => acc + dist, 0);
}

export function getCurvedMovePoints(move: Move): Point[] {
    const numPoints = 100;
    const legs = getCurvedMoveLegs(move.origin, move.anchors);

    const points = [];

    legs.forEach((leg) => {
        for (let i = 0; i <= numPoints; i++) {
            points.push(applyOffset(move.origin, getPointAlongBezier(leg.a, leg.controlPoint, leg.c, i / numPoints)));
        }
    });

    return points;
}

export function sliceMove(move: Move, start: number, end: number): Move {
    const points = move.curved ? getCurvedMovePoints(move) : [move.origin, ...move.anchors];
    const [startLeg, startPercentage] = moveAlongStraightMovement(points, start);
    const [endLeg, endPercentage] = moveAlongStraightMovement(points, end);

    const origin = moveTowardsPointPercentage(points[startLeg - 1], points[startLeg], startPercentage);
    const endPoint = moveTowardsPointPercentage(points[endLeg - 1], points[endLeg], endPercentage);
    const anchors = points.slice(startLeg, endLeg).concat([endPoint]);

    return new Move({
        ...move.toObj(),
        // lengthRatio: 1 is to correctly handle pass animations - is it appropriate for it to be in this function?
        lengthRatio: 1,
        curved: false,
        origin,
        anchors,
    });
}

export function getRestrictedZonePath(targetMove: Move, minPercentage: number, maxPercentage: number): Point[] {
    const restrictedZone = sliceMove(targetMove, maxPercentage, minPercentage);

    return [restrictedZone.origin, ...restrictedZone.anchors];
}

export function calculatePassMoves(carrierMove: Move, pass: Move, receiverMove?: Move): Move[] {
    const prePass = carrierMove ? sliceMove(carrierMove, 0, pass.props.start) : null;
    let postPass;

    if (receiverMove) {
        postPass = sliceMove(receiverMove, pass.props.end, 1);
    }

    if (prePass) {
        prePass.id = 0;
    }

    if (postPass) {
        postPass.id = 1;
    }

    return [prePass, pass, postPass].filter(Boolean);
}

export function calculatePassTimings(carrierMove: Move, pass: Move, receiverMove?: Move): number[] {
    const start = pass.props?.start ?? 0;
    const end = pass.props?.end ?? 1;

    const passModifier = 1 - start * 0.5;

    return [0, carrierMove ? start * passModifier : null, receiverMove ? end : null, 1].filter(
        (n) => typeof n === 'number'
    );
}

export function findClosestPointAlongMove(move: Move, point: Point): { closestPoint: Point; percentage: number } {
    if (move.curved) {
        const points = getCurvedMovePoints(move).map((pt, i) => ({ ...pt, i }));
        const sortedPoints = sortByDistance(point, points);
        const closestPoint = sortedPoints[0];
        let cumulativeDistance = 0;
        let totalDist = 0;

        arraySlidingWindow(points, (a, b, idx) => {
            const dist = distance(a, b);
            if (idx < closestPoint.i) {
                cumulativeDistance += dist;
            }

            totalDist += dist;
        });

        const percentage = cumulativeDistance / totalDist;

        return {
            closestPoint,
            percentage,
        };
    }

    const closestLine = findClosestLineSegment(point, createLines(move));
    const closestPoint = findClosestPointOnLineSegment(point, closestLine);

    const percentage = findPercentageAlongStraightMovement([move.origin, ...move.anchors], closestPoint);

    return { closestPoint, percentage };
}

export function findClosestPassablePoint(
    lines: AnchorLine[],
    fieldViewport: FieldViewport,
    frame: FrameSnapshot,
    offset: FieldPoint,
    anchorType: string,
    ballId: number
) {
    const carrierMove = frame.getMove(frame.getCarrier(ballId).id);
    const closestPassable = frame.findClosestPassable(offset, anchorType, frame.hasBall(), ballId);
    const closestDistance = distance(closestPassable.origin, offset);
    const modifiedLines = lines.map((line) => fieldViewport.getFieldLine(line));

    const closestLine = findClosestLineSegment(
        offset,
        modifiedLines,
        modifiedLines.filter((line) => line.move === carrierMove)
    );

    if (!closestLine) {
        return {
            closestDistance,
            closestPassable,
        };
    }
    const closestMove = closestLine.move;

    const { closestPoint, percentage } = findClosestPointAlongMove(closestMove, offset);
    const closestLineDistance = distance(offset, closestPoint);

    return {
        closestLineDistance,
        closestLine,
        closestDistance,
        closestPassable,
        closestPoint,
        closestMove,
        percentage,
    };
}
