import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { MailTo } from '@labradorsports/components';

import { Selectors } from '../../store/index.js';

const BetaPendingPage: FC = () => {
    const navigate = useNavigate();
    const currentTeam = useSelector(Selectors.currentTeam);

    useEffect(() => {
        if (currentTeam) {
            navigate('/feed');
        }
    }, [currentTeam]);

    return (
        <div className="p-3">
            <h1>Pending</h1>
            <p>
                Your beta access is in review. Please check back later. If you think this is a mistake, please contact
                us at <MailTo email="support@labradorsports.com" />
            </p>
        </div>
    );
};

export default BetaPendingPage;
