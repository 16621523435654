import { useSelector } from 'react-redux';

import { useAssets } from '@labradorsports/assets';
import { findMatchedField } from '@labradorsports/utils';

import { useLogger } from '../../../shared/index.js';
import { Selectors, useMyGlobalSearchResults } from '../../../store/index.js';
import { PostSearchResult, EventSearchResult, RosterSearchResult, PlaySearchResult } from '../index.js';

interface CategoryResultsProps {
    items: any[];
    searchResultClick: (result: any) => void;
}

const CategoryResults = {
    posts: ({ items, searchResultClick }: CategoryResultsProps) => (
        <div className="category-results">
            <h2>Posts</h2>
            {items.map((result) => (
                <PostSearchResult key={result.objectID} result={result} onClick={searchResultClick} />
            ))}
        </div>
    ),
    events: ({ items, searchResultClick }: CategoryResultsProps) => (
        <div className="category-results">
            <h2>Events</h2>
            {items.map((result) => (
                <EventSearchResult key={result.objectID} result={result} onClick={searchResultClick} />
            ))}
        </div>
    ),
    roster: ({ items, searchResultClick }: CategoryResultsProps) => (
        <div className="category-results">
            <h2>Roster</h2>
            {items.map((result) => (
                <RosterSearchResult key={result.objectID} result={result} onClick={searchResultClick} />
            ))}
        </div>
    ),
    plays: ({ items, searchResultClick }: CategoryResultsProps) => (
        <div className="category-results">
            <h2>Plays</h2>
            {items.map((result) => (
                <PlaySearchResult key={result.objectID} result={result} onClick={searchResultClick} />
            ))}
        </div>
    ),
} as const;

interface SearchResults {
    posts: any[];
    events: any[];
    roster: any[];
    plays: any[];
}

interface Props {
    query: string;
    onHide: AnyFunction;
}

const GlobalSearchResults: FC<Props> = ({ query, onHide }) => {
    const { SearchEmpty, LoadingIcon } = useAssets();
    const logger = useLogger();
    const { data: results, isFetching } = useMyGlobalSearchResults({ query });
    const allTeamsPaid = useSelector(Selectors.allTeamsPaid);

    const searchResultClick = (resultType) => (result) => {
        const matchedField = findMatchedField(result._highlightResult);

        logger.log('searchResultClick', {
            resultType,
            matchedField: matchedField.key,
        });

        onHide();
    };

    const getSortedCategories = (results: SearchResults) => {
        return Object.entries(results)
            .filter(([, items]) => items.length > 0)
            .sort(([, itemsA], [, itemsB]) => {
                const scoreA = itemsA[0]?.userScore || 0;
                const scoreB = itemsB[0]?.userScore || 0;
                return scoreB - scoreA;
            }) as [keyof SearchResults, any[]][];
    };

    const hasResults = Object.values(results).some((category) => category.length > 0);

    const contents = (() => {
        if (isFetching) {
            return (
                <div className="text-center">
                    <img src={LoadingIcon} className="loading-icon" alt="" />
                </div>
            );
        }

        if (!hasResults) {
            return (
                <div className="text-center">
                    <img src={SearchEmpty} className="empty-search" alt="" />
                    <p>No results found</p>
                </div>
            );
        }

        const sortedCategories = getSortedCategories(results);

        return sortedCategories.map(([category, items]) => {
            const ResultsComponent = CategoryResults[category];

            return <ResultsComponent key={category} items={items} searchResultClick={searchResultClick(category)} />;
        });
    })();

    return (
        <div className="global-search-results">
            <button className="close-button" onClick={onHide}>
                &times;
            </button>
            {contents}
            {!allTeamsPaid ? (
                <div className="unpaid-warning">
                    <i>Data from inactive teams is not searchable.</i>
                </div>
            ) : null}
        </div>
    );
};

export default GlobalSearchResults;
