import { GeneralErrors, createException } from '@labradorsports/utils';

import { addCordovaHandler, promisify } from '../../../app/utils.js';
import Logger from '../../../shared/logger.js';

export const hasPermission = async (logger: Logger): Promise<boolean> =>
    promisify(window.FirebasePlugin.hasPermission, logger)();
export const grantPermission = async (logger: Logger): Promise<boolean> =>
    promisify(window.FirebasePlugin.grantPermission, logger)();

export default function initNotifications(logger: Logger) {
    addCordovaHandler(logger, 'deviceready', () => {
        window.FirebasePlugin.onMessageReceived(
            (message: any) => {
                if (message.messageType === 'notification' && message.tap) {
                    if (message.minVersion) {
                        const minParts: string[] = message.minVersion.split('.');
                        const versionParts: string[] = VERSION.split('.');

                        if (minParts.some((part, idx) => Number(part) > Number(versionParts[idx]))) {
                            return;
                        }
                    }

                    if (message.targetPath) {
                        window.location.href = `${window.location.origin}${window.location.pathname}#${message.targetPath}`;
                    }
                }
            },
            (error: any) => {
                logger.exception(createException(GeneralErrors.UNKNOWN_ERROR, { nestedError: error }));
            }
        );
    });
}

export async function getNotificationPermission(logger: Logger): Promise<boolean> {
    try {
        if (await hasPermission(logger)) {
            return true;
        }

        return grantPermission(logger);
    } catch (err) {
        console.log(err);
        return false;
    }
}

export async function getFCMToken(logger: Logger): Promise<string> {
    try {
        return promisify(window.FirebasePlugin.getToken, logger)();
    } catch (err) {
        console.log(err);
        return null;
    }
}

export function onTokenRefresh(handler: (token: string) => void): void {
    window.FirebasePlugin.onTokenRefresh(handler, (err: any) => {
        console.log(err);
    });
}

export function clearNotificationBadge(logger: Logger): void {
    addCordovaHandler(logger, 'deviceready', () => {
        cordova.plugins.notification?.badge?.configure({ autoClear: true });
    });
}
