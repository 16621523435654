import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';

import { useAssets } from '@labradorsports/assets';
import { ProfileFlags } from '@labradorsports/constants';
import { useSite } from '@labradorsports/utils';

import { SimpleModal, SocialIcons } from '../../interface/index.js';

export type ProfileSetupStepType =
    | 'playbook'
    | 'roster'
    | 'schedule'
    | 'firstPlay'
    | 'shareSocial'
    | 'followSocial'
    | 'viewPlaybook'
    | 'viewSchedule'
    | 'downloadApp';

interface Step {
    icon: string;
    title: string;
    shortDescription: string;
    longDescription: string;
    buttonText: string;
    pageLink?: string;
    tutorialLink?: string;
    profileFlag?: string;
    customContent?: ReactNode;
}

interface Props {
    type: ProfileSetupStepType;
    number: number;
    compact?: boolean;
    responsive?: boolean;
    completed?: boolean;
    setProfileFlag?: (arg: { flag: string; value: boolean }) => any;
}

const ProfileSetupStep: FC<Props> = ({ type, number, compact, responsive, completed, setProfileFlag }) => {
    const { Config } = useSite();
    const {
        LogoImg,
        SharePlayIcon,
        InviteTeamIcon,
        CreateEventIcon,
        FirstPlayIcon,
        FollowSocialIcon,
        ShareSocialIcon,
        CheckIcon,
        GooglePlayIcon,
        AppStoreIcon,
    } = useAssets();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const STEP_CONFIGS: {
        [k in ProfileSetupStepType]: Step;
    } = {
        firstPlay: {
            icon: FirstPlayIcon,
            title: 'Design',
            shortDescription: 'Create your first play.',
            longDescription: 'Use our best-in-class play designer to start building your personal playbook.',
            buttonText: 'Create Play',
            pageLink: '/playbook',
            tutorialLink: 'https://www.youtube.com/watch?v=uTT_sk0bI2w',
        },
        playbook: {
            icon: SharePlayIcon,
            title: 'Share',
            shortDescription: 'Share plays to your team playbook.',
            longDescription: 'Now that you have a team, you can begin sharing plays into your team playbook.',
            buttonText: 'View Playbook',
            pageLink: '/playbook',
            tutorialLink: 'https://www.youtube.com/watch?v=uklJNoDoNpU',
        },
        roster: {
            icon: InviteTeamIcon,
            title: 'Invite',
            shortDescription: 'Invite your coaches and players.',
            longDescription: 'With plays now shared in your team playbook, be sure to invite your team to view them.',
            buttonText: 'Invite Team',
            pageLink: '/team',
            tutorialLink: 'https://www.youtube.com/watch?v=pR69YoIuEXw',
        },
        schedule: {
            icon: CreateEventIcon,
            title: 'Schedule',
            shortDescription: 'Create your first event.',
            longDescription:
                "Complete your team profile by adding your team's schedule. Share your games, scrimmages, and practice plans.",
            buttonText: 'View Schedule',
            pageLink: '/schedule',
            tutorialLink: 'https://www.youtube.com/watch?v=XmI7Cjs9IKI',
        },
        shareSocial: {
            icon: ShareSocialIcon,
            title: 'Share',
            shortDescription: 'Share your plays to social media.',
            longDescription: 'Invite your friends to check out your plays with a public link.',
            buttonText: 'Share',
            pageLink: '/playbook',
        },
        followSocial: {
            icon: FollowSocialIcon,
            title: 'Follow',
            shortDescription: 'Follow us on social media.',
            longDescription: `Follow us on our social media channels for the latest on ${Config.SiteConfig.SiteName}.`,
            buttonText: 'Follow Us',
            profileFlag: ProfileFlags.FOLLOWED_SOCIAL,
            customContent: (
                <div className="d-flex justify-content-center mb-2">
                    <SocialIcons />
                </div>
            ),
        },
        viewPlaybook: {
            icon: SharePlayIcon,
            title: 'View',
            shortDescription: 'View your team playbook.',
            longDescription: 'Get started by reviewing your team playbook ahead of your next practice.',
            buttonText: 'View Playbook',
            pageLink: '/playbook',
        },
        viewSchedule: {
            icon: CreateEventIcon,
            title: 'View',
            shortDescription: 'View your team schedule.',
            longDescription: 'Let your coach know that you plan to attend your next team event.',
            buttonText: 'View Schedule',
            pageLink: '/schedule',
        },
        downloadApp: {
            icon: LogoImg,
            title: 'Download',
            shortDescription: 'Download our app.',
            longDescription: `Download the official ${Config.SiteConfig.SiteName} app from the App Store or Google Play store.`,
            buttonText: 'Download the App',
            profileFlag: ProfileFlags.DOWNLOADED_APP,
            customContent: (
                <div className="app-store-badges">
                    <a href={Config.SiteConfig.GooglePlayUrl} target="_blank" rel="noreferrer">
                        <img src={GooglePlayIcon} alt="Available on Google Play" />
                    </a>
                    <a
                        className="apple-app-store"
                        href={Config.SiteConfig.AppStoreUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={AppStoreIcon} alt="Available on the App Store" />
                    </a>
                </div>
            ),
        },
    };

    const config = STEP_CONFIGS[type];

    const stepClick = () => {
        if (compact && config.customContent) {
            setShowModal(true);
        } else if (config.pageLink) {
            navigate(config.pageLink);
        }

        // In compact form, update flag immediately when opening modal
        if (compact && config.profileFlag) {
            setProfileFlag({ flag: config.profileFlag, value: true });
        }
    };

    const onMouseDown = () => {
        if (config.profileFlag) {
            // In normal form, delay update to allow other action (ex: opening a link) to occur first
            setTimeout(() => {
                setProfileFlag({ flag: config.profileFlag, value: true });
            }, 0);
        }
    };

    const openTutorial = () => {
        window.open(config.tutorialLink, '_blank');
    };

    if (compact) {
        return (
            <div className="profile-setup-step compact">
                <SimpleModal open={showModal && !!config.customContent} onClose={() => setShowModal(false)}>
                    <h1>{config.buttonText}</h1>
                    <p>{config.longDescription}</p>
                    {config.customContent}
                </SimpleModal>
                <div className="step-content" onClick={stepClick}>
                    <div className="step-icon">
                        <img src={config.icon} alt="icon" />
                        {completed ? <img className="completed" src={CheckIcon} alt="completed icon" /> : null}
                    </div>
                    <Button>{config.title}</Button>
                </div>
            </div>
        );
    }

    return (
        <div className={`profile-setup-step ${responsive ? 'responsive' : ''}`}>
            <div className="step-content">
                <div className="step-icon">
                    <img src={config.icon} alt="icon" />
                    {completed ? <img className="completed" src={CheckIcon} alt="completed icon" /> : null}
                </div>

                <div className="step-details">
                    <p className="step-title">
                        <em>
                            {number}. {config.shortDescription}
                        </em>
                    </p>
                    <p className="step-description">{config.longDescription}</p>
                </div>
            </div>
            <div className="step-buttons" onMouseDown={onMouseDown}>
                {config.customContent ? (
                    config.customContent
                ) : (
                    <>
                        <Button variant="outlined" color="primary" className="m-1" onClick={stepClick}>
                            {config.buttonText}
                        </Button>
                        <br className="d-none d-lg-block" />
                        {config.tutorialLink ? (
                            <Button color="primary" className="m-1" onClick={openTutorial}>
                                Watch Tutorial
                            </Button>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default ProfileSetupStep;
