import { useSelector } from 'react-redux';

import { CircleProgressBar } from '@labradorsports/components';

import { ProfilePicture } from '../../shared/interface/index.js';
import { Selectors } from '../../store/index.js';

const Avatar: FC = () => {
    const onboardingSteps = useSelector(Selectors.onboardingSteps);
    const uid = useSelector(Selectors.uid);
    const completedOnboardingSteps = useSelector(Selectors.completedOnboardingSteps);

    const completedSteps = onboardingSteps.filter((step) => completedOnboardingSteps[step]);
    const notificationCount = onboardingSteps.length - completedSteps.length;
    const completion = (completedSteps.length / onboardingSteps.length) * 100;

    return (
        <div className="avatar">
            <ProfilePicture uid={uid} />
            <CircleProgressBar radius={23} completion={completion} />
            {notificationCount > 0 ? (
                <span className="notification-count">{notificationCount < 10 ? notificationCount : '9+'}</span>
            ) : null}
        </div>
    );
};

export default Avatar;
