import { createContext, useContext, useEffect, useState } from 'react';

import { SpecialAnchorTypes, ToolBaseTypes, ToolTypes } from '@labradorsports/constants';

import { FieldPiece, Move } from '../models/index.js';

import { usePlayback } from './playback.js';
import { usePlayEditor } from './playEditor.js';

export interface SelectedContextValue {
    selected: FieldPiece;
    setSelected: (selected: FieldPiece) => void;
    focused: number;
    setFocused: (focused: number) => void;
    editPiece: FieldPiece;
    setEditPiece: (selected: FieldPiece) => void;
}

export const SelectedContext = createContext<SelectedContextValue>(null);

export const useSelected = (): SelectedContextValue => {
    return useContext(SelectedContext);
};

export const SelectedProvider: FC = ({ children }) => {
    const [selected, _setSelected] = useState<FieldPiece>(null);
    const [focused, setFocused] = useState<number>(null);
    const [editPiece, setEditPiece] = useState<FieldPiece>(null);
    const { currentFrame, playData, currentFrameIdx, PlayConfig, readOnly } = usePlayEditor();
    const { playing } = usePlayback();

    const setSelected = (newSelected: FieldPiece) => {
        // First time selected is set to null, keep focused
        // Second time, clear focused
        if (newSelected !== null) {
            _setSelected(newSelected);

            if ([ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER].includes(newSelected.type)) {
                setFocused(newSelected.id);
            }
        } else if (selected !== null) {
            _setSelected(null);
        }
    };

    // Only maintain selection if we did not switch to/from field setup
    useEffect(() => {
        setSelected(null);
    }, [playData?.fieldSetup, playData?.id, currentFrameIdx, playing]);

    useEffect(() => {
        if (selected && currentFrame) {
            const newSelected = (() => {
                if ([ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER].includes(selected.type)) {
                    return currentFrame.getPlayer(selected.id);
                }

                if (readOnly) {
                    return null;
                }

                // All options here use optional chaining, because the selected item could be removed by an undo
                if (selected instanceof FieldPiece) {
                    if (selected.type === ToolTypes.BALL) {
                        return currentFrame.getBall(selected.id);
                    }

                    if (
                        [
                            ToolTypes.OLINE,
                            ToolTypes.DLINE,
                            ToolTypes.GOAL,
                            ToolTypes.RECTANGLE,
                            ToolTypes.ELLIPSE,
                            SpecialAnchorTypes.LINE_ROTATE,
                            SpecialAnchorTypes.SHADING_RESIZE,
                            SpecialAnchorTypes.SHADING_ROTATE,
                        ].includes(selected.type)
                    ) {
                        return currentFrame.field.getFieldPiece(selected.id);
                    }
                }

                if (selected instanceof Move) {
                    if (selected.type === ToolTypes.OPTION) {
                        return currentFrame.options?.find((opt) => opt.target === selected.target);
                    }

                    if (PlayConfig.MoveConfig[selected.type]?.baseType === ToolBaseTypes.PASS) {
                        return currentFrame.passes?.find((pass) => pass.id === selected.id);
                    }

                    if (PlayConfig.MoveConfig[selected.type]?.baseType === ToolBaseTypes.SHOOT) {
                        return currentFrame.shots?.find((shot) => shot.id === selected.id);
                    }

                    if (selected.type === ToolTypes.ASSIGN) {
                        return currentFrame.assignments?.find(
                            (asn: any) => asn.target === selected.target && asn.source === selected.source
                        );
                    }

                    if (selected.type === ToolTypes.COMMENT) {
                        return currentFrame.fieldComments?.find((comment) => comment.id === selected.id);
                    }

                    return currentFrame.moves?.find((mv: any) => mv.target === selected.target);
                }

                return null;
            })();

            if (newSelected) {
                if (newSelected !== selected) {
                    setSelected(newSelected);
                }
            } else {
                setSelected(null);
            }
        }
    }, [currentFrame, selected]);

    const value: SelectedContextValue = {
        selected,
        setSelected,
        focused,
        setFocused,
        editPiece,
        setEditPiece,
    };

    return <SelectedContext.Provider value={value}>{children}</SelectedContext.Provider>;
};
