import { SpecialAnchorTypes, ToolBaseTypes } from '@labradorsports/constants';

import { PluginContext } from '../../plugins/api.js';
import {
    createLines,
    generatePassAnchors,
    generatePassSelectedAnchors,
    getDefaultPassMove,
    handlePassDrag,
    handlePassDragEnd,
} from '../../utils/index.js';
import PlayConfig from '../play-config.js';

const { ToolTypes } = PlayConfig;

const passFrameMap = {
    [ToolTypes.SNAP]: 1,
    [ToolTypes.HANDOFF]: 2,
    [ToolTypes.PASS]: 2,
    [SpecialAnchorTypes.PASS_START]: 2,
};

export default {
    baseType: ToolBaseTypes.PASS,
    types: [ToolTypes.PASS, ToolTypes.SNAP, ToolTypes.HANDOFF, SpecialAnchorTypes.PASS_START],
    generateAnchors: generatePassAnchors,
    generateSelectedAnchors: generatePassSelectedAnchors,

    generateLines: ({ play }) => {
        if (play.fieldSetup) return [];

        return play.frameSnapshots.flatMap(
            (frame, frameIdx) =>
                frame.passes?.flatMap(createLines).map((line) => ({
                    ...line,
                    frameIdx,
                })) ?? []
        );
    },

    handleDragEnd: ({ api, play, lines, fieldViewport }, anchor, offset) => {
        // Snap always happens on frame 1
        const frameIdx = passFrameMap[anchor.type];
        const frame = play.frameSnapshots[frameIdx];

        // override frame with the correct frame based on anchor type
        const wrappedApi = {
            ...api,
            // override update pass to always specify start and frameIdx
            updatePass: (update) => {
                api.updatePass(update, frameIdx);
            },
        };

        handlePassDragEnd({ api: wrappedApi, play, lines, fieldViewport, frame }, anchor, offset);
    },

    handleDrag({ play, lines, api, fieldViewport }, anchor, offset) {
        // Snap always happens on frame 1
        const frameIdx = passFrameMap[anchor.type];
        const frame = play.frameSnapshots[frameIdx];

        // override frame with the correct frame based on anchor type
        return handlePassDrag(
            {
                play,
                lines,
                api,
                frame,
                fieldViewport,
            },
            anchor,
            offset
        );
    },

    getDefaultMove({ play, lines, fieldViewport }, type, offset) {
        // Snap always happens on frame 1
        const frameIdx = passFrameMap[type];
        const frame = play.frameSnapshots[frameIdx];

        const move = getDefaultPassMove({ play, lines, fieldViewport, frame }, type, offset);

        return move;
    },
} as PlayPlugin<PluginContext>;
