import { getSVGPoints, movePath, arcPath } from '@labradorsports/utils';

import { FieldViewport } from '../models/index.js';

interface Props {
    fieldViewport: FieldViewport;
}

const BoxGoal: FC<Props> = ({ fieldViewport }) => {
    // goalWidth === 2, so goalHeight can be simplified to this based on this calculation: h = (Math.sqrt(3) / 2) * w
    const goalHeight = Math.sqrt(3);

    const goal = [
        {
            x: fieldViewport.getPixelLength(-1),
            y: 0,
        },
        {
            x: fieldViewport.getPixelLength(1),
            y: 0,
        },
        {
            x: 0,
            y: fieldViewport.getPixelLength(goalHeight),
        },
    ];

    const goalFan =
        movePath(fieldViewport.getPixelLength(-2), fieldViewport.getPixelLength(2)) +
        arcPath(
            fieldViewport.getPixelLength(3),
            false,
            fieldViewport.getPixelLength(2),
            fieldViewport.getPixelLength(2),
            true
        );

    return (
        <>
            <line
                x1={fieldViewport.getPixelLength(-2)}
                y1={fieldViewport.getPixelLength(2)}
                x2={fieldViewport.getPixelLength(2)}
                y2={fieldViewport.getPixelLength(2)}
            />
            <polygon points={getSVGPoints(goal)} fill="transparent" />
            <path fill="transparent" d={goalFan} />
        </>
    );
};

export default BoxGoal;
