import { useSite } from '@labradorsports/utils';

import { SimpleModal } from '../../interface/index.js';

const UpdateModal: FC = () => {
    const { Config } = useSite();

    return (
        <SimpleModal onClose={() => window.location.reload()} open>
            <p>
                It looks like there&apos;s a new version of {Config.SiteConfig.SiteName}! Click <em>OK</em> to refresh.
                If this keeps happening, please contact us at{' '}
                <a href="mailto:support@labradorsports.com">support@labradorsports.com</a>
            </p>
        </SimpleModal>
    );
};

export default UpdateModal;
