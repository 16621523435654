import { findClosestNode } from '../utils/index.js';

import FieldPiece from './field-piece.js';

// An abstraction to allow usage of coordinates in Yards and display in pixels

interface Sideline {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

export default class Field {
    width: number;

    height: number;

    sideline: Sideline;

    pieces?: FieldPiece[];

    fieldType: string;

    goalDesign?: any;

    constructor(fieldWidth: number, fieldHeight: number, sideline: Partial<Sideline> = {}, config?: any) {
        this.width = fieldWidth;
        this.height = fieldHeight;
        this.sideline = {
            top: sideline.top ?? 0,
            left: sideline.left ?? 0,
            bottom: sideline.bottom ?? 0,
            right: sideline.right ?? 0,
        };

        if (config) {
            this.fieldType = config.fieldType;
            this.goalDesign = config.goalDesign;

            if (config.pieces) {
                this.pieces = config.pieces.map((pc: any) => new FieldPiece(pc));
            }
        }
    }

    clampCoords(coords: FieldPoint): FieldPoint {
        return {
            x: Math.min(Math.max(coords.x, -this.sideline.left), this.width + this.sideline.right),
            y: Math.min(Math.max(coords.y, -this.sideline.top), this.height + this.sideline.bottom),
        };
    }

    getFieldPiece(id: number): FieldPiece {
        return this.pieces?.find((pc) => pc.id === id);
    }

    findClosestPiece(coords: FieldPoint, types?: string[]): FieldPiece {
        if (!this.pieces) return null;

        const pieces = (types ? this.pieces.filter((pc) => types.includes(pc.type)) : this.pieces).map((pc) => ({
            ...pc.origin,
            piece: pc,
        }));

        const closest = findClosestNode(coords, pieces);

        return closest?.piece;
    }

    getDiagonal(): number {
        return Math.sqrt(this.width ** 2 + this.height ** 2);
    }

    getCenter(): FieldPoint {
        return {
            x: this.width / 2,
            y: this.height / 2,
        };
    }

    getFullWidth(): number {
        return this.width + this.sideline.left + this.sideline.right;
    }

    getFullHeight(): number {
        return this.height + this.sideline.top + this.sideline.bottom;
    }
}
