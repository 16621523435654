import { useCallback, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, debounce, InputAdornment } from '@mui/material';

import { useAssets } from '@labradorsports/assets';
import { StyledTextField } from '@labradorsports/components';

import GlobalSearchResults from '../global-search-results/global-search-results.js';

interface Props {
    shown: boolean;
    onHide: () => any;
}

const GlobalSearchBox: FC<Props> = ({ shown, onHide }) => {
    const { SearchIcon, SearchIconWhite } = useAssets();
    const inputRef = useRef(null);
    const [search, setSearch] = useState('');
    const [loadedSearch, setLoadedSearch] = useState('');

    const debouncedSearch = useCallback(
        debounce((str: string) => {
            setLoadedSearch(str);
        }, 500),
        []
    );

    const clearSearch = () => {
        setLoadedSearch('');
        setSearch('');
    };

    const onClickAway = (evt) => {
        if (shown && !evt.target.closest('.global-search-results')) {
            onHide();
        }
    };

    useEffect(() => {
        if (!shown) {
            clearSearch();
        } else {
            inputRef.current?.focus();
        }
    }, [shown]);

    const searchChange = (evt) => {
        setSearch(evt.target.value);

        debouncedSearch(evt.target.value);

        if (evt.target.value === '') {
            setLoadedSearch('');
        }
    };

    const keyDown = (evt: any) => {
        if (evt.key === 'Enter' && search !== '') {
            setLoadedSearch('');
        }
    };

    return (
        <div className={`global-search-box ${shown ? 'shown' : ''}`}>
            <button className="search-button" onClick={onHide}>
                <img className="button-icon" src={APP ? SearchIconWhite : SearchIcon} alt="" />
            </button>
            <ClickAwayListener onClickAway={onClickAway}>
                <div className="search-container">
                    <StyledTextField
                        className="me-3"
                        label="Search"
                        value={search}
                        onChange={searchChange}
                        onKeyDown={keyDown}
                        inputRef={inputRef}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img className="button-icon" src={APP ? SearchIconWhite : SearchIcon} alt="" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </ClickAwayListener>
            {loadedSearch !== '' ? <GlobalSearchResults query={loadedSearch} onHide={onHide} /> : null}
        </div>
    );
};

export default GlobalSearchBox;
