import { createContext, useContext, useMemo } from 'react';
import { Provider } from 'react-redux';

import { useSite } from '@labradorsports/utils';

import { initStore, RootState } from '../../store/index.js';
import { CFFetcher } from '../cloud-functions.js';
import { Logger } from '../logger.js';

export interface GlobalsProviderProps {
    initialState?: RecursivePartial<RootState>;
}

export interface GlobalsContextValue {
    logger: Logger;
    fetcher: CFFetcher;
}

export const GlobalsContext = createContext<GlobalsContextValue>(null);

export const GlobalsProvider: FC<GlobalsProviderProps> = ({ children, initialState }) => {
    const site = useSite();
    const value = useMemo(() => {
        const logger = new Logger(site.env);
        const fetcher = new CFFetcher(site.env, logger);
        const store = initStore(logger, fetcher, site, initialState);

        logger.setStore(store);
        fetcher.setStore(store);

        return {
            logger,
            fetcher,
            store,
        };
    }, []);

    return (
        <GlobalsContext.Provider value={value}>
            <Provider store={value.store}>{children}</Provider>
        </GlobalsContext.Provider>
    );
};

export const useLogger = (): Logger => {
    return useContext(GlobalsContext).logger;
};

export const useFetcher = (): CFFetcher => {
    return useContext(GlobalsContext).fetcher;
};
