import * as Constants from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';

import CollegeFullFieldBackdrop from './field-backdrop/college-full-field-backdrop.js';
import HighSchoolFullFieldBackdrop from './field-backdrop/highschool-full-field-backdrop.js';

const { Sites } = Constants;
const { Sports, PlayerPositions } = SportConfigs[Sites.footballlab];

const PlayTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    DRILL: 'DRILL',
};

const FieldTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    CUSTOM: 'CUSTOM',
};

const FieldBackdropMap = {
    [Sports.CollegeFootball]: {
        [PlayTypes.HALF_FIELD]: CollegeFullFieldBackdrop,
        [PlayTypes.FULL_FIELD]: CollegeFullFieldBackdrop,
    },
    [Sports.HighSchoolFootball]: {
        [PlayTypes.HALF_FIELD]: HighSchoolFullFieldBackdrop,
        [PlayTypes.FULL_FIELD]: HighSchoolFullFieldBackdrop,
    },
};

const FieldDescriptor = 'Field';

const GoalDesign = {};

const Formations = {
    Starting: 'Starting',
};

const ToolTypes = {
    ...Constants.ToolTypes,
    BLOCK: 'BLOCK',
    GROUNDBALL: 'GROUNDBALL',
    PRESNAP: 'PRESNAP',
    SNAP: 'SNAP',
    HANDOFF: 'HANDOFF',

    ...Formations,
};

// Dimensions in yards
const FieldSettings: DefaultFieldConfig = {
    [Sports.CollegeFootball]: {
        [PlayTypes.HALF_FIELD]: {
            width: 53,
            height: 120,
            sideline: {
                right: 2,
            },
            center: {
                x: 25,
                y: 21,
            },
            goals: [],
            zoomLevels: [1.5, 3, 4.5],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 53,
            height: 120,
            sideline: {
                right: 2,
            },
            center: {
                x: 25,
                y: 42,
            },
            goals: [],
            zoomLevels: [1.5, 3, 4.5],
        },
    },
    [Sports.HighSchoolFootball]: {
        [PlayTypes.HALF_FIELD]: {
            width: 53,
            height: 120,
            sideline: {
                right: 2,
            },
            center: {
                x: 25,
                y: 21,
            },
            goals: [],
            zoomLevels: [1.5, 3, 4.5],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 53,
            height: 120,
            sideline: {
                right: 2,
            },
            center: {
                x: 25,
                y: 42,
            },
            goals: [],
            zoomLevels: [1.5, 3, 4.5],
        },
    },
};

const FeatureFlags = {
    EnableGoalie: false,
    EnableCustomField: true,
    UnifiedField: true,
    OnionSkin: true,
};

const NewPlaySettings = {
    FrameCount: 3,
    StartingFormation: Formations.Starting,
    InitialBalls: [
        {
            role: PlayerPositions.CENTER,
        },
    ],
    ViewOptions: {
        onionSkin: {
            framesBack: 2,
            framesForward: 2,
            opacityDelta: 0,
        },
    },
};

const FrameRoles = {
    HOT: 'HOT',
    ONBALL: 'ONBALL',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TWO: 'TWO',
    THREE: 'THREE',
};

const MoveConfig: MoveConfigType = {
    ...Constants.MoveConfig,
    [ToolTypes.BLOCK]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER],
        head: 'line',
    },
    [ToolTypes.GROUNDBALL]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        strokeDashArray: '2, 4',
        shorten: 12,
    },
    [ToolTypes.PRESNAP]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        pattern: 'WAVE',
    },
    [ToolTypes.SNAP]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER],
        strokeDashArray: '2, 2',
    },
    [ToolTypes.HANDOFF]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER],
        strokeDashArray: '2, 2',
    },

    // Formation layout positions defined on a 100x100 scale
    [Formations.Starting]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 44, y: 50, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RIGHT_TACKLE } },
            { x: 47, y: 50, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RIGHT_GUARD } },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.CENTER } },
            { x: 53, y: 50, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.LEFT_GUARD } },
            { x: 56, y: 50, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.LEFT_TACKLE } },

            { x: 50, y: 55, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.QUARTERBACK } },

            { x: 40, y: 70, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RUNNING_BACK } },
            { x: 45, y: 70, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.RUNNING_BACK } },
            { x: 50, y: 70, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.TIGHT_END } },
            { x: 55, y: 70, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.WIDE_RECEIVER } },
            { x: 60, y: 70, type: ToolTypes.BLUEPLAYER, props: { role: PlayerPositions.WIDE_RECEIVER } },
        ],
    },
};

const RoleDisplay = {
    [PlayerPositions.LEFT_TACKLE]: 'LT',
    [PlayerPositions.LEFT_GUARD]: 'LG',
    [PlayerPositions.CENTER]: 'C',
    [PlayerPositions.RIGHT_GUARD]: 'RG',
    [PlayerPositions.RIGHT_TACKLE]: 'RT',
    [PlayerPositions.QUARTERBACK]: 'QB',
    [PlayerPositions.RUNNING_BACK]: 'RB',
    [PlayerPositions.TIGHT_END]: 'TE',
    [PlayerPositions.WIDE_RECEIVER]: 'WR',
};

const FrameRoleNames = {
    [ToolTypes.ORANGEPLAYER]: {
        [FrameRoles.ONBALL]: 'On-Ball',
        [FrameRoles.HOT]: 'Hot Slide',
        [FrameRoles.LEFT]: 'Left',
        [FrameRoles.RIGHT]: 'Right',
        [FrameRoles.TWO]: '2-Slide',
        [FrameRoles.THREE]: '3-Slide',
    },
};

const FieldLines = {
    GOAL: 'Goal',
    FOOTBALL: 'Football',
};

const PlayTheme: PlayThemeSpec = {
    Players: {
        [PlayerPositions.CENTER]: {
            fill: '#5592C2',
            shape: Constants.PlayerShapes.RECT,
        },
        [PlayerPositions.LEFT_TACKLE]: {
            fill: '#5592C2',
        },
        [PlayerPositions.LEFT_GUARD]: {
            fill: '#5592C2',
        },
        [PlayerPositions.RIGHT_TACKLE]: {
            fill: '#5592C2',
        },
        [PlayerPositions.RIGHT_GUARD]: {
            fill: '#5592C2',
        },
    },
};

const PlayConfig: PlayConfigSpec = {
    PlayTypes,
    FieldTypes,
    FieldDescriptor,
    GoalDesign,
    FieldSettings,
    FeatureFlags,
    NewPlaySettings,
    ToolTypes,
    MoveConfig,
    FrameRoles,
    FrameRoleNames,
    RoleDisplay,
    FieldLines,
    Formations,
    FieldBackdropMap,
    PlayTheme,
    DynamicAnimation: true,
};

export default PlayConfig;
