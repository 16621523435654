import { useSelector } from 'react-redux';

import { useMyPlaybookQuery } from '../playbook/queries.js';
import { Selectors } from '../state.js';

import { useGetGlobalSearchQuery } from './api.js';

export const useMyGlobalSearchResults = ({ query }) => {
    const { data: myPlaybook } = useMyPlaybookQuery();
    const allTeams = useSelector(Selectors.allTeams);
    const {
        data: queryResults = { posts: [], events: [], roster: [], plays: [] },
        isLoading,
        isFetching,
    } = useGetGlobalSearchQuery(
        {
            query,
            playbookId: myPlaybook?.id,
            teamIds: allTeams.map((team) => team.id),
        },
        {
            skip: query === '' || !myPlaybook?.id || !allTeams,
        }
    );

    return {
        data: queryResults,
        isLoading,
        isFetching,
    };
};
