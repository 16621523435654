import { createAssetGetter } from '@labradorsports/assets';
import * as Constants from '@labradorsports/constants';
import { Sites } from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';

import BoxGoal from './box-goal.js';
import BoxFullFieldBackdrop from './field-backdrop/box-full-field-backdrop.js';
import BoxHalfFieldBackdrop from './field-backdrop/box-half-field-backdrop.js';
import MensFullFieldBackdrop from './field-backdrop/mens-full-field-backdrop.js';
import MensHalfFieldBackdrop from './field-backdrop/mens-half-field-backdrop.js';
import WomensFullFieldBackdrop from './field-backdrop/womens-full-field-backdrop.js';
import WomensHalfFieldBackdrop from './field-backdrop/womens-half-field-backdrop.js';
import Goal from './goal.js';

const { Sports, PlayerPositions } = SportConfigs[Sites.lacrosselab];

const HotIconWhite = createAssetGetter('img/lacrosse/HotIcon.svg');
const HotIcon = createAssetGetter('img/lacrosse/HotSlide.svg');

const FieldDescriptor = 'Field';

const PlayTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    DRILL: 'DRILL',
};

const FieldTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    CUSTOM: 'CUSTOM',
};

const FieldBackdropMap = {
    [Sports.MensLax]: {
        [PlayTypes.HALF_FIELD]: MensHalfFieldBackdrop,
        [PlayTypes.FULL_FIELD]: MensFullFieldBackdrop,
    },
    [Sports.WomensLax]: {
        [PlayTypes.HALF_FIELD]: WomensHalfFieldBackdrop,
        [PlayTypes.FULL_FIELD]: WomensFullFieldBackdrop,
    },
    [Sports.BoxLax]: {
        [PlayTypes.HALF_FIELD]: BoxHalfFieldBackdrop,
        [PlayTypes.FULL_FIELD]: BoxFullFieldBackdrop,
    },
};

const GoalDesign = {
    [Sports.MensLax]: Goal,
    [Sports.WomensLax]: Goal,
    [Sports.BoxLax]: BoxGoal,
};

const Formations = {
    _231: '_231',
    _33: '_33',
    _222: '_222',
    _141: '_141',
    Circle: 'Circle',

    WomensCircle: 'WomensCircle',
    _232: '_232',

    _3out2in: '_3out2in',
    _4out1in: '_4out1in',
    _5out: '_5out',
};

const ToolTypes = {
    ...Constants.ToolTypes,
    PICK: 'PICK',
    GROUNDBALL: 'GROUNDBALL',
    MOVE2: 'MOVE2',

    ...Formations,

    // Included for legacy plays
    /** @deprecated */
    ROLLBACK: 'ROLLBACK',
    /** @deprecated */
    DODGE: 'DODGE',
};

// Dimensions in yards
const FieldSettings: DefaultFieldConfig = {
    [Sports.MensLax]: {
        [PlayTypes.HALF_FIELD]: {
            width: 60,
            height: 55,
            sideline: {
                right: 2,
            },
            center: {
                x: 30,
                y: 27.5,
            },
            goals: [
                {
                    origin: { x: 30, y: 40 },
                    rotation: 0,
                    goalieVisible: true,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            zoomLevels: [1, 2],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 60,
            height: 110,
            sideline: {
                right: 2,
            },
            center: {
                x: 30,
                y: 55,
            },
            goals: [
                {
                    origin: { x: 30, y: 95 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 30, y: 15 },
                    rotation: 180,
                    goalieVisible: true,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
            zoomLevels: [1, 2],
        },
    },
    [Sports.WomensLax]: {
        [PlayTypes.HALF_FIELD]: {
            width: 70,
            height: 60,
            sideline: {
                right: 2,
            },
            center: {
                x: 35,
                y: 30,
            },
            goals: [
                {
                    origin: { x: 35, y: 45 },
                    rotation: 0,
                    goalieVisible: true,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            zoomLevels: [1, 2],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 70,
            height: 120,
            sideline: {
                right: 2,
            },
            center: {
                x: 35,
                y: 60,
            },
            goals: [
                {
                    origin: { x: 35, y: 105 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 35, y: 15 },
                    rotation: 180,
                    goalieVisible: true,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
            zoomLevels: [1, 2],
        },
    },
    [Sports.BoxLax]: {
        [PlayTypes.HALF_FIELD]: {
            width: 30,
            height: 33,
            sideline: {
                right: 2,
            },
            center: {
                x: 15,
                y: 16.5,
            },
            goals: [
                {
                    origin: { x: 15, y: 28 },
                    rotation: 0,
                    goalieVisible: true,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            zoomLevels: [1, 2],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 30,
            height: 66,
            sideline: {
                right: 2,
            },
            center: {
                x: 15,
                y: 33,
            },
            goals: [
                {
                    origin: { x: 15, y: 61 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 15, y: 5 },
                    rotation: 180,
                    goalieVisible: true,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
            zoomLevels: [1, 2],
        },
    },
};

const FeatureFlags = {
    EnableGoalie: true,
    EnableCustomField: true,
    UnifiedField: true,
    OnionSkin: false,
};

const NewPlaySettings = {
    FrameCount: 1,
};

const FrameRoles = {
    HOT: 'HOT',
    ONBALL: 'ONBALL',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TWO: 'TWO',
    THREE: 'THREE',
};

const MoveConfig: MoveConfigType = {
    ...Constants.MoveConfig,
    [ToolTypes.PICK]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER],
        head: 'line',
    },
    [ToolTypes.GROUNDBALL]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        strokeDashArray: '2, 4',
        shorten: 12,
    },
    [ToolTypes.MOVE2]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        pattern: 'WAVE',
    },

    // Formation layout positions defined on a 100x100 scale
    [Formations._231]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 33.3, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 66.7, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._33]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 25, y: 40, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 60, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 40, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 60, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 40, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 60, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._222]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 25, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 40, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 60, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._141]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 50, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 20, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 40, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 60, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 80, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations.Circle]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 25, y: 33.3, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 33.3, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 66.7, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 66.7, type: ToolTypes.BLUEPLAYER },
        ],
    },

    [Formations.WomensCircle]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 50, y: 40, type: ToolTypes.BLUEPLAYER },
            { x: 35, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 65, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 60, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 60, type: ToolTypes.BLUEPLAYER },
            { x: 40, y: 80, type: ToolTypes.BLUEPLAYER },
            { x: 60, y: 80, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._232]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 33.3, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 66.7, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 33.3, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 66.7, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },

    [Formations._3out2in]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 50, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._4out1in]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 25, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._5out]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 50, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 33.3, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 66.7, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
};

const RoleDisplay = {
    [FrameRoles.ONBALL]: 'B',
    [FrameRoles.LEFT]: 'L',
    [FrameRoles.RIGHT]: 'R',
    [FrameRoles.TWO]: '2',
    [FrameRoles.THREE]: '3',
    [FrameRoles.HOT]: {
        url: HotIcon,
        urlWhite: HotIconWhite,
    },

    [PlayerPositions.ATTACK]: 'A',
    [PlayerPositions.DEFENSE]: 'D',
    [PlayerPositions.GOALKEEPER]: 'G',
    [PlayerPositions.LSM]: 'LS',
    [PlayerPositions.MIDFIELD]: 'M',
    [PlayerPositions.FACEOFF]: 'FO',
};

const FrameRoleNames = {
    [ToolTypes.ORANGEPLAYER]: {
        [FrameRoles.ONBALL]: 'On-Ball',
        [FrameRoles.HOT]: 'Hot Slide',
        [FrameRoles.LEFT]: 'Left',
        [FrameRoles.RIGHT]: 'Right',
        [FrameRoles.TWO]: '2-Slide',
        [FrameRoles.THREE]: '3-Slide',
    },
};

const FieldLines = {
    GOAL: 'Goal',
    FOOTBALL: 'Football',
};

const PlayConfig: PlayConfigSpec = {
    PlayTypes,
    FieldTypes,
    FieldDescriptor,
    GoalDesign,
    FieldSettings,
    FeatureFlags,
    NewPlaySettings,
    ToolTypes,
    MoveConfig,
    FrameRoles,
    FrameRoleNames,
    RoleDisplay,
    FieldLines,
    Formations,
    FieldBackdropMap,
    DynamicAnimation: true,
};

export default PlayConfig;
