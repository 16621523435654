import { Component } from 'react';

import { Exception, GeneralErrors, cleanStack, createException } from '@labradorsports/utils';

import { CrashPage } from '../index.js';

class ErrorBoundary extends Component<any, any> {
    state: any = {};

    componentDidCatch(error: any, info: any) {
        const finalException =
            error instanceof Exception
                ? error
                : createException(GeneralErrors.UNKNOWN_ERROR, {
                      nestedError: error,
                  });

        this.props.logger.log(cleanStack(info.componentStack, this.props.env.baseUrl));
        this.props.logger.exception(finalException);

        this.setState({
            error: finalException,
        });
    }

    render() {
        const { children } = this.props;
        const { error } = this.state;

        if (error) return <CrashPage error={error} />;

        return children;
    }
}

export default ErrorBoundary;
