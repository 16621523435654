 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface TeamsState {
    activeTeam: string;
    tempTeams: any[];
    rosterPlayerId: string;
    tempRatings: any;
    activeRatingId: string;
    depthChartModified: boolean;
}

const teamsDefaultState: TeamsState = {
    activeTeam: undefined,
    tempTeams: undefined,
    rosterPlayerId: undefined,
    tempRatings: undefined,
    activeRatingId: undefined,
    depthChartModified: false,
};

const { reducer: teamsReducer, actions: teamsActions } = createSlice({
    name: 'teams',
    initialState: teamsDefaultState,
    reducers: {
        SetActiveTeam: (state, action) => {
            if (action.payload !== state.activeTeam) {
                state.activeTeam = action.payload;
            }
        },

        SetRosterPlayer: (state, action) => {
            state.rosterPlayerId = action.payload;
        },

        SetTempRatings: {
            prepare: (payload?: any) => ({ payload }),
            reducer: (state, action: PayloadAction<any>) => {
                state.tempRatings = action.payload;
            },
        },

        SetActiveRatingId: (state, action) => {
            state.activeRatingId = action.payload;
        },

        DepthChartModified: (state, action) => {
            state.depthChartModified = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: () => teamsDefaultState,
    },
});

export { teamsReducer, teamsActions, teamsDefaultState };
