import { configureStore, isPlain } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { objectMerge } from '@labradorsports/utils';

import { CFFetcher } from '../shared/cloud-functions.js';
import Logger from '../shared/logger.js';

import { authReducer } from './auth/index.js';
import { billingReducer } from './billing/index.js';
import { communityReducer } from './community/index.js';
import { mainReducer } from './main/index.js';
import { messagingReducer } from './messaging/index.js';
import { playEditorReducer } from './play-editor/index.js';
import { playbookReducer } from './playbook/index.js';
import { scheduleReducer } from './schedule/index.js';
import { scoutingReportReducer } from './scouting-report/index.js';
import { RootState, defaultState } from './state.js';
import { teamsReducer } from './teams/index.js';
// TODO: figure out why this import needs to be after the ./state.js import for the SSR build
// eslint-disable-next-line import/order
import { emptySplitApi } from './api.js';

const routerReducer = (appReducer) => {
    const locationReducer = (state, action) => {
        const { type, payload } = action;
        if (type !== 'UPDATE_STATE' || !payload) {
            return state;
        }

        return objectMerge(state, payload);
    };

    return function combinedReducer(state, action) {
        const postReducerState = appReducer(state, action);
        const postLocationState = locationReducer(postReducerState, action);
        const hasChanged = postLocationState !== state;
        return hasChanged ? postLocationState : state;
    };
};

const reducers = combineReducers({
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    main: mainReducer,
    messaging: messagingReducer,
    auth: authReducer,
    playbook: playbookReducer,
    playEditor: playEditorReducer,
    teams: teamsReducer,
    schedule: scheduleReducer,
    billing: billingReducer,
    scoutingReport: scoutingReportReducer,
    community: communityReducer,
});

export function initStore(
    logger: Logger,
    fetcher: CFFetcher,
    site: SiteSpec,
    initialState: RecursivePartial<RootState> = {}
) {
    const preloadedState = objectMerge(
        {
            ...defaultState,
            playEditor: {
                PlayConfig: site.PlayConfig,
            },
        },
        initialState
    );

    return configureStore({
        middleware: (defaultMiddleware) => [
            ...defaultMiddleware({
                serializableCheck: {
                    isSerializable: (value: any) => value instanceof Date || isPlain(value),
                    ignoredPaths: [
                        'auth.user',
                        'auth.accountFormError',
                        'billing.creatingTeam.logoFile',
                        'main.genericError',
                        'playEditor.PlayConfig',
                    ],
                    ignoredActions: [
                        'auth/LoginChange',
                        'auth/AccountFormError',
                        'playEditor/RemoveMove',
                        'main/GenericError',
                    ],
                    ignoredActionPaths: ['payload.logoFile', 'meta'],
                },
                immutableCheck: {
                    ignoredPaths: ['auth.user', 'billing.creatingTeam.logoFile'],
                },
                thunk: {
                    extraArgument: {
                        logger,
                        cff: fetcher,
                        site,
                    },
                },
            }),
            emptySplitApi.middleware,
        ],
        reducer: routerReducer(reducers),
        preloadedState,
    });
}

// Redux state
export * from './state.js';
export * from './auth/index.js';
export * from './main/index.js';
export * from './messaging/index.js';
export * from './playbook/index.js';
export * from './teams/index.js';
export * from './schedule/index.js';
export * from './play-editor/index.js';
export * from './billing/index.js';
export * from './scouting-report/index.js';
export * from './community/index.js';

// RTK Query api slices
export * from './api.js';
export * from './auth/api.js';
export * from './billing/api.js';
export * from './playbook/api.js';
export * from './main/api.js';
export * from './messaging/api.js';
export * from './schedule/api.js';
export * from './scouting-report/api.js';
export * from './teams/api.js';

// Compound queries
export * from './auth/queries.js';
export * from './billing/queries.js';
export * from './playbook/queries.js';
export * from './main/queries.js';
export * from './schedule/queries.js';
export * from './scouting-report/queries.js';
export * from './teams/queries.js';
