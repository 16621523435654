 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface ProgramSettings {
    id?: string;
    name?: string;
    sport?: string;
    logo?: string;
    logoFile?: File;
    colorPrimary?: string;
    colorSecondary?: string;
    teams?: Team[];
    addons?: {
        [k: string]: number;
    };
}

export interface BillingSettings {
    plan?: string;
    billingFrequency?: string;
    nameOnCard?: string;
    cardToken?: any;
    existingCard?: string;
    couponCode?: string;
    couponProperties?: {
        discount?: number;
        type?: 'percent' | 'flat';
        durationMonths?: number;
        freeTrial?: number;
    };
    sendInvoice?: boolean;
    invoiceEmail?: string;
    thirdParty?: {
        email: string;
        cardId: string;
    };
    referralCode?: string;
    billingAddress?: {
        addressLine1: string;
        addressLine2?: string;
        city: string;
        state: string;
        zipCode: string;
    };
    paymentSuspended?: boolean;
    lastPayment?: Date;
    failedPayments?: number;
    failureMessage?: string;
}

export interface BillingState {
    creatingProgram: ProgramSettings;
    creatingBilling: BillingSettings;
    editBillingEntityId: string;
    invoiceLinkId?: string;
    invoiceError?: string;
}

const billingDefaultState: BillingState = {
    creatingProgram: {},
    creatingBilling: {},
    editBillingEntityId: null,
};

const { reducer: billingReducer, actions: billingActions } = createSlice({
    name: 'billing',
    initialState: billingDefaultState,
    reducers: {
        UpdateCreatingProgram: (state, action) => {
            state.creatingProgram = {
                ...state.creatingProgram,
                ...action.payload,
            };
        },

        UpdateCreatingBilling: (state, action) => {
            state.creatingBilling = {
                ...state.creatingBilling,
                ...action.payload,
            };
        },

        ResetCreatingProgram: (state) => {
            state.creatingProgram = {};
            state.creatingBilling = {};
        },

        SetEditBillingEntity: (state, action: PayloadAction<string | null>) => {
            state.editBillingEntityId = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: () => billingDefaultState,
    },
});

export { billingReducer, billingActions, billingDefaultState };
