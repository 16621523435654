 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MessagingState {
    viewDocumentAcksId: string;
    viewPostId: string;
}

const messagingDefaultState: MessagingState = {
    viewDocumentAcksId: undefined,
    viewPostId: undefined,
};

const { reducer: messagingReducer, actions: messagingActions } = createSlice({
    name: 'messaging',
    initialState: messagingDefaultState,
    reducers: {
        ViewDocumentAcks: (state, action: PayloadAction<string | null>) => {
            state.viewDocumentAcksId = action.payload;
        },
        ViewPost: (state, action: PayloadAction<string | null>) => {
            state.viewPostId = action.payload;
        },
    },
});

export { messagingReducer, messagingActions, messagingDefaultState };
