interface Props {
    text: string;
}

function stripHTML(text: string) {
    return text?.replace(/<\/?(.*?)>/g, ' ');
}

const HighlightedSearchText: FC<Props> = ({ text }) => {
    if (!text) {
        return null;
    }

    const match = text.matchAll(/<em>(.*?)<\/em>/g);

    const [end, elements] = Array.from(match).reduce(
        ([remaining = '', acc], [outer, inner]) => {
            const [pre, post] = remaining.split(outer);

            remaining = post;

            return [remaining, [...acc, stripHTML(pre), <em key={Math.random()}>{stripHTML(inner)}</em>]];
        },
        [text, []] as [string, any[]]
    );

    return (
        <>
            {elements}
            {stripHTML(end)}
        </>
    );
};

export default HighlightedSearchText;
