import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { ButtonsBar, Panel } from '@labradorsports/components';
import { PlaybookTabs } from '@labradorsports/constants';
import { rootFolders } from '@labradorsports/utils';

import { RootState, Selectors } from '../../store/index.js';
import { FolderList } from '../index.js';

interface Props {
    selectFolder: (folderId: string) => any;
    onCancel: () => any;
    itemIds?: string[];
}

const MoveToFolder: FC<Props> = ({ selectFolder, onCancel, itemIds }) => {
    const folders = useSelector(Selectors.currentFolders);
    const shownPlaybook = useSelector((state: RootState) => state.playbook.shownPlaybook);

    const [selectedFolder, setSelectedFolder] = useState(null);

    const confirmClick = () => {
        selectFolder(selectedFolder);
    };

    // Mock the playbook as the top-most folder
    const playbookFolder: Folder = {
        id: shownPlaybook,
        name: shownPlaybook === PlaybookTabs.MY ? 'My Playbook' : 'Team Playbook',
        folders: rootFolders(folders).map((folder) => folder.id),
        plays: [],
    };

    return (
        <div className="move-to-folder-container">
            <Panel
                className="move-to-folder"
                content={
                    <>
                        <h4>Add to Folder</h4>
                        <p>Select the folder to which you would like to add your play:</p>
                        {folders?.length > 0 ? (
                            <FolderList
                                allFolders={folders}
                                folders={[playbookFolder]}
                                folderClick={setSelectedFolder}
                                selectedId={selectedFolder}
                                disabledFolderIds={itemIds}
                            />
                        ) : (
                            <p>You have no folders created yet.</p>
                        )}
                    </>
                }
                footer={
                    <ButtonsBar>
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={confirmClick} disabled={!selectedFolder}>
                            Confirm
                        </Button>
                    </ButtonsBar>
                }
            />
        </div>
    );
};

export default MoveToFolder;
