import * as Constants from '@labradorsports/constants';
import SportConfigs from '@labradorsports/sports';

import CollegeFullFieldBackdrop from './field-backdrop/college-full-field-backdrop.js';
import CollegeHalfFieldBackdrop from './field-backdrop/college-half-field-backdrop.js';
import HighSchoolFullFieldBackdrop from './field-backdrop/highschool-full-field-backdrop.js';
import HighSchoolHalfFieldBackdrop from './field-backdrop/highschool-half-field-backdrop.js';
import Hoop from './hoop.js';

const { Sites } = Constants;
const { Sports, PlayerPositions } = SportConfigs[Sites.hoopslab];

const PlayTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    DRILL: 'DRILL',
};

const FieldTypes = {
    HALF_FIELD: 'HALF_FIELD',
    FULL_FIELD: 'FULL_FIELD',
    CUSTOM: 'CUSTOM',
};

const FieldDescriptor = 'Court';

const FieldBackdropMap = {
    [Sports.HighSchoolBBall]: {
        [PlayTypes.HALF_FIELD]: HighSchoolHalfFieldBackdrop,
        [PlayTypes.FULL_FIELD]: HighSchoolFullFieldBackdrop,
    },
    [Sports.MensCollegeBBall]: {
        [PlayTypes.HALF_FIELD]: CollegeHalfFieldBackdrop,
        [PlayTypes.FULL_FIELD]: CollegeFullFieldBackdrop,
    },
    [Sports.WomensCollegeBBall]: {
        [PlayTypes.HALF_FIELD]: CollegeHalfFieldBackdrop,
        [PlayTypes.FULL_FIELD]: CollegeFullFieldBackdrop,
    },
};

const GoalDesign = {
    [Sports.HighSchoolBBall]: Hoop,
    [Sports.MensCollegeBBall]: Hoop,
    [Sports.WomensCollegeBBall]: Hoop,
};

const Formations = {
    _3out2in: '_3out2in',
    _4out1in: '_4out1in',
    _5out: '_5out',
};

const ToolTypes = {
    ...Constants.ToolTypes,
    PICK: 'PICK',
    DRIBBLE: 'DRIBBLE',
    REBOUND: 'REBOUND',

    ...Formations,

    // Included for legacy plays
    /** @deprecated */
    ROLLBACK: 'ROLLBACK',
    /** @deprecated */
    DODGE: 'DODGE',
};

// Dimensions in feet
const FieldSettings: DefaultFieldConfig = {
    [Sports.HighSchoolBBall]: {
        [PlayTypes.HALF_FIELD]: {
            width: 50,
            height: 42,
            sideline: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            center: {
                x: 25,
                y: 21,
            },
            goals: [
                {
                    origin: { x: 25, y: 37.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            zoomLevels: [1, 1.5],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 50,
            height: 84,
            sideline: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            center: {
                x: 25,
                y: 42,
            },
            goals: [
                {
                    origin: { x: 25, y: 79.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 25, y: 4.75 },
                    rotation: 180,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
            zoomLevels: [1, 1.5],
        },
    },
    [Sports.MensCollegeBBall]: {
        [PlayTypes.HALF_FIELD]: {
            width: 50,
            height: 47,
            sideline: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            center: {
                x: 25,
                y: 23.5,
            },
            goals: [
                {
                    origin: { x: 25, y: 42.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            zoomLevels: [1, 1.5],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 50,
            height: 94,
            sideline: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            center: {
                x: 25,
                y: 47,
            },
            goals: [
                {
                    origin: { x: 25, y: 89.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 25, y: 4.75 },
                    rotation: 180,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
            zoomLevels: [1, 1.5],
        },
    },
    [Sports.WomensCollegeBBall]: {
        [PlayTypes.HALF_FIELD]: {
            width: 50,
            height: 47,
            sideline: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            center: {
                x: 25,
                y: 23.5,
            },
            goals: [
                {
                    origin: { x: 25, y: 42.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
            ],
            zoomLevels: [1, 1.5],
        },
        [PlayTypes.FULL_FIELD]: {
            width: 50,
            height: 94,
            sideline: {
                top: 4,
                right: 4,
                bottom: 4,
                left: 4,
            },
            center: {
                x: 25,
                y: 47,
            },
            goals: [
                {
                    origin: { x: 25, y: 89.25 },
                    rotation: 0,
                    goalieType: ToolTypes.ORANGEPLAYER,
                },
                {
                    origin: { x: 25, y: 4.75 },
                    rotation: 180,
                    goalieType: ToolTypes.BLUEPLAYER,
                },
            ],
            zoomLevels: [1, 1.5],
        },
    },
};

const FeatureFlags = {
    EnableGoalie: false,
    EnableCustomField: false,
    UnifiedField: true,
    OnionSkin: false,
};

const NewPlaySettings = {
    FrameCount: 1,
};

const FrameRoles = {};

const FrameRoleNames = {};

const PlayTheme: PlayThemeSpec = {
    Balls: {
        fill: '#ff6700',
    },
};

const MoveConfig: MoveConfigType = {
    ...Constants.MoveConfig,
    [ToolTypes.PICK]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER],
        head: 'line',
    },
    [ToolTypes.DRIBBLE]: {
        baseType: Constants.ToolBaseTypes.MOVE,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        pattern: 'WAVE',
    },
    [ToolTypes.REBOUND]: {
        baseType: Constants.ToolBaseTypes.PASS,
        targets: [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER],
        strokeDashArray: '2, 4',
        shorten: 12,
    },

    // Formation layout positions defined on a 100x100 scale
    [Formations._3out2in]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 50, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._4out1in]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 25, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 50, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
    [Formations._5out]: {
        baseType: Constants.ToolBaseTypes.PRESET,
        layout: [
            { x: 50, y: 25, type: ToolTypes.BLUEPLAYER },
            { x: 25, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 75, y: 50, type: ToolTypes.BLUEPLAYER },
            { x: 33.3, y: 75, type: ToolTypes.BLUEPLAYER },
            { x: 66.7, y: 75, type: ToolTypes.BLUEPLAYER },
        ],
    },
};

const RoleDisplay = {
    [PlayerPositions.POINT_GUARD]: 'PG',
    [PlayerPositions.SHOOTING_GUARD]: 'SG',
    [PlayerPositions.SMALL_FORWARD]: 'SF',
    [PlayerPositions.POWER_FORWARD]: 'PF',
    [PlayerPositions.CENTER]: 'C',
};

const PlayConfig: PlayConfigSpec = {
    PlayTypes,
    FieldTypes,
    FieldDescriptor,
    GoalDesign,
    FieldSettings,
    FeatureFlags,
    NewPlaySettings,
    ToolTypes,
    MoveConfig,
    FrameRoles,
    FrameRoleNames,
    PlayTheme,
    Formations,
    RoleDisplay,
    FieldBackdropMap,
    DynamicAnimation: true,
};

export default PlayConfig;
