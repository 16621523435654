import { createContext, useContext, useEffect, useState, ReactNode, useRef, useMemo, RefObject } from 'react';
import { createPortal } from 'react-dom';

import { useSite } from '@labradorsports/utils';

import { Play, FrameSnapshot } from '../models/index.js';
import { initializePlay } from '../utils/index.js';

export interface PlayEditorContextValue {
    PlayConfig: PlayConfigSpec;
    Plugins: PlayPlugin[];
    ToolboxConfig: ToolboxConfigSpec;
    fieldUrl: string;
    readOnly: boolean;

    showHelpMenu?: boolean;
    toggleHelpMenu?: AnyFunction;

    dimensions: Dimensions;
    setDimensions: (dimensions: Dimensions) => void;

    currentFrameIdx: number;
    currentFrame: FrameSnapshot;
    setCurrentFrame?: (val?: FrameSnapshot) => void;
    selectFrame: (idx: number) => void;

    play: Play;
    playData: any;
    fieldSetup: boolean;
    debugOptions?: any;
    debugMarkers?: any[];

    disableAnimation: boolean;
    setDisableAnimation: (value: boolean) => void;

    fieldRef: RefObject<SVGSVGElement>;
    editorRef: RefObject<HTMLDivElement>;
    renderFieldElement: (el: ReactNode) => ReactNode;
}

export const PlayEditorContext = createContext<PlayEditorContextValue>(null);

export const usePlayEditor = (): PlayEditorContextValue => {
    return useContext(PlayEditorContext);
};

interface Props {
    children: ReactNode;
    dimensions?: Dimensions;
    fieldUrl: string;
    playData: any;
    debugOptions?: any;
    debugMarkers?: any[];
    currentFrameIdx: number;
    selectFrame?: (idx: number) => void;
    readOnly?: boolean;
}

export const PlayEditorProvider: FC<Props> = ({
    children,
    playData,
    debugOptions,
    debugMarkers,
    currentFrameIdx,
    selectFrame,
    dimensions: propDimensions,
    fieldUrl,
    readOnly,
}) => {
    const { PlayConfig, ToolboxConfig, Plugins } = useSite();
    // The strange use of a ref here is to make sure that play is always representative of the current playData
    // Instead of using useState and having a single render with an old Play object and new playData
    const playRef = useRef<Play>();
    playRef.current = useMemo(() => {
        return playData?.fieldType ? initializePlay(PlayConfig, playData) : null;
    }, [playData?.id, playData?.fieldSetup, playData?.fieldType, playData?.fieldPieces]);

    const play = useMemo(() => {
        if (playData?.fieldType && playRef.current) {
            // If something in the play has changed, copy and update the existing play object to reuse unchanged frames
            const playCopy = playRef.current.copy();
            playCopy.updatePlayData(playData);

            // Update the current play without causing a re-render
            playRef.current = playCopy;
            return playCopy;
        }

        return null;
    }, [playData, playRef.current]);

    const fieldRef = useRef();
    const editorRef = useRef();

    const [disableAnimation, setDisableAnimation] = useState<boolean>(false);

    const integerFrameIdx = Math.floor(currentFrameIdx);
    const partialFramePercentage = currentFrameIdx - integerFrameIdx;
    const baseFrame = playRef.current?.frameSnapshots[integerFrameIdx];
    const [currentFrame, setCurrentFrame] = useState<FrameSnapshot>(
        partialFramePercentage > 0 ? baseFrame?.generateNextFrame(null, partialFramePercentage) : baseFrame
    );

    const [dimensions, setDimensions] = useState<Dimensions>(propDimensions);

    // Allow setting the dimensions of the field from the parent component
    useEffect(() => {
        if (propDimensions) {
            setDimensions(propDimensions);
        }
    }, [propDimensions]);

    const [showHelpMenu, setShowHelpMenu] = useState(false);
    const toggleHelpMenu = () => {
        setShowHelpMenu(!showHelpMenu);
    };

    const renderFieldElement = (el: ReactNode) => createPortal(el, fieldRef.current);

    const value: PlayEditorContextValue = {
        PlayConfig,
        Plugins,
        ToolboxConfig,
        fieldUrl,
        readOnly,

        playData,
        play,
        fieldSetup: playData?.fieldSetup && !readOnly,
        debugOptions,
        debugMarkers,

        currentFrame,
        currentFrameIdx,
        setCurrentFrame,
        selectFrame,

        toggleHelpMenu,
        showHelpMenu,

        dimensions,
        setDimensions,

        disableAnimation,
        setDisableAnimation,

        fieldRef,
        editorRef,
        renderFieldElement,
    };

    return <PlayEditorContext.Provider value={value}>{children}</PlayEditorContext.Provider>;
};
