import { HudlLogo } from '@labradorsports/assets';
import { getVimeoVideoID, getYouTubeVideoID, isHudlUrl } from '@labradorsports/utils';

import { DataTags } from '../../shared/constants.js';
import { getAssetUrl } from '../../shared/utils.js';
import { createApiSlice } from '../api.js';
import { CustomQueryDefinition } from '../types.js';

export const mainApi = createApiSlice({
    getVideoDetails: {
        type: 'query',
        extraOptions: {
            retryable: true,
            suppressLoading: true,
        },
        isValid: ({ videoLink }) => Boolean(videoLink),
        queryFn: async ({ videoLink }, { extra }) => {
            const { cff } = extra;
            const hudlUrl = isHudlUrl(videoLink);
            const youtubeUrl = Boolean(getYouTubeVideoID(videoLink));
            const vimeoUrl = Boolean(getVimeoVideoID(videoLink));

            if (videoLink && hudlUrl) {
                return {
                    data: {
                        thumbnailUrl: getAssetUrl(HudlLogo),
                        title: 'Hudl Video',
                        description: '',
                    },
                };
            }

            if (youtubeUrl || vimeoUrl) {
                const videoDetails = await cff.fetch('main/getVideoDetails', { videoLink }, null, true);

                return {
                    data: videoDetails,
                };
            }

            return {
                data: null,
            };
        },
        providesTags: (result: any[], error, { videoLink }) => [{ type: DataTags.VIDEO_DETAILS, id: videoLink }],
    } as CustomQueryDefinition<{ videoLink: string }, any>,

    getVersion: {
        type: 'query',
        extraOptions: {
            suppressLoading: true,
        },
        query: () => ({
            path: 'main/version',
        }),
        transformResponse: ({ version, deployed }) => {
            return [version, new Date(deployed)];
        },
    } as CustomQueryDefinition<void, any>,

    getGlobalSearch: {
        type: 'query',
        extraOptions: {
            suppressLoading: true,
            unloggableArg: ['query'],
        },
        isValid: ({ query, playbookId }) => Boolean(query && playbookId),
        query: ({ query, playbookId, teamIds }) => ({
            path: 'search/global',
            query: {
                query,
                playbookId,
                teamIds: teamIds.join(','),
            },
        }),
        onQueryEnded: ({ data }, { extra }) => {
            const { logger } = extra;
            const { posts, events, roster, plays } = data;

            logger.log('getGlobalSearch complete', {
                posts: posts.length,
                events: events.length,
                roster: roster.length,
                plays: plays.length,
            });
        },
    } as CustomQueryDefinition<
        { query: string; playbookId: string; teamIds: string[] },
        { posts: []; events: []; roster: []; plays: [] }
    >,
});

export const { useGetVideoDetailsQuery, useGetVersionQuery, useGetGlobalSearchQuery } = mainApi;
