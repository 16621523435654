import { ToolTypes } from '@labradorsports/constants';
import { PlayEditorErrors, createException } from '@labradorsports/utils';

import {
    getLinePlayerPosition,
    calculatePartialMovement,
    findClosestPiece,
    defaultMove,
    getFrameDuration,
    sortByDistance,
    distance,
    moveTowardsPointPercentage,
    getMoveLength,
} from '../utils/index.js';

import FieldPiece from './field-piece.js';
import Field from './field.js';
import Move from './move.js';

// Coordinates should always be in units of the field (not pixels)
// a FieldViewport will be used to translate those coordinates into screen space
export default class FrameSnapshot {
    static getPlayer(players: FieldPiece[], id: number): FieldPiece {
        return players.find((plr) => plr.id === id);
    }

    config: PlayConfigSpec;

    balls: FieldPiece[];

    players: FieldPiece[];

    goalies: FieldPiece[];

    coaches: FieldPiece[];

    moves: Move[];

    assignments: Move[];

    options: Move[];

    fieldComments: Move[];

    slides: any[];

    theme: any[];

    sport: string;

    passes: Move[];

    shots: Move[];

    field: Field;

    comment: string;

    playType: string;

    pieces: FieldPiece[];

    idx: number;

    duration?: number;

    longestMoveLength?: number;

    constructor(
        config: PlayConfigSpec,
        field: Field,
        sport: string,
        playType: string,
        idx: number,
        players?: FieldPiece[],
        balls?: any[],
        frame?: any
    ) {
        this.config = config;
        this.field = field;
        this.sport = sport;
        this.playType = playType;
        this.idx = idx;

        this.moves = [];

        if (players) {
            this.setPlayers(players);
        }

        if (balls) {
            this.balls = balls.map((ball) => {
                const carrier = this.getPlayer(ball.carrier);

                return new FieldPiece({
                    ...ball,
                    type: ToolTypes.BALL,
                    origin: {
                        ...carrier.origin,
                    },
                });
            });
        }

        this.pieces = field.pieces?.slice();

        if (frame) {
            this.updateFrameData(frame);
        }
    }

    copy(): FrameSnapshot {
        const newSnapshot = new FrameSnapshot(this.config, this.field, this.sport, this.playType, this.idx);

        Object.assign(newSnapshot, this);

        // Re-create players and balls so they can be modified
        newSnapshot.setPlayers(this.players);
        newSnapshot.balls = this.balls.map((ball) => new FieldPiece(ball.toObj()));

        newSnapshot.pieces = this.field.pieces?.slice();
        newSnapshot.fieldComments = this.fieldComments?.map((comment) => new Move(comment.toObj()));

        return newSnapshot;
    }

    updateFrameData(frame: any): void {
        this.comment = frame.comment;
        this.moves = frame.moves?.map((mv: any) => this.processMove(mv));
        this.passes = frame.passes?.map((pass: any) => this.generatePassMove(pass));
        this.shots = frame.shots?.map((shot: any) => this.generateShotMove(shot));

        if (this.config.DynamicAnimation) {
            const allMoves = (this.moves ?? []).concat(this.passes ?? []).concat(this.shots ?? []);
            const moveLengths = allMoves.map(getMoveLength);
            const sortedLengths = moveLengths.slice().sort((a, b) => b - a);
            this.longestMoveLength = sortedLengths[0];

            allMoves.forEach((move, idx) => {
                move.lengthRatio = moveLengths[idx] / this.longestMoveLength;
            });
        }

        this.duration = getFrameDuration(this.sport, this);

        if (this.shots) {
            if (this.config.FeatureFlags.EnableGoalie) {
                const goalIds = Array.from(
                    new Set(this.shots.filter((shot) => typeof shot.target === 'number').map((shot) => shot.target))
                );

                goalIds.forEach((goalId) => {
                    const goal = this.field.getFieldPiece(goalId);

                    if (goal?.props?.goalieVisible) {
                        this.moves.push(this.processMove(this.generateGoalieMove(goalId)));
                    }
                });
            }
        }

        this.players.forEach((plr) => {
            this.setFrameRole(plr.id);
        });

        this.slides = frame.slides;
        if (frame.slides) {
            // Frame roles are stored in frame.slides
            frame.slides.forEach((slide: any) => {
                this.setFrameRole(slide.target, slide.role);
            });
        }

        this.assignments = frame.assignments?.map((asn: any) => this.generateAssignmentMove(asn, false));
        this.options = frame.options
            ?.map((opt: any) => this.generateOptionMove(opt))
            // Filter out null results
            .filter(Boolean);

        this.fieldComments = frame.fieldComments?.map((comment: any) => this.generateCommentMove(comment));

        this.players.forEach((plr) => {
            this.updatePlayer(plr.id, { rotation: null });
        });

        frame.playerRotations?.forEach((rotation: any) => {
            this.updatePlayer(rotation.target, { rotation: rotation.rotation });
        });

        this.theme = frame.theme;

        this.validate();
    }

    setPlayers(players: FieldPiece[]): void {
        // We need to copy the players so they can be modified in place
        this.players = players.map((plr) => {
            return new FieldPiece(plr instanceof FieldPiece ? plr.toObj() : plr);
        });

        this.goalies = this.players.filter(
            (plr: any) =>
                typeof plr.props?.pieceId === 'number' &&
                this.field.getFieldPiece(plr.props.pieceId).type === ToolTypes.GOAL
        );

        this.coaches = this.players.filter((plr: any) => plr.type === ToolTypes.COACH);
    }

    getPlayers(types: string[], exclude: any[] = []): FieldPiece[] {
        return this.players.filter((plr) => types.includes(plr.type) && !exclude.includes(plr));
    }

    getPlayer(id: number): FieldPiece {
        return FrameSnapshot.getPlayer(this.players, id);
    }

    executeMove(targetId: number, move: Move, percentage = 1): void {
        const target = this.getPlayer(targetId);

        Object.assign(target.origin, calculatePartialMovement(move, percentage, move.lengthRatio));

        const balls = this.getBallsByCarrier(targetId);
        balls.forEach((ball) => {
            if (!this.passes?.some((pass) => pass.props.ball === ball.id)) {
                // eslint-disable-next-line no-param-reassign
                ball.origin = calculatePartialMovement(move, percentage, move.lengthRatio);
            }
        });
    }

    executeShot(move: Move, percentage = 1): void {
        const goalie = this.goalies.find((plr: any) => plr.id === move.target);

        if (goalie) {
            const goal = this.field.getFieldPiece(goalie.props?.pieceId);

            // Goalie move has to happen first so pass calculates the right position for the ball
            this.executeMove(goalie.id, new Move({ origin: goalie.origin, anchors: [goal.origin] }), percentage);

            this.executePass(
                new Move({
                    type: ToolTypes.SHOOT,
                    target: goalie.id,
                    ...move.props,
                }),
                percentage
            );
        }
    }

    executePass(move: Move, percentage = 1): void {
        const target = this.getPlayer(move.target);

        // If target doesn't exist, we shouldn't execute the pass
        if (target) {
            const ball = this.getBall(move.props.ball);
            ball.props.carrier = move.target;

            const targetMove = this.getMove(target.id);
            const destination = targetMove ? targetMove.anchors[targetMove.anchors.length - 1] : target.origin;

            ball.origin = moveTowardsPointPercentage(ball.origin, destination, percentage);
        }
    }

    getBall(id: number): FieldPiece {
        return this.balls.find((ball) => ball.id === id);
    }

    getBallsByCarrier(id: number): FieldPiece[] {
        return this.balls.filter((ball) => ball.props.carrier === id);
    }

    hasBall(): FieldPiece[] {
        return this.balls.map((ball) => this.getPlayer(ball.props.carrier));
    }

    getCarrier(ballId: number): FieldPiece {
        const ball = this.getBall(ballId);
        if (ball) {
            return this.getPlayer(ball.props.carrier);
        }

        return null;
    }

    possession(ballId?: number): string {
        const { PlayTypes } = this.config;

        if (this.playType === PlayTypes.DRILL) {
            if (typeof ballId === 'number') {
                const carrier = this.getCarrier(ballId);
                return carrier.type;
            }

            // For a drill, there is no "global" possession
            return null;
        }

        const hasBall = this.hasBall();

        // Blue starts with the ball by default
        if (hasBall.length === 0) return ToolTypes.BLUEPLAYER;

        return hasBall[0].type;
    }

    getMove(targetId: number): Move {
        const targetMove = this.moves.find((mv) => mv.target === targetId);

        if (targetMove) return targetMove;

        return null;
    }

    setRole(playerId: number, role: string): void {
        const target = this.getPlayer(playerId);
        target.props.role = role;
    }

    setFrameRole(playerId: number, role?: string): void {
        const target = this.getPlayer(playerId);
        target.props.frameRole = role;
    }

    generatePassMove(pass: any): Move {
        const hasBall = this.getPlayer(this.getBall(pass.ball).props.carrier);
        let origin = { ...hasBall.origin };
        let target = {
            x: hasBall.origin.x + 5,
            y: hasBall.origin.y - 5,
        };

        if (typeof pass.target === 'number') {
            target = this.getPlayer(pass.target).origin;
            const targetMove = this.getMove(pass.target);
            if (targetMove) {
                target = calculatePartialMovement(targetMove, pass.end ?? 1);
            }

            const carrierMove = this.getMove(hasBall.id);
            if (carrierMove) {
                origin = calculatePartialMovement(carrierMove, pass.start ?? 0);
            }
        }

        return new Move({
            ...pass,
            origin,
            anchors: [
                {
                    x: target.x,
                    y: target.y,
                },
            ],
        });
    }

    generateShotMove(shot: any): Move {
        const hasBall = this.getPlayer(this.getBall(shot.ball).props.carrier);

        // When moving the ball between players with a shot placed, there will temporarily be a shot with no ball-carrier
        if (!hasBall) return null;

        let target;
        let anchor = {
            x: hasBall.origin.x + 5,
            y: hasBall.origin.y - 5,
        };

        if (typeof shot.target === 'number') {
            const goalie = this.goalies.find((plr) => plr.props.pieceId === shot.target);
            const goal = this.field.getFieldPiece(shot.target);

            if (goalie) {
                target = goalie.id;
            }

            anchor = { ...goal.origin };
        }

        return new Move({
            ...shot,
            type: ToolTypes.SHOOT,
            target,
            origin: { ...hasBall.origin },
            anchors: [anchor],
        });
    }

    generateAssignmentMove(assignment: any, useDestination: boolean): Move {
        const { id, defId, atkId } = assignment;
        const def = this.getPlayer(defId);
        const assign = defaultMove(this.field, def.origin, ToolTypes.ASSIGN, def.id);

        assign.id = id;

        const defMv = this.getMove(defId);
        if (useDestination && defMv) {
            const origin = defMv.anchors[defMv.anchors.length - 1];
            assign.origin = {
                ...origin,
            };
        }

        if (typeof atkId === 'number') {
            const atk = this.getPlayer(atkId);
            const atkMv = this.getMove(atkId);
            const pos = useDestination && atkMv ? atkMv.anchors[atkMv.anchors.length - 1] : atk.origin;
            assign.source = atkId;

            assign.anchors[0] = {
                x: pos.x,
                y: pos.y,
            };
        }

        return assign;
    }

    generateOptionMove(option: any): Move {
        const source = this.getPlayer(option.source);
        const targetPlayer = this.getPlayer(option.target);

        if (!source) return null;

        let target = { x: source.origin.x + 5, y: source.origin.y - 5 };

        if (targetPlayer) {
            target = {
                ...targetPlayer.origin,
            };

            const targetMove = this.getMove(option.target);
            if (targetMove) {
                target = { ...targetMove.anchors[targetMove.anchors.length - 1] };
            }
        }

        const optionMove = new Move({
            ...option,
            origin: { ...source.origin },
            anchors: [target],
        });

        return optionMove;
    }

    generateCommentMove({ x, y, ...comment }: any): Move {
        return new Move({
            ...comment,
            origin: { x, y },
            type: ToolTypes.COMMENT,
            anchors: [],
        });
    }

    generateNextFrame(nextFrame: any, percentage = 1): FrameSnapshot {
        const newFrame = this.copy();

        newFrame.idx += 1;
        newFrame.balls = this.balls.map((ball) => new FieldPiece(ball.toObj()));

        if (newFrame.moves.length > 0) {
            newFrame.moves.forEach((mv: any) => {
                newFrame.executeMove(mv.target, mv, percentage);
                const target = newFrame.players.find((plr) => plr.id === mv.target);
                if (typeof mv.props.targetLine === 'number') {
                    target.props.pieceId = mv.props.targetLine;
                    // Move this player to the end of the line (correct position will be determined when the frame is processed)
                    target.props.linePosition = 99;
                } else if (typeof target.props.pieceId === 'number') {
                    const piece = this.field.getFieldPiece(target.props.pieceId);
                    // Only remove the pieceId if the player came from a line
                    if (piece.type === ToolTypes.OLINE || piece.type === ToolTypes.DLINE) {
                        target.props.pieceId = null;
                        target.props.linePosition = null;
                    }
                }
            });
        }

        if (this.field.pieces) {
            this.field.pieces
                .filter((pc) => pc.type === ToolTypes.OLINE || pc.type === ToolTypes.DLINE)
                .forEach((line) => {
                    const linePlayers = newFrame.players.filter((plr) => plr.props.pieceId === line.id);
                    // Set the front of the line based on how many players are currently in the line
                    // This prevents the players from moving up as soon as the first player leaves
                    const maxPos = Math.min(line.props.playerCount, linePlayers.length);

                    linePlayers
                        .sort((a, b) => a.props.linePosition - b.props.linePosition)
                        .forEach((plr, i) => {
                            const newPos = getLinePlayerPosition(
                                line.origin,
                                maxPos,
                                // Extra players in the line should stack in the back
                                Math.min(maxPos - 1, i),
                                this.field,
                                line.rotation
                            );

                            const balls = newFrame.getBallsByCarrier(plr.id);
                            balls.forEach((ball) => {
                                // eslint-disable-next-line no-param-reassign
                                ball.origin = newPos;
                            });

                            Object.assign(plr, {
                                origin: newPos,
                                linePosition: i,
                            });
                        });
                });
        }

        if (newFrame.passes) {
            newFrame.passes.forEach((pass) => {
                newFrame.executePass(pass, percentage);
            });
        }

        if (newFrame.shots) {
            newFrame.shots.forEach((shot) => {
                newFrame.executeShot(shot, percentage);
            });
        }

        if (nextFrame) {
            newFrame.updateFrameData(nextFrame);
        }

        return newFrame;
    }

    generateGoalieMove(goalId: number): Move {
        const goalie = this.players.find((plr) => plr.props.pieceId === goalId);
        const goal = this.field.getFieldPiece(goalId);

        const newMv = new Move({
            type: ToolTypes.MOVE,
            target: goalie.id,
            anchors: [{ ...goal.origin }],
        });

        return newMv;
    }

    processMove(mv: any): Move {
        const newMv = new Move(mv);

        const atk = this.getPlayer(mv.target);
        newMv.origin = {
            ...atk.origin,
        };

        if (typeof mv.targetLine === 'number') {
            const targetLine = this.field.getFieldPiece(mv.targetLine);

            if (targetLine) {
                newMv.anchors = [
                    ...newMv.anchors.slice(0, newMv.anchors.length - 1),
                    {
                        ...targetLine.origin,
                    },
                ];
            }
        }

        return newMv;
    }

    findClosestDefender(coords: FieldPoint, exclude?: any[]): any {
        return this.findClosestPlayer(coords, exclude, [
            this.possession() === ToolTypes.BLUEPLAYER ? ToolTypes.ORANGEPLAYER : ToolTypes.BLUEPLAYER,
            ToolTypes.COACH,
        ]);
    }

    findClosestAttacker(coords: FieldPoint, exclude?: any[]): any {
        return this.findClosestPlayer(coords, exclude, [this.possession()]);
    }

    findClosestPassable(coords: FieldPoint, type: string, exclude: any[] = [], ballId?: number): any {
        const possession = this.possession(ballId);
        // Coach can pass to anyone, so don't restrict if coach has the ball
        const types =
            possession === ToolTypes.COACH || possession === null
                ? null
                : [...this.getTargetForToolType(type, possession), ToolTypes.COACH];

        return this.findClosestPlayer(
            coords,
            this.hiddenGoalies()
                .concat([this.getCarrier(ballId)])
                .concat(exclude),
            types
        );
    }

    findClosestOption(coords: FieldPoint, carrierId: number): any {
        const hasBall = this.hasBall();
        const carrier = this.getPlayer(carrierId);
        const otherTeamGoalies = this.goalies.filter((plr) => plr.type !== carrier.type);

        // Exclude opponents, except for the goalie, representing a shot on goal
        const excluded = this.getPlayers(
            [carrier.type === ToolTypes.ORANGEPLAYER ? ToolTypes.BLUEPLAYER : ToolTypes.ORANGEPLAYER],
            otherTeamGoalies
        );

        return this.findClosestPlayer(coords, [...hasBall, ...excluded]);
    }

    // Find the closest player to a point, optionally filtered to a certain set of types
    // Prioritizes players of equal distance by the order of the types provided
    findClosestPlayer(coords: FieldPoint, exclude?: any[], types?: string[]): any {
        if (!types) {
            return findClosestPiece(coords, this.players, exclude);
        }

        // Get the closest for each type
        const closestByType = sortByDistance(
            coords,
            types.map((type) => findClosestPiece(coords, this.getPlayers([type]), exclude)).filter(Boolean)
        );

        // Find the absolute closest
        const closest = findClosestPiece(coords, closestByType);

        if (closest) {
            // Filter down to results of equal distance to the absolute closest, and return the first
            // These are in order of the {types} list, so the first type will be prioritized
            return closestByType.filter(
                (node) => distance(coords, node.origin) === distance(coords, closest.origin)
            )[0];
        }

        return null;
    }

    findClosestGoal(coords: FieldPoint, type: string): FieldPiece {
        const { PlayTypes } = this.config;

        const otherTeamGoalIds = this.goalies
            .filter((plr: any) => plr.type !== type)
            .map((plr: any) => plr.props.pieceId);

        // TODO: is filtering this ahead of time (blueGoals/orangeGoals) a good idea?
        return findClosestPiece(
            coords,
            this.field.pieces.filter(
                (pc) =>
                    pc.type === ToolTypes.GOAL &&
                    (this.playType === PlayTypes.DRILL || otherTeamGoalIds.includes(pc.id))
            )
        );
    }

    insertAnchor(mvTarget: number, idx: number, coords: FieldPoint): Move {
        const newMoves = this.moves.slice();
        const targetMove = newMoves.find((mv: any) => mv.target === mvTarget);
        const trg = targetMove.copy();

        trg.insertAnchor(idx, coords, this.field);

        newMoves.splice(newMoves.indexOf(targetMove), 1, trg);

        this.moves = newMoves;

        return trg;
    }

    updateAnchor(mvTarget: number, anchorId: number, coords: FieldPoint): Move {
        const newMoves = this.moves.slice();
        const targetMove = newMoves.find((mv: any) => mv.target === mvTarget);
        const trg = targetMove.copy();

        trg.updateAnchor(anchorId, coords, this.field);

        newMoves.splice(newMoves.indexOf(targetMove), 1, trg);

        this.moves = newMoves;

        return trg;
    }

    updatePass(
        id: number,
        updates: { type?: string; targetId?: number; origin?: FieldPoint; endpoint?: FieldPoint }
    ): void {
        const { targetId, endpoint, origin, type } = updates;
        const targetPass = this.passes.find((pass) => pass.id === id);

        if (type) {
            targetPass.type = type;
        }

        if (origin) {
            targetPass.origin = origin;
        }

        if (endpoint) {
            targetPass.anchors = [
                {
                    ...endpoint,
                },
            ];
        } else if (targetId) {
            let target = this.getPlayer(targetId).origin;
            const targetMove = this.getMove(targetId);

            if (targetMove) {
                target = targetMove.anchors[targetMove.anchors.length - 1];
            }

            targetPass.anchors = [
                {
                    ...target,
                },
            ];
        }
    }

    updateShot(id: number, goalId: number): void {
        const targetShot = this.shots.find((shot) => shot.id === id);
        const goal = this.field.getFieldPiece(goalId);
        targetShot.target = goalId;
        targetShot.anchors = [{ ...goal.origin }];
    }

    updatePlayer(id: number, updates: any): void {
        const target = this.getPlayer(id);

        const { x, y, ...rest } = updates;
        Object.assign(target, rest);

        if (typeof updates.x === 'number' || typeof updates.y === 'number') {
            Object.assign(target.origin, { x, y });
            const balls = this.getBallsByCarrier(id);

            balls.forEach((ball) => {
                // eslint-disable-next-line no-param-reassign
                ball.origin = {
                    x: updates.x,
                    y: updates.y,
                };
            });
        }
    }

    updateAssignment(id: number, atkId: number): void {
        const existing = this.assignments.find((asn: any) => asn.id === id);
        const target = this.getPlayer(atkId);
        existing.source = atkId;
        existing.anchors = [
            {
                ...target.origin,
            },
        ];
    }

    updateOption(id: number, target: number): void {
        const existing = this.options.find((opt: any) => opt.id === id);
        const targetPlayer = this.getPlayer(target);
        existing.anchors = [
            {
                ...targetPlayer.origin,
            },
        ];
    }

    updateComment(id: number, coords: FieldPoint, props: any = {}): void {
        const target = this.fieldComments.find((comment: any) => comment.id === id);
        Object.assign(target.origin, coords);
        Object.assign(target.props, props);
    }

    getFieldPiece(id: number): FieldPiece {
        return this.pieces?.find((pc) => pc.id === id);
    }

    // Doesn't modify the object in place because we do not want to re-create the FieldPiece objects for every frame in the play
    updateFieldPiece(id: number, coords?: FieldPoint, props: any = {}): void {
        const targetIdx = this.pieces.findIndex((piece) => piece.id === id);
        const target = this.pieces[targetIdx];
        const newPiece = new FieldPiece(target.toObj());
        newPiece.origin = coords ?? target.origin;
        this.pieces.splice(targetIdx, 1, newPiece);
        Object.assign(newPiece.props, props);
    }

    updateLinePlayers(id: number, coords: FieldPoint): void {
        const target = this.pieces.find((piece) => piece.id === id);
        this.players = this.players.map((plr) => {
            if (plr.props.pieceId !== id) return plr;

            const newPos = getLinePlayerPosition(
                coords,
                target.props.playerCount,
                plr.props.linePosition,
                this.field,
                target.rotation
            );

            return new FieldPiece({
                ...plr,
                origin: newPos,
            });
        });

        const affectedPlayers = this.players.filter((plr) => plr.props.pieceId === id);

        this.balls = this.balls.map((ball) => {
            const carrier = affectedPlayers.find((plr) => plr.id === ball.props.carrier);
            if (!carrier) return ball;

            return new FieldPiece({
                ...ball.toObj(),
                origin: {
                    ...carrier.origin,
                },
            });
        });
    }

    updatePlayerRotation(id: number, rotation: number): void {
        const target = this.getPlayer(id);
        target.rotation = rotation;
    }

    rotateFieldPiece(id: number, rotation: number): void {
        const targetIdx = this.pieces.findIndex((piece) => piece.id === id);
        const target = this.pieces[targetIdx];
        const newPiece = new FieldPiece(target.toObj());
        newPiece.rotation = rotation;
        this.pieces.splice(targetIdx, 1, newPiece);
    }

    visibleGoalies(): any[] {
        return this.goalies.filter((goalie) => this.field.getFieldPiece(goalie.props.pieceId).props.goalieVisible);
    }

    hiddenGoalies(): any[] {
        return this.goalies.filter((goalie) => !this.field.getFieldPiece(goalie.props.pieceId).props.goalieVisible);
    }

    visibleMoves(viewOptions: any): Move[] {
        const goalie = this.goalies[0];
        return this.moves
            .filter((mv) => !this.shots || !goalie || mv.target !== goalie.id) // Filter out the goalie move on frames with a shot
            .filter((mv) => {
                const target = this.getPlayer(mv.target);
                if (target.type === ToolTypes.BLUEPLAYER) {
                    return viewOptions.ATTACK.moves;
                }

                return viewOptions.DEFENSE.moves;
            });
    }

    getTargetForToolType(toolType: string, possessionParam?: string): string[] {
        const possession = possessionParam ?? this.possession();

        // All moves can go to any player in a drill
        if (possession === null) return [ToolTypes.BLUEPLAYER, ToolTypes.ORANGEPLAYER, ToolTypes.COACH];

        // Map target types based on possession
        return this.config.MoveConfig[toolType].targets.map((tp) => {
            if (possession === ToolTypes.ORANGEPLAYER) {
                if (tp === ToolTypes.BLUEPLAYER) return ToolTypes.ORANGEPLAYER;
                if (tp === ToolTypes.ORANGEPLAYER) return ToolTypes.BLUEPLAYER;
            }

            return tp;
        });
    }

    validate() {
        const throwIfInvalid = (piece: FieldPiece) => {
            if (!piece.validate()) {
                const details = `Frame: ${this.idx}, ${JSON.stringify(piece.toObj())}`;
                if (!PROD) {
                    console.log(details);
                }

                throw createException(PlayEditorErrors.NAN_VALUE, {
                    details,
                });
            }
        };

        this.moves?.map(throwIfInvalid);
        this.passes?.map(throwIfInvalid);
        this.shots?.map(throwIfInvalid);
        this.assignments?.map(throwIfInvalid);
        this.options?.map(throwIfInvalid);
        this.pieces?.map(throwIfInvalid);
        this.fieldComments?.map(throwIfInvalid);
        this.balls?.map(throwIfInvalid);
    }
}
