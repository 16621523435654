import { Quill } from 'react-quill';
import Delta from 'quill-delta';

const Tooltip = Quill.import('ui/tooltip');

interface RosterPlayerData {
    number: string;
    className?: string;
    added?: boolean;
}

const InlineBlot = Quill.import('blots/inline');
export class RosterPlayerBlot extends InlineBlot {
    static create(value: RosterPlayerData): HTMLElement {
        const node = super.create(value);

        node.setAttribute('class', value.className ?? 'roster-player');

        if (value.added) {
            node.setAttribute('data-added', 'true');
        }

        return node;
    }

    static value(node: HTMLElement): RosterPlayerData {
        if (['roster-player-pill', 'roster-player'].includes(node.className)) {
            return {
                number: node.textContent,
                className: node.className,
                added: node.getAttribute('data-added') === 'true',
            };
        }

        return null;
    }

    static formats(node: HTMLElement): { number: string } {
        if (node.textContent.trim() === '') return super.formats(node);

        return {
            number: node.textContent,
        };
    }
}

RosterPlayerBlot.blotName = 'roster-player';
RosterPlayerBlot.tagName = 'span';
RosterPlayerBlot.className = 'roster-player';

class RosterPlayerTooltip extends Tooltip {
    activePlayer: string;

    showForPill(pill: RosterPlayerBlot, index: number) {
        if (pill != null) {
            const value = RosterPlayerBlot.value(pill.domNode);

            if (!value.added) {
                this.activePlayer = value.number;
                this.show();
                this.position(this.quill.getBounds(index));
                return;
            }
        }

        this.hide();
    }

    listen() {
        this.root.querySelector('a.roster-player-ignore').addEventListener('click', (event: any) => {
            event.preventDefault();
            this.hide();
        });

        this.root.querySelector('a.roster-player-add').addEventListener('click', (event: any) => {
            this.quill.emitter.emit('roster-player-add-click', this.activePlayer.replace('#', ''));
            event.preventDefault();
            this.hide();
        });

        this.quill.on('selection-change', (range: any, oldRange: any, source: any) => {
            if (range == null) return;
            if (range.length === 0 && source === 'user') {
                const [pill] = this.quill.scroll.descendant(RosterPlayerBlot, range.index);
                this.showForPill(pill, range.index);
            }
        });

        this.quill.on('text-change', (delta: any, old: any, source: string) => {
            const range = this.quill.getSelection();
            if (range == null) return;
            if (range.length === 0 && source === 'user') {
                const [beforePill] = this.quill.scroll.descendant(RosterPlayerBlot, range.index - 1);
                this.showForPill(beforePill, range.index - 1);
            }
        });
    }
}

// prettier-ignore
RosterPlayerTooltip.TEMPLATE = [
    '<a class="roster-player-ignore">Ignore</a>',
    '<a class="roster-player-add">Add Personnel</a>',
].join('');

export default class RosterPlayer {
    quill: InstanceType<typeof Quill>;

    tooltip: RosterPlayerTooltip;

    options: any;

    constructor(quill: InstanceType<typeof Quill>, options: any) {
        this.quill = quill;
        this.options = options;
        this.registerTypeListener();
        // Typings for Quill do not include container prop
        // @ts-ignore
        this.tooltip = new RosterPlayerTooltip(quill, quill.container);
        this.tooltip.listen();
    }

    updateAddedPlayers(): void {
        const addedPlayers = this.options?.getAddedPlayers?.() ?? [];

        // @ts-ignore
        const blots = this.quill.scroll.descendants(RosterPlayerBlot);
        blots.forEach((blot: RosterPlayerBlot) => {
            if (addedPlayers.find((plr: any) => plr.jersey === blot.domNode.textContent.substring(1))) {
                blot.domNode.setAttribute('data-added', 'true');
            } else {
                blot.domNode.setAttribute('data-added', 'false');
            }
        });
    }

    registerTypeListener(): void {
        this.quill.on('text-change', (delta, old, source) => {
            const { ops } = delta;

            const addedPlayers = this.options?.getAddedPlayers?.() ?? [];

            if (!ops || ops.length < 1) {
                return;
            }

            const lastOp = ops[ops.length - 1];
            const secondLastOp = ops[ops.length - 2];

            if (source === 'user') {
                if (
                    lastOp.retain &&
                    !lastOp.attributes?.['roster-player'] &&
                    secondLastOp.insert &&
                    secondLastOp.attributes?.['roster-player'] &&
                    secondLastOp.attributes['roster-player'].number !== ''
                ) {
                    const number = secondLastOp.attributes['roster-player'].number.trim().replace('#', '');

                    if (addedPlayers.some((plr: any) => plr.jersey === number)) {
                        // Quill typings don't include emitter
                        // @ts-ignore
                        this.quill.emitter.emit('roster-player-add-existing', number);
                    }
                }

                this.checkForTag();
            }

            setTimeout(() => {
                this.updateAddedPlayers();
            }, 0);
        });
    }

    checkForTag(): void {
        const sel = this.quill.getSelection();
        if (!sel) {
            return;
        }

        const [leaf] = this.quill.getLeaf(sel.index);
        const leafIndex = this.quill.getIndex(leaf);

        if (!leaf.text) {
            return;
        }

        const matches = leaf.text.match(/#[0-9]*/);

        if (!matches) {
            return;
        }

        const [match] = matches;
        const retain = match.length;
        const retainAfter = leaf.text.length - matches.index + retain;

        const ops = new Delta()
            .retain(leafIndex + matches.index)
            .retain(retain, { 'roster-player': { number: '' } })
            .retain(1, { 'roster-player': false })
            .retain(retainAfter + 1);

        this.quill.updateContents(ops);
    }
}
