 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authActions } from '../auth/index.js';

export interface ScoutingReportState {
    editScoutingReportId: string;
    showPublishedNotification: boolean;
    ncaaYear?: string;
}

const scoutingReportDefaultState: ScoutingReportState = {
    editScoutingReportId: undefined,
    showPublishedNotification: false,
};

const { reducer: scoutingReportReducer, actions: scoutingReportActions } = createSlice({
    name: 'scoutingReport',
    initialState: scoutingReportDefaultState,
    reducers: {
        SetEditScoutingReport: (state, action: PayloadAction<string | null>) => {
            state.editScoutingReportId = action.payload;
        },

        ShowPublishedNotification: (state, action) => {
            state.showPublishedNotification = action.payload;
        },

        SetNCAAYear: (state, action) => {
            state.ncaaYear = action.payload;
        },
    },
    extraReducers: {
        [authActions.PurgeProfile.type]: () => scoutingReportDefaultState,
    },
});

export { scoutingReportReducer, scoutingReportActions, scoutingReportDefaultState };
