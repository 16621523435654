import { ProfileSetupStepType } from '@labradorsports/components';
import { TeamFlags, JoinTypes, Plans, ProfileFlags, PlaybookTabs } from '@labradorsports/constants';
import { getFolderParent, getTeamUserRole, isUserAdmin, maskObjectPaths, teamHasFlag } from '@labradorsports/utils';

import { authApi } from './auth/api.js';
import { AuthState, authDefaultState } from './auth/index.js';
import { BillingState, billingDefaultState } from './billing/index.js';
import { CommunityState, communityDefaultState } from './community/index.js';
import { MainState, mainDefaultState } from './main/index.js';
import { MessagingState, messagingDefaultState } from './messaging/index.js';
import { PlayEditorState, playEditorDefaultState } from './play-editor/index.js';
import { playbookApi } from './playbook/api.js';
import { PlaybookState, playbookDefaultState } from './playbook/index.js';
import { scheduleApi } from './schedule/api.js';
import { ScheduleState, scheduleDefaultState } from './schedule/index.js';
import { ScoutingReportState, scoutingReportDefaultState } from './scouting-report/index.js';
import { teamsApi } from './teams/api.js';
import { TeamsState, teamsDefaultState } from './teams/index.js';

export interface RootState {
    api: any;
    site: SiteSpec;
    auth: AuthState;
    main: MainState;
    messaging: MessagingState;
    playbook: PlaybookState;
    teams: TeamsState;
    schedule: ScheduleState;
    playEditor: PlayEditorState;
    billing: BillingState;
    scoutingReport: ScoutingReportState;
    community: CommunityState;
}

export const defaultState = {
    auth: authDefaultState,
    main: mainDefaultState,
    messaging: messagingDefaultState,
    playbook: playbookDefaultState,
    teams: teamsDefaultState,
    schedule: scheduleDefaultState,
    playEditor: playEditorDefaultState,
    billing: billingDefaultState,
    scoutingReport: scoutingReportDefaultState,
    community: communityDefaultState,
};

export const Selectors = {
    profileLoaded(state: RootState) {
        return state.auth.profileLoaded;
    },

    accountFormError(state: RootState) {
        return state.auth.accountFormError;
    },

    user(state: RootState) {
        return state.auth.user;
    },

    uid(state: RootState) {
        return Selectors.user(state)?.uid;
    },

    shareConfig(state: RootState) {
        return state.playbook.shareConfig;
    },

    currentPlaybookId(state: RootState) {
        const { shownPlaybook } = state.playbook;
        const myPlaybook = Selectors.myPlaybook(state);
        const teamPlaybook = Selectors.teamPlaybook(state);
        const isMyPlaybook = shownPlaybook === PlaybookTabs.MY;

        return isMyPlaybook ? myPlaybook?.id : teamPlaybook?.id;
    },

    play(state: RootState) {
        return state.playEditor.play;
    },

    playbook(state: RootState) {
        return state.playbook;
    },

    playbookId(state: RootState) {
        const selector = playbookApi.endpoints.loadMyPlaybook.select();

        return selector(state).data?.id;
    },

    playId(state: RootState) {
        return state.playbook.playId;
    },

    viewEventId(state: RootState) {
        return state.schedule.viewEventId;
    },

    viewPostId(state: RootState) {
        return state.messaging.viewPostId;
    },

    activeTeam(state: RootState) {
        return state.teams.activeTeam;
    },

    teams(state: RootState) {
        const teamsSelector = teamsApi.endpoints.fetchTeams.select();

        return teamsSelector(state).data ?? {};
    },

    myPlaybook(state: RootState) {
        const selector = playbookApi.endpoints.loadMyPlaybook.select();

        return selector(state).data;
    },

    teamPlaybook(state: RootState) {
        const activeTeam = Selectors.activeTeam(state);
        const selector = playbookApi.endpoints.loadTeamPlaybook.select({
            activeTeam,
        });

        return selector(state).data;
    },

    currentFolders(state: RootState) {
        const { shownPlaybook } = state.playbook;
        const myPlaybook = Selectors.myPlaybook(state);
        const teamPlaybook = Selectors.teamPlaybook(state);
        const { folders: myFolders } = myPlaybook ?? {};
        const { folders: teamFolders } = teamPlaybook ?? {};

        return shownPlaybook === PlaybookTabs.MY ? myFolders : teamFolders;
    },

    parentFolderId(state: RootState) {
        const folders = Selectors.currentFolders(state);

        return getFolderParent(folders, state.playbook.folderId)?.id;
    },

    currentTeam(state: RootState): any {
        const { activeTeam } = state.teams;
        const teams = Selectors.allTeams(state);

        if (activeTeam) {
            return teams.find((tm) => tm.id === activeTeam);
        }

        return null;
    },

    currentProgram(state: RootState): any {
        const currentTeam = Selectors.currentTeam(state);

        if (currentTeam) {
            return Selectors.teams(state).programs?.find((prog) => prog.id === currentTeam.programId);
        }

        return null;
    },

    currentBranding(state: RootState): any {
        const currentTeam = Selectors.currentTeam(state);

        if (currentTeam) {
            const brandingEntity = currentTeam.programId
                ? Selectors.teams(state).programs.find((program) => program.id === currentTeam.programId)
                : currentTeam;

            if (brandingEntity) {
                const { colorPrimary, colorSecondary } = brandingEntity;

                return {
                    colorPrimary,
                    colorSecondary,
                };
            }
        }

        return null;
    },

    currentPlan(state: RootState): string {
        const currentTeam = Selectors.currentTeam(state);

        if (currentTeam) {
            return currentTeam.plan;
        }

        return null;
    },

    currentSport(state: RootState): string {
        const currentTeam = Selectors.currentTeam(state);
        return currentTeam?.sport;
    },

    teamPaid(state: RootState): boolean {
        const currentTeam = Selectors.currentTeam(state);

        if (currentTeam) return currentTeam.paid;

        return false;
    },

    teamViewable(state: RootState): boolean {
        const teamPaid = Selectors.teamPaid(state);
        const userRole = Selectors.currentUserTeamRole(state);

        return teamPaid || userRole === JoinTypes.ADMIN;
    },

    teamHasFlag(state: RootState, flag: string, teamOverride?: any): boolean {
        const team = teamOverride ?? Selectors.currentTeam(state);

        return teamHasFlag(team, flag);
    },

    currentUserTeamRole(state: RootState): string {
        const team = Selectors.currentTeam(state);

        if (state.auth.user && team) {
            const { uid } = state.auth.user;

            return getTeamUserRole(team, uid);
        }

        return null;
    },

    currentUserTeamRoleIsCoach(state: RootState): boolean {
        const teamRole = Selectors.currentUserTeamRole(state);

        return [JoinTypes.ADMIN, JoinTypes.COACH].includes(teamRole);
    },

    currentUserProgramRole(state: RootState): string {
        const program = Selectors.currentProgram(state);

        if (state.auth.user && program) {
            const isAdmin = isUserAdmin(program, state.auth.user.uid);

            return isAdmin ? JoinTypes.ADMIN : null;
        }

        return null;
    },

    currentUserIsProgramAdmin(state: RootState): boolean {
        const role = Selectors.currentUserProgramRole(state);

        return role === JoinTypes.ADMIN;
    },

    currentUserIsPlayer(state: RootState): boolean {
        if (state.auth.user) {
            const { uid } = state.auth.user;
            const allTeams = Selectors.allTeams(state);
            return allTeams.length > 0 && allTeams.every((team) => getTeamUserRole(team, uid));
        }

        return false;
    },

    someTeamsPaid(state: RootState): boolean {
        return state.auth.user && Selectors.allTeams(state).some((team) => team.paid);
    },

    allTeamsPaid(state: RootState): boolean {
        return state.auth.user && Selectors.allTeams(state).every((team) => team.paid);
    },

    profile(state: RootState): any {
        const selector = authApi.endpoints.loadProfile.select();

        const { data: profile } = selector(state);

        return profile;
    },

    profileFlags(state: RootState): any {
        return Selectors.profile(state)?.profileFlags ?? {};
    },

    userHasFlag(state: RootState, flag: string): boolean {
        const flags = Selectors.profileFlags(state);

        return Boolean(flags?.[flag]);
    },

    basicDetails(state: RootState): any {
        return Selectors.profile(state)?.basicDetails ?? {};
    },

    playReadOnly(state: RootState): boolean {
        const { play } = state.playEditor;

        if (play) {
            // Can edit your own plays
            if (
                Selectors.myPlaybook(state)?.plays.findIndex((pl: any) => pl.id === play.id) !== -1 ||
                play.id.includes('__')
            ) {
                return false;
            }

            // Can edit team plays if you are a coach and the team setting is enabled
            if (
                Selectors.teamPlaybook(state)?.shared.findIndex((pl: any) => pl.id === play.id) !== -1 &&
                Selectors.teamEditable(state) &&
                Selectors.teamHasFlag(state, TeamFlags.COACHES_CAN_EDIT_TEAM_PLAYS)
            ) {
                return false;
            }
        }

        // All other scenarios are read-only
        return true;
    },

    allTeams(state: RootState): any[] {
        const { ownedTeams, joinedTeams } = Selectors.teams(state);

        return (ownedTeams ?? []).concat(joinedTeams ?? []);
    },

    editableTeams(state: RootState): any[] {
        if (!state.auth.user?.uid) return [];

        const { ownedTeams, joinedTeams } = Selectors.teams(state);

        if (!joinedTeams) return ownedTeams;

        return joinedTeams.filter((tm: any) => tm.members[state.auth.user.uid] !== undefined).concat(ownedTeams);
    },

    teamEditable(state: RootState): boolean {
        const isCoach = Selectors.currentUserTeamRoleIsCoach(state);
        const isProgramAdmin = Selectors.currentUserIsProgramAdmin(state);

        return isCoach || isProgramAdmin;
    },

    currentRosterPlayer(state: RootState): any {
        const uid = Selectors.uid(state);
        const activeTeam = Selectors.activeTeam(state);
        const rosterSelector = teamsApi.endpoints.retrieveRoster.select({ teamId: activeTeam, uid });
        const { data: { roster } = {} } = rosterSelector(state);

        return roster?.find((pl) => pl.id === state.teams.rosterPlayerId);
    },

    canViewRoster(state: RootState): boolean {
        return (
            (Selectors.teamEditable(state) || Selectors.teamHasFlag(state, TeamFlags.PLAYERS_CAN_VIEW_TEAM_ROSTER)) &&
            (Selectors.teamPaid(state) || Selectors.currentUserTeamRole(state) === JoinTypes.ADMIN)
        );
    },

    playerRating(Config: ConfigSpec, position: string, ratings: any): number {
        const weights = Config.PlayerRatingWeights[position];
        if (!weights) return null;
        const weightKeys = Object.keys(weights);

        const totalWeight = weightKeys.reduce((accumulator, key) => weights[key] + accumulator, 0);
        const rating = weightKeys.reduce(
            (accumulator, key) => accumulator + (weights[key] / totalWeight) * ratings[key],
            0
        );
        return Math.round(rating * 100) / 100;
    },

    activeRating(state: RootState): any {
        const activeTeam = Selectors.activeTeam(state);
        const ratingsListSelector = teamsApi.endpoints.getRatings.select({
            activeTeam,
            playerId: state.teams.rosterPlayerId,
        });
        const { data: ratingsList } = ratingsListSelector(state);

        return ratingsList?.find((rating) => rating.id === state.teams.activeRatingId);
    },

    currentEvent(state: RootState): any {
        const activeTeam = Selectors.activeTeam(state);
        const scheduleSelector = scheduleApi.endpoints.loadSchedule.select({ activeTeam });
        const { data: events } = scheduleSelector(state);

        if (events) {
            return events.find((event) => event.id === state.schedule.viewEventId);
        }

        return null;
    },

    communityPlay(state: RootState): any {
        return state.community.play;
    },

    communityProfile(state: RootState): any {
        return state.community.profile;
    },

    gifDownload(state: RootState, playId: string, playbookId?: string): any {
        return state.playbook.gifDownloads.find(
            (gd) => gd.playId === playId && (!playbookId || gd.playbookId === playbookId)
        );
    },

    loggableState(state: RootState) {
        // TODO: find a way to include query results that are loggable?
        const redactedPaths = [
            'api.queries.*.data',
            'api.mutations',
            'api.provided',
            'api.subscriptions',

            'auth',

            'profile.settings.notificationDevices',

            'playEditor.play.viewers.*.firstName',
            'playEditor.play.viewers.*.lastName',
            'playEditor.editHistory',
            'playEditor.PlayConfig',

            // This will be logged separately
            'main.actionLog',
        ];

        return maskObjectPaths(state, redactedPaths);
    },

    onboardingSteps(state: RootState): ProfileSetupStepType[] {
        const {
            auth: { user },
        } = state;
        const profileLoaded = Selectors.profileLoaded(state);
        const { ownedTeams, joinedTeams, programs } = Selectors.teams(state);

        if (user && profileLoaded) {
            const commonSteps: ProfileSetupStepType[] = ['firstPlay', 'shareSocial', 'followSocial'];

            const extraSteps = ((): ProfileSetupStepType[] => {
                // Personal
                if (ownedTeams.length === 0 && joinedTeams.length === 0 && programs.length === 0) {
                    return [];
                }

                // Team Admin/Coach
                if (
                    ownedTeams.some((team) => team.plan !== Plans.BASIC) ||
                    joinedTeams.some((team) => team.plan !== Plans.BASIC && team.members[user.uid])
                ) {
                    return ['playbook', 'roster', 'schedule', 'downloadApp'];
                }

                // Basic Team Coach
                if (ownedTeams.length > 0 || joinedTeams.some((team) => team.members[user.uid])) {
                    return ['playbook', 'roster', 'downloadApp'];
                }

                // Player
                if (joinedTeams.some((team) => team.plan !== Plans.BASIC)) {
                    return ['viewPlaybook', 'viewSchedule', 'downloadApp'];
                }

                return ['viewPlaybook', 'downloadApp'];
            })();

            return [...commonSteps, ...extraSteps];
        }

        return [];
    },

    completedOnboardingSteps(state: RootState): any {
        const profileFlagMap: {
            [k in ProfileSetupStepType]: string;
        } = {
            firstPlay: ProfileFlags.CREATED_FIRST_PLAY,
            followSocial: ProfileFlags.FOLLOWED_SOCIAL,
            shareSocial: ProfileFlags.SHARED_SOCIAL,
            playbook: ProfileFlags.SHARED_PLAY_TEAM,
            roster: ProfileFlags.INVITED_TEAM,
            schedule: ProfileFlags.CREATED_FIRST_EVENT,
            viewPlaybook: ProfileFlags.VIEWED_PLAYBOOK,
            viewSchedule: ProfileFlags.VIEWED_SCHEDULE,
            downloadApp: ProfileFlags.DOWNLOADED_APP,
        };

        const profileFlags = Selectors.profileFlags(state);

        return Object.fromEntries(
            Selectors.onboardingSteps(state).map((step) => [step, !!profileFlags?.[profileFlagMap[step]]])
        );
    },

    findPlay(state: RootState, playId: string, prioritizeTeam = true): any {
        const myPlaybook = Selectors.myPlaybook(state);
        const teamPlaybook = Selectors.teamPlaybook(state);

        const fromTeam = (teamPlaybook?.shared ?? []).find((pl: any) => pl.id === playId);
        const fromMy = (myPlaybook?.plays ?? []).find((pl: any) => pl.id === playId);

        if (prioritizeTeam) {
            return fromTeam ?? fromMy;
        }

        return fromMy ?? fromTeam;
    },
};
