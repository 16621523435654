import { useNavigate } from 'react-router';

import { useAssets } from '@labradorsports/assets';
import { HighlightedSearchText, PlayTagDisplay } from '@labradorsports/components';

import { useDispatcher } from '../../../shared/index.js';
import { playbookActions, teamsActions } from '../../../store/index.js';

interface Props {
    result: any;
    onClick: (result: any) => void;
}

const PlaySearchResult: FC<Props> = ({ result, onClick }) => {
    const navigate = useNavigate();
    const { Ball } = useAssets();
    const setActiveTeam = useDispatcher(teamsActions.SetActiveTeam);
    const setOpenPlay = useDispatcher(playbookActions.OpenPlay);

    const showPlay = () => {
        onClick(result);
        navigate('/playbook');
        if (result.teamId) {
            setActiveTeam(result.teamId);
        }
        setOpenPlay(result.playId);
    };

    return (
        <div className="play-search-result search-result" onClick={showPlay}>
            <img src={Ball} alt="" />
            <div className="flex-grow-1">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <HighlightedSearchText text={result._highlightResult.name.value} />{' '}
                    </div>
                    <div>
                        {result.teamName ? (
                            <HighlightedSearchText text={result._highlightResult.teamName.value} />
                        ) : (
                            'My Playbook'
                        )}
                    </div>
                </div>
                <div className="small">
                    <HighlightedSearchText text={result._highlightResult.description?.value} />{' '}
                </div>
                <div>
                    <PlayTagDisplay tags={result.tags} />
                </div>
            </div>
        </div>
    );
};

export default PlaySearchResult;
