import { Suspense } from 'react';

import { LoadingSpinner } from '@labradorsports/components';

import PlayEditorInterfaceDirect, {
    PlayEditorProps,
} from '../../play-editor/play-editor-interface/play-editor-interface.js';
import { DnDContext, lazyWrapper } from '../../shared/structural/index.js';
import PlayEditorWrapper from '../playEditorWrapper.js';
import { FieldContainerProvider, PenStrokeProvider } from '../providers/index.js';

const PlayEditorInterfaceLazy = lazyWrapper(
    () =>
        import(
            /* webpackChunkName: "play-editor-interface" */ '../../play-editor/play-editor-interface/play-editor-interface.js'
        )
);

const PlayEditorInterface = SSR ? PlayEditorInterfaceDirect : PlayEditorInterfaceLazy;

const PlayEditor: FC<PlayEditorProps & { readOnly?: boolean }> = ({ readOnly, ...props }) => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <PlayEditorWrapper readOnly={readOnly}>
                <DnDContext>
                    <FieldContainerProvider>
                        <PenStrokeProvider>
                            <PlayEditorInterface {...props} />
                        </PenStrokeProvider>
                    </FieldContainerProvider>
                </DnDContext>
            </PlayEditorWrapper>
        </Suspense>
    );
};

export default PlayEditor;
