import { ToolBaseTypes, ToolTypes } from '@labradorsports/constants';

import Move from '../models/move.js';
import { createLines, findClosestPiece, defaultMove } from '../utils/index.js';

import { PluginContext } from './api.js';

export default {
    baseType: ToolBaseTypes.SHOOT,
    types: [ToolTypes.SHOOT],
    generateAnchors: ({ play, frame }) => {
        const anchors = [];

        if (!play.fieldSetup && frame.shots) {
            anchors.unshift(
                ...frame.shots.map((shot) => {
                    return {
                        type: shot.type,
                        id: shot.id,
                        target: shot.target,
                        ...shot.anchors[0],
                        space: 'screen' as const,
                    };
                })
            );
        }

        return anchors;
    },

    generateLines: ({ play, frame }) => {
        if (!play.fieldSetup && frame.shots) {
            return frame.shots.flatMap(createLines);
        }

        return [];
    },

    handleDragEnd: ({ api, play, frame }, anchor, offset) => {
        // Shot ID will use ball ID when left blank
        if (!play.field.pieces) return;

        const closestCarrier = findClosestPiece(offset, frame.hasBall());
        const closestBalls = frame.getBallsByCarrier(closestCarrier.id);

        if (frame.balls.length === 1) {
            const closest = frame.findClosestGoal(offset, frame.possession());

            if (closest) {
                api.updateShot({
                    ball: closestBalls[0].id,
                    target: closest.id,
                });
            }
        } else if (typeof anchor.id === 'number') {
            const shot = frame.shots.find((shot) => shot.id === anchor.id);
            const hasBall = frame.getPlayer(shot.target);
            const closest = frame.findClosestGoal(offset, hasBall.type);

            api.updateShot({
                id: anchor.id,
                target: closest.id,
            });
        } else {
            const targetBall = closestBalls.find((ball) => !frame.shots?.some((shot) => shot.props.ball === ball.id));

            api.updateShot({
                ball: (targetBall ?? closestBalls[0]).id,
                type: anchor.type,
                target: null,
            });
        }
    },

    handleDrag({ frame }, anchor, offset, clamped) {
        const closest = frame.findClosestGoal(clamped, anchor.type);

        frame.updateShot(anchor.id, closest.id);
    },

    getDefaultMove({ frame }, type, offset) {
        const closestCarrier = findClosestPiece(offset, frame.hasBall());

        if (!frame.field.pieces || !closestCarrier) {
            return null;
        }

        if (frame.balls.length > 1) {
            return defaultMove(frame.field, closestCarrier.origin, type, closestCarrier.id);
        }

        const closest = frame.findClosestGoal(offset, frame.possession());

        if (!closest) return null;

        return new Move({
            type,
            target: closest.id,
            origin: frame.hasBall()[0].origin,
            anchors: [{ ...closest.origin }],
        });
    },
} as PlayPlugin<PluginContext>;
