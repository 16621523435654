import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';

import { StyledTextField, SimpleModal } from '@labradorsports/components';

import { Selectors, useReauthenticateMutation, useSendPasswordResetMutation } from '../../store/index.js';

interface Props {
    open: boolean;
    onClose: AnyFunction;
}

const ReauthenticateModal: FC<Props> = ({ open, onClose }) => {
    const navigate = useNavigate();
    const [reauthenticate] = useReauthenticateMutation();
    const [sendPasswordReset] = useSendPasswordResetMutation();
    const accountFormError = useSelector(Selectors.accountFormError);
    const user = useSelector(Selectors.user);
    const email = user?.email;
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (open && !user) {
            navigate('/login');
        }
    }, [user, open]);

    const passwordChange = (evt: any) => {
        setPassword(evt.target.value);
    };

    const submit = () => {
        reauthenticate({ password });
    };

    const keyPress = (evt: any) => {
        if (evt.key === 'Enter') {
            submit();
        }
    };

    const resetPassword = () => {
        onClose();
        sendPasswordReset({ email });
    };

    const buttons = [
        {
            text: 'Login',
            action: submit,
            color: 'primary',
        },
    ];

    return (
        <SimpleModal buttons={buttons} open={open} onClose={onClose}>
            <div className="text-start">
                <h4>Please log in again:</h4>
                <div className="small">Email</div>
                <div>{email}</div>
                <StyledTextField
                    type="password"
                    value={password}
                    onChange={passwordChange}
                    label="Password"
                    onKeyPress={keyPress}
                    fullWidth
                />
                {accountFormError ? <span className="error-text">{accountFormError.message}</span> : undefined}
                <div>
                    <div className="small">Forgot your password?</div>
                    <Button tabIndex={1} onClick={resetPassword}>
                        Reset Password
                    </Button>
                </div>
            </div>
        </SimpleModal>
    );
};

export default ReauthenticateModal;
