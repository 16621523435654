import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';

import { PanelHeader } from '@labradorsports/components';

import { Selectors, useLoadEntityLogoQuery } from '../../../store/index.js';

interface Props {
    team?: any;
    subtitle?: string;
    title?: string;
    actionMenu?: any;
    onBack?: (evt: MouseEvent<any, any>) => any;
}

const TeamHeader: FC<Props> = ({ team, subtitle, title, onBack, actionMenu }) => {
    const currentTeam = useSelector(Selectors.currentTeam);
    const showTeam = team ?? currentTeam;
    const { data: logoUrl } = useLoadEntityLogoQuery({ programId: showTeam?.id });

    const finalTitle = showTeam && !subtitle ? showTeam?.name : title;
    const finalSubheader = showTeam && !subtitle ? title : subtitle;

    return (
        <PanelHeader
            title={finalTitle}
            subtitle={finalSubheader}
            logoUrl={logoUrl}
            onBack={onBack}
            actionMenu={actionMenu}
        />
    );
};

export default TeamHeader;
