import { addMonths, addYears, addDays } from 'date-fns';

import {
    Addons,
    BillingFrequency,
    PlanConfig,
    Plans,
    PaymentGracePeriod,
    SiteConstants,
} from '@labradorsports/constants';

interface PlanPricing {
    MonthlyPrice: number;
    AnnualPrice: number;
}

export function isProgramPaid(paymentExpires: any): [boolean, string?] {
    if (paymentExpires) {
        const paymentExpiresDate = paymentExpires instanceof Date ? paymentExpires : paymentExpires.toDate();

        // Add grace period for payment to complete
        if (addDays(paymentExpiresDate, PaymentGracePeriod) < new Date()) {
            return [false, 'unpaid subscription'];
        }

        return [true];
    }

    return [false, 'unpaid subscription (no paymentExpires)'];
}

export function priceForBillingFrequency(billingFrequency: string, planConfig: PlanPricing): number {
    return billingFrequency === BillingFrequency.ANNUAL ? planConfig.AnnualPrice : planConfig.MonthlyPrice;
}

export function priceDisplayForBillingFrequency(
    billingFrequency: string,
    planConfig: PlanPricing,
    quantity = 1
): string {
    const price = priceForBillingFrequency(billingFrequency, planConfig) * quantity;
    return ['$', price.toFixed(2), billingFrequency === BillingFrequency.ANNUAL ? '/yr' : '/mo'].join('');
}

export function priceForTeam(site: string, plan: string, billingFrequency: string, team: Team): number {
    const teamsBasePrice = priceForBillingFrequency(billingFrequency, PlanConfig[plan]);

    const addonsPrice =
        (team.addons?.[Addons.PLAYERS] ?? 0) *
            priceForBillingFrequency(billingFrequency, SiteConstants[site].AddonConfig[Addons.PLAYERS]) +
        (team.addons?.[Addons.COACHES] ?? 0) *
            priceForBillingFrequency(billingFrequency, SiteConstants[site].AddonConfig[Addons.COACHES]);

    return teamsBasePrice + addonsPrice;
}

export function calculateTodayBasePrice(
    site: string,
    plan: string,
    billingFrequency: string,
    addons: any = {},
    teams: any[] = []
): number {
    if (plan === Plans.PERSONAL_UNLIMITED) {
        return priceForBillingFrequency(billingFrequency, PlanConfig[plan]);
    }

    const programAddonPrice =
        (addons[Addons.ADMINS] ?? 0) *
        priceForBillingFrequency(billingFrequency, SiteConstants[site].AddonConfig[Addons.ADMINS]);

    return (
        teams.reduce((accumulator, team) => accumulator + priceForTeam(site, plan, billingFrequency, team), 0) +
        programAddonPrice
    );
}

// Calculates the discount value of a coupon in one payment
// This varies based on billing frequency
export function calculateCouponDiscount(
    site: string,
    plan: string,
    billingFrequency: string,
    addons = {},
    teams = [],
    couponProperties?: any
): number {
    const paymentDurationMonths = (() => {
        switch (billingFrequency) {
            case BillingFrequency.MONTHLY: {
                return 1;
            }

            case BillingFrequency.ANNUAL: {
                return 12;
            }

            default: {
                throw new Error(`invalid billing frequency: ${billingFrequency}`);
            }
        }
    })();

    const basePrice = calculateTodayBasePrice(site, plan, billingFrequency, addons, teams);
    const basePricePerMonth = basePrice / paymentDurationMonths;

    if (couponProperties && !couponProperties.freeTrial) {
        const discountPerMonth =
            couponProperties.discountType === 'percent'
                ? (couponProperties.discount / 100) * basePricePerMonth
                : couponProperties.discount;

        // Coupon can last no longer than its assigned duration and the length of the payment
        const discountDuration = Math.min(paymentDurationMonths, couponProperties.durationMonths ?? 999);

        return Math.min(basePrice, discountPerMonth * discountDuration);
    }

    return 0;
}

export function calculatePayments(
    site: string,
    plan: string,
    billingFrequency: string,
    lastPayment = new Date(),
    addons = {},
    teams = [],
    couponProperties?: any
): [number, number, Date] {
    const firstPaymentBase = calculateTodayBasePrice(site, plan, billingFrequency, addons, teams);
    const couponDiscount = calculateCouponDiscount(site, plan, billingFrequency, addons, teams, couponProperties);

    if (couponProperties?.freeTrial) {
        return [0, firstPaymentBase, addDays(lastPayment, couponProperties.freeTrial)];
    }

    // Discounts cannot bring price below 0
    const afterCoupon = Math.max(firstPaymentBase - couponDiscount, 0);

    const couponAppliesAgain =
        couponProperties && (!couponProperties.durationMonths || couponProperties.durationMonths > 1);
    const nextPaymentBase = couponAppliesAgain ? afterCoupon : firstPaymentBase;
    const nextPayment = Math.max(nextPaymentBase, 0);

    const nextPaymentDate =
        billingFrequency === BillingFrequency.MONTHLY ? addMonths(lastPayment, 1) : addYears(lastPayment, 1);

    if (nextPaymentDate < new Date()) {
        const correctedNextPaymentDate =
            billingFrequency === BillingFrequency.MONTHLY ? addMonths(new Date(), 1) : addYears(new Date(), 1);
        return [afterCoupon, nextPayment, correctedNextPaymentDate];
    }

    return [afterCoupon, nextPayment, nextPaymentDate];
}

const FREQUENCY_NAMES = {
    [BillingFrequency.MONTHLY]: 'Monthly',
    [BillingFrequency.ANNUAL]: 'Annually',
};

export function billingFrequencyDisplay(billingFrequency: string): string {
    return FREQUENCY_NAMES[billingFrequency];
}

export function getDisplayPrice(price: number, billingFrequency: string): string {
    const amount = billingFrequency === BillingFrequency.ANNUAL ? price / 12 : price;
    const isRound = amount % 1 === 0;

    return `$${isRound ? amount : amount.toFixed(2)}`;
}
