import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';

import { Plans } from '@labradorsports/constants';

import { RootState, Selectors } from '../state.js';
import { useFetchTeamsQuery } from '../teams/api.js';

import { useFetchBillingDetailsQuery, useGetInvoicesQuery, useGetPersonalUnlimitedQuery } from './api.js';

export const useBillingDetailsQuery = () => {
    const { data: billingDetails = {} } = useFetchBillingDetailsQuery();
    const { cards = {}, teams = {} } = billingDetails;

    return { cards, teams };
};

export const useCurrentTeamBillingQuery = () => {
    const currentTeam = useSelector(Selectors.currentTeam);
    const { teams } = useBillingDetailsQuery();

    const billing = currentTeam ? teams[currentTeam.programId] : null;

    return {
        data: billing,
    };
};

export const useEntityBillingDetailsQuery = () => {
    const editBillingEntityId = useSelector((state: RootState) => state.billing.editBillingEntityId);
    const { data: { programs } = {} } = useFetchTeamsQuery();
    const { teams, cards } = useBillingDetailsQuery();
    const { data: personalBilling } = useGetPersonalUnlimitedQuery();

    const entity =
        editBillingEntityId === Plans.PERSONAL_UNLIMITED
            ? {
                  id: Plans.PERSONAL_UNLIMITED,
                  name: 'Personal Unlimited',
              }
            : programs.find((program) => program.id === editBillingEntityId);

    const entityBillingDetails =
        editBillingEntityId === Plans.PERSONAL_UNLIMITED ? personalBilling : teams?.[editBillingEntityId];

    if (entityBillingDetails) {
        const paymentMethod = cards[entityBillingDetails.cardId];

        return {
            data: {
                entity,
                billingDetails: entityBillingDetails,
                paymentMethod,
            },
        };
    }

    return {
        data: {
            entity,
        },
    };
};

export const useEntityInvoicesQuery = ({ entityId }) => {
    const { data: invoices } = useGetInvoicesQuery();

    return {
        data: invoices?.filter((invoice) => invoice.entityId === entityId),
    };
};

export const useHasPersonalQuery = () => {
    const user = useSelector(Selectors.user);
    const someTeamsPaid = useSelector(Selectors.someTeamsPaid);
    const { data: personalBilling } = useGetPersonalUnlimitedQuery();

    const personalPaid = user && personalBilling && addDays(personalBilling.paymentExpires, 2) > new Date();

    return {
        data: personalPaid || someTeamsPaid,
    };
};
