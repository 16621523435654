import { useAssets } from '@labradorsports/assets';

import { useLoadProfileImageQuery } from '../../../store/index.js';
import { getAssetUrl } from '../../utils.js';

interface Props {
    uid: string;
}

const ProfilePicture: FC<Props> = ({ uid }) => {
    const { AvatarIcon } = useAssets();
    const { data: profileImageUrl } = useLoadProfileImageQuery({ uid }, { skip: !uid });

    return (
        <div className="profile-picture">
            <img src={profileImageUrl ?? getAssetUrl(AvatarIcon)} alt="avatar icon" />
        </div>
    );
};

export default ProfilePicture;
